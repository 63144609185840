import { createActionCreator } from 'deox';

import { TCreateInvoiceMaterials, TCreateInvoiceResponse } from '@/services/api/invoice/create-invoice';

// CONSTANTS

export enum ECreateInvoiceAction {
  CREATE_INVOICE = 'CREATE_INVOICE',
  CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST',
  CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS',
  CREATE_INVOICE_FAILED = 'CREATE_INVOICE_FAILED',
}

// TYPES

export type TCreateInvoiceRequest = {
  type: ECreateInvoiceAction.CREATE_INVOICE_REQUEST;
  payload: {
    materials: TCreateInvoiceMaterials;
    successCallback?: (response: TCreateInvoiceResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateInvoiceSuccess = {
  type: ECreateInvoiceAction.CREATE_INVOICE_SUCCESS;
  payload: { response: TCreateInvoiceResponse };
};

export type TCreateInvoiceFailed = { type: ECreateInvoiceAction.CREATE_INVOICE_FAILED };

// FUNCTION

export const createInvoiceAction = {
  request: createActionCreator(
    ECreateInvoiceAction.CREATE_INVOICE_REQUEST,
    (resolve) =>
      (
        materials: TCreateInvoiceMaterials,
        successCallback?: (response: TCreateInvoiceResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateInvoiceRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateInvoiceAction.CREATE_INVOICE_SUCCESS,
    (resolve) =>
      (response: TCreateInvoiceResponse): TCreateInvoiceSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateInvoiceAction.CREATE_INVOICE_FAILED,
    (resolve) =>
      (error: unknown): TCreateInvoiceFailed =>
        resolve({ error }),
  ),
};
