import ApiService from '@/services/api';

// TYPES

export type TGetPublicCouponsParams = unknown;

export type TGetPublicCouponsMaterials = {
  params?: TGetPublicCouponsParams;
};

export type TGetPublicCouponsResponse = unknown;

// FUNCTION

export const getPublicCoupons = async ({ params }: TGetPublicCouponsMaterials): Promise<TGetPublicCouponsResponse> => {
  const response = await ApiService.get(`/api-user/coupons`, { params });
  return response?.data;
};
