import { TPaymentState } from '@/redux/reducers/payment';
import { TGetPublicPaymentsSuccess } from '@/redux/actions/payment';

export const getPublicPaymentsUpdateState = (
  state: TPaymentState,
  action: TGetPublicPaymentsSuccess,
): TPaymentState => ({
  ...state,
  getPublicPaymentsResponse: action.payload.response,
});
