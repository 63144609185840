import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getAcademyAction } from '@/redux/actions';
import { getAcademy, TGetAcademyResponse } from '@/services/api';

// FUNCTION

export function* getAcademySaga(action: ActionType<typeof getAcademyAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getAcademy, materials);
    const getAcademyResponse: TGetAcademyResponse = response as TGetAcademyResponse;
    yield put(getAcademyAction.success(getAcademyResponse));
    successCallback?.(getAcademyResponse);
  } catch (err) {
    yield put(getAcademyAction.failure(err));
    failedCallback?.(err);
  }
}
