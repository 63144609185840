import ApiService from '@/services/api';

// TYPES

export type TCreatePackageParams = unknown;
export type TCreatePackageBody = unknown;

export type TCreatePackageMaterials = {
  params?: TCreatePackageParams;
  body?: TCreatePackageBody;
};

export type TCreatePackageResponse = unknown;

// FUNCTION

export const createPackage = async ({ params, body }: TCreatePackageMaterials): Promise<TCreatePackageResponse> => {
  const response = await ApiService.post(`/api-admin/packages`, body, { params });
  return response?.data;
};
