import { TPayment } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TCreatePaymentByUserParams = unknown;
export type TCreatePaymentByUserBody = unknown;

export type TCreatePaymentByUserMaterials = {
  params?: TCreatePaymentByUserParams;
  body?: TCreatePaymentByUserBody;
};

export type TCreatePaymentByUserResponse = TCommonResponse & {
  content: TPayment;
};

// FUNCTION

export const createPaymentByUser = async ({
  params,
  body,
}: TCreatePaymentByUserMaterials): Promise<TCreatePaymentByUserResponse> => {
  const response = await ApiService.post(`/api-user/payments`, body, { params });
  return response?.data;
};
