import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteCouponAction } from '@/redux/actions';
import { deleteCoupon, TDeleteCouponResponse } from '@/services/api';

// FUNCTION

export function* deleteCouponSaga(action: ActionType<typeof deleteCouponAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteCoupon, materials);
    const deleteCouponResponse: TDeleteCouponResponse = response as TDeleteCouponResponse;
    yield put(deleteCouponAction.success(deleteCouponResponse));
    successCallback?.(deleteCouponResponse);
  } catch (err) {
    yield put(deleteCouponAction.failure(err));
    failedCallback?.(err);
  }
}
