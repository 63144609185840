import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deletePackageAction } from '@/redux/actions';
import { deletePackage, TDeletePackageResponse } from '@/services/api';

// FUNCTION

export function* deletePackageSaga(action: ActionType<typeof deletePackageAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deletePackage, materials);
    const deletePackageResponse: TDeletePackageResponse = response as TDeletePackageResponse;
    yield put(deletePackageAction.success(deletePackageResponse));
    successCallback?.(deletePackageResponse);
  } catch (err) {
    yield put(deletePackageAction.failure(err));
    failedCallback?.(err);
  }
}
