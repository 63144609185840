import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getInvoicesAction } from '@/redux/actions';
import { getInvoices, TGetInvoicesResponse } from '@/services/api';

// FUNCTION

export function* getInvoicesSaga(action: ActionType<typeof getInvoicesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getInvoices, materials);
    const getInvoicesResponse: TGetInvoicesResponse = response as TGetInvoicesResponse;
    yield put(getInvoicesAction.success(getInvoicesResponse));
    successCallback?.(getInvoicesResponse);
  } catch (err) {
    yield put(getInvoicesAction.failure(err));
    failedCallback?.(err);
  }
}
