import ApiService from '@/services/api';

// TYPES

export type TCreateAdvisoryParams = unknown;
export type TCreateAdvisoryBody = unknown;

export type TCreateAdvisoryMaterials = {
  params?: TCreateAdvisoryParams;
  body?: TCreateAdvisoryBody;
};

export type TCreateAdvisoryResponse = unknown;

// FUNCTION

export const createAdvisory = async ({ params, body }: TCreateAdvisoryMaterials): Promise<TCreateAdvisoryResponse> => {
  const response = await ApiService.post(`/api-admin/advisories`, body, { params });
  return response?.data;
};
