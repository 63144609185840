import { TInvoice } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TCreateInvoiceByUserParams = unknown;
export type TCreateInvoiceByUserBody = unknown;

export type TCreateInvoiceByUserMaterials = {
  params?: TCreateInvoiceByUserParams;
  body?: TCreateInvoiceByUserBody;
};

export type TCreateInvoiceByUserResponse = TCommonResponse & {
  content: TInvoice;
};

// FUNCTION

export const createInvoiceByUser = async ({
  params,
  body,
}: TCreateInvoiceByUserMaterials): Promise<TCreateInvoiceByUserResponse> => {
  const response = await ApiService.post(`/api-user/invoices`, body, { params });
  return response?.data;
};
