import { createReducer } from 'deox';

import {
  TCreatePaymentByUserResponse,
  TCreatePaymentResponse,
  TDeletePaymentResponse,
  TGetPaymentsResponse,
  TGetPublicPaymentsResponse,
  TUpdatePaymentResponse,
} from '@/services/api/payment';
import {
  createPaymentByUserAction,
  createPaymentAction,
  deletePaymentAction,
  getPaymentsAction,
  getPublicPaymentsAction,
  updatePaymentAction,
} from '@/redux/actions';
import { createPaymentByUserUpdateState } from './create-payment-by-user';
import { createPaymentUpdateState } from './create-payment';
import { deletePaymentUpdateState } from './delete-payment';
import { getPaymentsUpdateState } from './get-payments';
import { getPublicPaymentsUpdateState } from './get-public-payments';
import { updatePaymentUpdateState } from './update-payment';

export type TPaymentState = {
  createPaymentByUserResponse?: TCreatePaymentByUserResponse;
  createPaymentResponse?: TCreatePaymentResponse;
  deletePaymentResponse?: TDeletePaymentResponse;
  getPaymentsResponse?: TGetPaymentsResponse;
  getPublicPaymentsResponse?: TGetPublicPaymentsResponse;
  updatePaymentResponse?: TUpdatePaymentResponse;
};

const initialState: TPaymentState = {
  createPaymentByUserResponse: undefined,
  createPaymentResponse: undefined,
  deletePaymentResponse: undefined,
  getPaymentsResponse: undefined,
  getPublicPaymentsResponse: undefined,
  updatePaymentResponse: undefined,
};

const PaymentReducer = createReducer(initialState, (handleAction) => [
  handleAction(createPaymentByUserAction.success, createPaymentByUserUpdateState),
  handleAction(createPaymentAction.success, createPaymentUpdateState),
  handleAction(deletePaymentAction.success, deletePaymentUpdateState),
  handleAction(getPaymentsAction.success, getPaymentsUpdateState),
  handleAction(getPublicPaymentsAction.success, getPublicPaymentsUpdateState),
  handleAction(updatePaymentAction.success, updatePaymentUpdateState),
]);

export default PaymentReducer;
