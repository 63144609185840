import { TSettings } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetPublicSettingsParams = unknown;

export type TGetPublicSettingsMaterials = {
  params?: TGetPublicSettingsParams;
};

export type TGetPublicSettingsResponse = TCommonResponse & {
  content: TSettings;
};

// FUNCTION

export const getPublicSettings = async ({
  params,
}: TGetPublicSettingsMaterials): Promise<TGetPublicSettingsResponse> => {
  const response = await ApiService.get(`/api-public/settings`, { params });
  return response?.data;
};
