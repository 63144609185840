import React, { useEffect } from 'react';
import { Redirect, Router } from '@reach/router';
import { useDispatch } from 'react-redux';

import { LayoutPaths, Pages, Paths, ProtectedRoute, PublicRoute } from '@/pages/routers';
import Auth from '@/layouts/Auth';
import Admin from '@/layouts/Admin';
import { uiActions } from '@/redux/actions';
import { isAdminApplication } from '@/utils/functions';

import 'moment/locale/vi';

const App: React.FC = () => {
  const dispatch = useDispatch();

  const isAdminApp = isAdminApplication();

  useEffect(() => {
    const updateSize = (): void => {
      dispatch(uiActions.setDevice(window.innerWidth));
    };
    window.addEventListener('resize', updateSize);
    return (): void => window.removeEventListener('resize', updateSize);
  }, [dispatch]);

  return (
    <div className="App">
      <Router primary={false}>
        <Auth path={LayoutPaths.Auth}>
          {isAdminApp ? (
            <>
              <PublicRoute path={Paths.Login} component={Pages.Login} />
            </>
          ) : (
            <>
              <PublicRoute path={Paths.Login} component={Pages.Login} />
              <PublicRoute path={Paths.LoginDomain} component={Pages.LoginDomain} />
              <PublicRoute path={Paths.Register} component={Pages.RegisterBranch} />
              <PublicRoute path={Paths.PaymentStatus} component={Pages.PaymentStatus} />
            </>
          )}

          <Redirect
            noThrow
            from={Paths.Rest}
            to={`${LayoutPaths.Auth}${isAdminApp ? Paths.Login : Paths.LoginDomain}`}
          />
        </Auth>

        {isAdminApp && (
          <Admin path={LayoutPaths.Admin}>
            <ProtectedRoute path={Paths.Dashboard} component={Pages.Dashboard} />
            <ProtectedRoute path={Paths.Academy} component={Pages.Academy} />
            <ProtectedRoute path={Paths.AcademyDetail()} component={Pages.AcademyDetail} />
            <ProtectedRoute path={Paths.ContactUsers} component={Pages.ContactUsers} />
            <ProtectedRoute path={Paths.HistoriesPayment} component={Pages.HistoriesPayment} />
            <ProtectedRoute path={Paths.CRMs} component={Pages.CRMs} />
            <ProtectedRoute path={Paths.Coupons} component={Pages.Coupons} />
            <ProtectedRoute path={Paths.Packages} component={Pages.Packages} />
            <ProtectedRoute path={Paths.SettingTrialMode} component={Pages.SettingTrialMode} />
            <ProtectedRoute path={Paths.SettingVAT} component={Pages.SettingVAT} />
            <ProtectedRoute path={Paths.SettingEmail} component={Pages.SettingEmail} />
            <ProtectedRoute path={Paths.SettingMoney} component={Pages.SettingMoney} />
            <ProtectedRoute path={Paths.SettingBank} component={Pages.SettingBank} />
            <ProtectedRoute path={Paths.SettingGeneral} component={Pages.SettingGeneral} />
            <ProtectedRoute path={Paths.Receipts} component={Pages.Receipts} />
            <ProtectedRoute path={Paths.Feedback} component={Pages.Feedback} />

            <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Admin}${Paths.Dashboard}`} />
          </Admin>
        )}

        <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Auth}${isAdminApp ? Paths.Login : Paths.LoginDomain}`} />
      </Router>
    </div>
  );
};

export default App;
