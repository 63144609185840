import { createReducer } from 'deox';

import { TGetDashboardCountResponse } from '@/services/api/report';
import { getDashboardCountAction } from '@/redux/actions';
import { getDashboardCountUpdateState } from './get-dashboard-count';

export type TReportState = {
  getDashboardCountResponse?: TGetDashboardCountResponse;
};

const initialState: TReportState = {
  getDashboardCountResponse: undefined,
};

const ReportReducer = createReducer(initialState, (handleAction) => [
  handleAction(getDashboardCountAction.success, getDashboardCountUpdateState),
]);

export default ReportReducer;
