import { createReducer } from 'deox';

import {
  TCheckExistUserResponse,
  TDeleteUserResponse,
  TGetMyProfileResponse,
  TGetUsersSearchResponse,
  TGetUsersResponse,
} from '@/services/api/user';
import {
  checkExistUserAction,
  deleteUserAction,
  getMyProfileAction,
  getUsersSearchAction,
  getUsersAction,
} from '@/redux/actions';
import { checkExistUserUpdateState } from './check-exist-user';
import { deleteUserUpdateState } from './delete-user';
import { getMyProfileUpdateState } from './get-my-profile';
import { getUsersSearchUpdateState } from './get-users-search';
import { getUsersUpdateState } from './get-users';

export type TUserState = {
  checkExistUserResponse?: TCheckExistUserResponse;
  deleteUserResponse?: TDeleteUserResponse;
  getMyProfileResponse?: TGetMyProfileResponse;
  getUsersSearchResponse?: TGetUsersSearchResponse;
  getUsersResponse?: TGetUsersResponse;
};

const initialState: TUserState = {
  checkExistUserResponse: undefined,
  deleteUserResponse: undefined,
  getMyProfileResponse: undefined,
  getUsersSearchResponse: undefined,
  getUsersResponse: undefined,
};

const UserReducer = createReducer(initialState, (handleAction) => [
  handleAction(checkExistUserAction.success, checkExistUserUpdateState),
  handleAction(deleteUserAction.success, deleteUserUpdateState),
  handleAction(getMyProfileAction.success, getMyProfileUpdateState),
  handleAction(getUsersSearchAction.success, getUsersSearchUpdateState),
  handleAction(getUsersAction.success, getUsersUpdateState),
]);

export default UserReducer;
