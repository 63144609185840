import { createActionCreator } from 'deox';

import { TGetUsersSearchMaterials, TGetUsersSearchResponse } from '@/services/api/user/get-users-search';

// CONSTANTS

export enum EGetUsersSearchAction {
  GET_USERS_SEARCH = 'GET_USERS_SEARCH',
  GET_USERS_SEARCH_REQUEST = 'GET_USERS_SEARCH_REQUEST',
  GET_USERS_SEARCH_SUCCESS = 'GET_USERS_SEARCH_SUCCESS',
  GET_USERS_SEARCH_FAILED = 'GET_USERS_SEARCH_FAILED',
}

// TYPES

export type TGetUsersSearchRequest = {
  type: EGetUsersSearchAction.GET_USERS_SEARCH_REQUEST;
  payload: {
    materials: TGetUsersSearchMaterials;
    successCallback?: (response: TGetUsersSearchResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetUsersSearchSuccess = {
  type: EGetUsersSearchAction.GET_USERS_SEARCH_SUCCESS;
  payload: { response: TGetUsersSearchResponse };
};

export type TGetUsersSearchFailed = { type: EGetUsersSearchAction.GET_USERS_SEARCH_FAILED };

// FUNCTION

export const getUsersSearchAction = {
  request: createActionCreator(
    EGetUsersSearchAction.GET_USERS_SEARCH_REQUEST,
    (resolve) =>
      (
        materials: TGetUsersSearchMaterials,
        successCallback?: (response: TGetUsersSearchResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetUsersSearchRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetUsersSearchAction.GET_USERS_SEARCH_SUCCESS,
    (resolve) =>
      (response: TGetUsersSearchResponse): TGetUsersSearchSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetUsersSearchAction.GET_USERS_SEARCH_FAILED,
    (resolve) =>
      (error: unknown): TGetUsersSearchFailed =>
        resolve({ error }),
  ),
};
