import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TCheckExistUserParams = unknown;

export type TCheckExistUserMaterials = {
  params?: TCheckExistUserParams;
};

export type TCheckExistUserResponse = TCommonResponse & {
  content: { is_exist: boolean };
};

// FUNCTION

export const checkExistUser = async ({ params }: TCheckExistUserMaterials): Promise<TCheckExistUserResponse> => {
  const response = await ApiService.get(`/api-public/users/is-exist`, { params });
  return response?.data;
};
