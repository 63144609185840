import { createActionCreator } from 'deox';

import { TGetPaymentsMaterials, TGetPaymentsResponse } from '@/services/api/payment/get-payments';

// CONSTANTS

export enum EGetPaymentsAction {
  GET_PAYMENTS = 'GET_PAYMENTS',
  GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST',
  GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS',
  GET_PAYMENTS_FAILED = 'GET_PAYMENTS_FAILED',
}

// TYPES

export type TGetPaymentsRequest = {
  type: EGetPaymentsAction.GET_PAYMENTS_REQUEST;
  payload: {
    materials: TGetPaymentsMaterials;
    successCallback?: (response: TGetPaymentsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPaymentsSuccess = {
  type: EGetPaymentsAction.GET_PAYMENTS_SUCCESS;
  payload: { response: TGetPaymentsResponse };
};

export type TGetPaymentsFailed = { type: EGetPaymentsAction.GET_PAYMENTS_FAILED };

// FUNCTION

export const getPaymentsAction = {
  request: createActionCreator(
    EGetPaymentsAction.GET_PAYMENTS_REQUEST,
    (resolve) =>
      (
        materials: TGetPaymentsMaterials,
        successCallback?: (response: TGetPaymentsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPaymentsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPaymentsAction.GET_PAYMENTS_SUCCESS,
    (resolve) =>
      (response: TGetPaymentsResponse): TGetPaymentsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPaymentsAction.GET_PAYMENTS_FAILED,
    (resolve) =>
      (error: unknown): TGetPaymentsFailed =>
        resolve({ error }),
  ),
};
