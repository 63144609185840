import { createActionCreator } from 'deox';

import { TGetInvoiceMaterials, TGetInvoiceResponse } from '@/services/api/invoice/get-invoice';

// CONSTANTS

export enum EGetInvoiceAction {
  GET_INVOICE = 'GET_INVOICE',
  GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST',
  GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILED = 'GET_INVOICE_FAILED',
}

// TYPES

export type TGetInvoiceRequest = {
  type: EGetInvoiceAction.GET_INVOICE_REQUEST;
  payload: {
    materials: TGetInvoiceMaterials;
    successCallback?: (response: TGetInvoiceResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetInvoiceSuccess = {
  type: EGetInvoiceAction.GET_INVOICE_SUCCESS;
  payload: { response?: TGetInvoiceResponse };
};

export type TGetInvoiceFailed = { type: EGetInvoiceAction.GET_INVOICE_FAILED };

// FUNCTION

export const getInvoiceAction = {
  request: createActionCreator(
    EGetInvoiceAction.GET_INVOICE_REQUEST,
    (resolve) =>
      (
        materials: TGetInvoiceMaterials,
        successCallback?: (response: TGetInvoiceResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetInvoiceRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetInvoiceAction.GET_INVOICE_SUCCESS,
    (resolve) =>
      (response?: TGetInvoiceResponse): TGetInvoiceSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetInvoiceAction.GET_INVOICE_FAILED,
    (resolve) =>
      (error: unknown): TGetInvoiceFailed =>
        resolve({ error }),
  ),
};
