import { createReducer } from 'deox';

import { TGetPublicSettingsResponse, TGetSettingsResponse, TUpdateSettingKeyResponse } from '@/services/api/setting';
import { getPublicSettingsAction, getSettingsAction, updateSettingKeyAction } from '@/redux/actions';
import { getPublicSettingsUpdateState } from './get-public-settings';
import { getSettingsUpdateState } from './get-settings';
import { updateSettingKeyUpdateState } from './update-setting-key';

export type TSettingState = {
  getPublicSettingsResponse?: TGetPublicSettingsResponse;
  getSettingsResponse?: TGetSettingsResponse;
  updateSettingKeyResponse?: TUpdateSettingKeyResponse;
};

const initialState: TSettingState = {
  getPublicSettingsResponse: undefined,
  getSettingsResponse: undefined,
  updateSettingKeyResponse: undefined,
};

const SettingReducer = createReducer(initialState, (handleAction) => [
  handleAction(getPublicSettingsAction.success, getPublicSettingsUpdateState),
  handleAction(getSettingsAction.success, getSettingsUpdateState),
  handleAction(updateSettingKeyAction.success, updateSettingKeyUpdateState),
]);

export default SettingReducer;
