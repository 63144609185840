import { createActionCreator } from 'deox';

import { TUpdateSettingKeyMaterials, TUpdateSettingKeyResponse } from '@/services/api/setting/update-setting-key';

// CONSTANTS

export enum EUpdateSettingKeyAction {
  UPDATE_SETTING_KEY = 'UPDATE_SETTING_KEY',
  UPDATE_SETTING_KEY_REQUEST = 'UPDATE_SETTING_KEY_REQUEST',
  UPDATE_SETTING_KEY_SUCCESS = 'UPDATE_SETTING_KEY_SUCCESS',
  UPDATE_SETTING_KEY_FAILED = 'UPDATE_SETTING_KEY_FAILED',
}

// TYPES

export type TUpdateSettingKeyRequest = {
  type: EUpdateSettingKeyAction.UPDATE_SETTING_KEY_REQUEST;
  payload: {
    materials: TUpdateSettingKeyMaterials;
    successCallback?: (response: TUpdateSettingKeyResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateSettingKeySuccess = {
  type: EUpdateSettingKeyAction.UPDATE_SETTING_KEY_SUCCESS;
  payload: { response: TUpdateSettingKeyResponse };
};

export type TUpdateSettingKeyFailed = { type: EUpdateSettingKeyAction.UPDATE_SETTING_KEY_FAILED };

// FUNCTION

export const updateSettingKeyAction = {
  request: createActionCreator(
    EUpdateSettingKeyAction.UPDATE_SETTING_KEY_REQUEST,
    (resolve) =>
      (
        materials: TUpdateSettingKeyMaterials,
        successCallback?: (response: TUpdateSettingKeyResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateSettingKeyRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateSettingKeyAction.UPDATE_SETTING_KEY_SUCCESS,
    (resolve) =>
      (response: TUpdateSettingKeyResponse): TUpdateSettingKeySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateSettingKeyAction.UPDATE_SETTING_KEY_FAILED,
    (resolve) =>
      (error: unknown): TUpdateSettingKeyFailed =>
        resolve({ error }),
  ),
};
