import ApiService from '@/services/api';

// TYPES

export type TCreatePaymentParams = unknown;
export type TCreatePaymentBody = unknown;

export type TCreatePaymentMaterials = {
  params?: TCreatePaymentParams;
  body?: TCreatePaymentBody;
};

export type TCreatePaymentResponse = unknown;

// FUNCTION

export const createPayment = async ({ params, body }: TCreatePaymentMaterials): Promise<TCreatePaymentResponse> => {
  const response = await ApiService.post(`/api-admin/payments`, body, { params });
  return response?.data;
};
