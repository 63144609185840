import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createInvoiceAction } from '@/redux/actions';
import { createInvoice, TCreateInvoiceResponse } from '@/services/api';

// FUNCTION

export function* createInvoiceSaga(action: ActionType<typeof createInvoiceAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createInvoice, materials);
    const createInvoiceResponse: TCreateInvoiceResponse = response as TCreateInvoiceResponse;
    yield put(createInvoiceAction.success(createInvoiceResponse));
    successCallback?.(createInvoiceResponse);
  } catch (err) {
    yield put(createInvoiceAction.failure(err));
    failedCallback?.(err);
  }
}
