import React, { useCallback, useEffect } from 'react';
import { Link } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import { TAuthProps } from '@/layouts/Auth/Auth.types';
import LogoDark from '@/assets/images/logo-dark.svg';
import { LayoutPaths } from '@/pages/routers';
import { getMyProfileAction, getPublicSettingsAction } from '@/redux/actions';
import Helpers from '@/services/helpers';
import { TRootState } from '@/redux/reducers';

import './Auth.scss';

const Auth: React.FC<TAuthProps> = ({ children }) => {
  const dispatch = useDispatch();
  const atk = Helpers.getAccessToken();

  const myProfileState = useSelector((state: TRootState) => state.userReducer.getMyProfileResponse)?.content;

  const getMyProfile = useCallback((): void => {
    if (atk && !myProfileState) dispatch(getMyProfileAction.request({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, atk]);

  const getPublicSettings = useCallback(() => {
    dispatch(getPublicSettingsAction.request({}));
  }, [dispatch]);

  useEffect(() => {
    getMyProfile();
  }, [getMyProfile]);

  useEffect(() => {
    getPublicSettings();
  }, [getPublicSettings]);

  return (
    <div className="Auth">
      <div className="Auth-header">
        <div className="container">
          <div className="Auth-header-wrapper flex items-center">
            <Link className="Auth-header-logo" to={LayoutPaths.Auth}>
              <img src={LogoDark} alt="" />
            </Link>
          </div>
        </div>
      </div>
      <div className="Auth-body">{children}</div>
    </div>
  );
};

export default Auth;
