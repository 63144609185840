import { createActionCreator } from 'deox';

import { TGetInvoicesMaterials, TGetInvoicesResponse } from '@/services/api/invoice/get-invoices';

// CONSTANTS

export enum EGetInvoicesAction {
  GET_INVOICES = 'GET_INVOICES',
  GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST',
  GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS',
  GET_INVOICES_FAILED = 'GET_INVOICES_FAILED',
}

// TYPES

export type TGetInvoicesRequest = {
  type: EGetInvoicesAction.GET_INVOICES_REQUEST;
  payload: {
    materials: TGetInvoicesMaterials;
    successCallback?: (response: TGetInvoicesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetInvoicesSuccess = {
  type: EGetInvoicesAction.GET_INVOICES_SUCCESS;
  payload: { response: TGetInvoicesResponse };
};

export type TGetInvoicesFailed = { type: EGetInvoicesAction.GET_INVOICES_FAILED };

// FUNCTION

export const getInvoicesAction = {
  request: createActionCreator(
    EGetInvoicesAction.GET_INVOICES_REQUEST,
    (resolve) =>
      (
        materials: TGetInvoicesMaterials,
        successCallback?: (response: TGetInvoicesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetInvoicesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetInvoicesAction.GET_INVOICES_SUCCESS,
    (resolve) =>
      (response: TGetInvoicesResponse): TGetInvoicesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetInvoicesAction.GET_INVOICES_FAILED,
    (resolve) =>
      (error: unknown): TGetInvoicesFailed =>
        resolve({ error }),
  ),
};
