import { createActionCreator } from 'deox';

import { TGetTestimonialsMaterials, TGetTestimonialsResponse } from '@/services/api/testimonial/get-testimonials';

// CONSTANTS

export enum EGetTestimonialsAction {
  GET_TESTIMONIALS = 'GET_TESTIMONIALS',
  GET_TESTIMONIALS_REQUEST = 'GET_TESTIMONIALS_REQUEST',
  GET_TESTIMONIALS_SUCCESS = 'GET_TESTIMONIALS_SUCCESS',
  GET_TESTIMONIALS_FAILED = 'GET_TESTIMONIALS_FAILED',
}

// TYPES

export type TGetTestimonialsRequest = {
  type: EGetTestimonialsAction.GET_TESTIMONIALS_REQUEST;
  payload: {
    materials: TGetTestimonialsMaterials;
    successCallback?: (response: TGetTestimonialsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTestimonialsSuccess = {
  type: EGetTestimonialsAction.GET_TESTIMONIALS_SUCCESS;
  payload: { response: TGetTestimonialsResponse };
};

export type TGetTestimonialsFailed = { type: EGetTestimonialsAction.GET_TESTIMONIALS_FAILED };

// FUNCTION

export const getTestimonialsAction = {
  request: createActionCreator(
    EGetTestimonialsAction.GET_TESTIMONIALS_REQUEST,
    (resolve) =>
      (
        materials: TGetTestimonialsMaterials,
        successCallback?: (response: TGetTestimonialsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTestimonialsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTestimonialsAction.GET_TESTIMONIALS_SUCCESS,
    (resolve) =>
      (response: TGetTestimonialsResponse): TGetTestimonialsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTestimonialsAction.GET_TESTIMONIALS_FAILED,
    (resolve) =>
      (error: unknown): TGetTestimonialsFailed =>
        resolve({ error }),
  ),
};
