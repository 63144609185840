import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { googleLoginAction } from '@/redux/actions';
import { googleLogin, TGoogleLoginResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION

export function* googleLoginSaga(action: ActionType<typeof googleLoginAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(googleLogin, materials);
    const googleLoginResponse: TGoogleLoginResponse = response as TGoogleLoginResponse;

    Helpers.storeAccessToken(googleLoginResponse?.content?.access_token || '');
    Helpers.storeRefreshToken(googleLoginResponse?.content?.refresh_token || '');

    yield put(googleLoginAction.success(googleLoginResponse));
    successCallback?.(googleLoginResponse);
  } catch (err) {
    yield put(googleLoginAction.failure(err));
    failedCallback?.(err);
  }
}
