import ApiService from '@/services/api';

// TYPES

export type TCreateCouponParams = unknown;
export type TCreateCouponBody = unknown;

export type TCreateCouponMaterials = {
  params?: TCreateCouponParams;
  body?: TCreateCouponBody;
};

export type TCreateCouponResponse = unknown;

// FUNCTION

export const createCoupon = async ({ params, body }: TCreateCouponMaterials): Promise<TCreateCouponResponse> => {
  const response = await ApiService.post(`/api-admin/coupons`, body, { params });
  return response?.data;
};
