import { createReducer } from 'deox';

import {
  TCreateCouponResponse,
  TDeleteCouponResponse,
  TGetCouponsResponse,
  TGetPublicCouponResponse,
  TGetPublicCouponsResponse,
  TUpdateCouponResponse,
} from '@/services/api/coupon';
import {
  createCouponAction,
  deleteCouponAction,
  getCouponsAction,
  getPublicCouponAction,
  getPublicCouponsAction,
  updateCouponAction,
} from '@/redux/actions';
import { createCouponUpdateState } from './create-coupon';
import { deleteCouponUpdateState } from './delete-coupon';
import { getCouponsUpdateState } from './get-coupons';
import { getPublicCouponUpdateState } from './get-public-coupon';
import { getPublicCouponsUpdateState } from './get-public-coupons';
import { updateCouponUpdateState } from './update-coupon';

export type TCouponState = {
  createCouponResponse?: TCreateCouponResponse;
  deleteCouponResponse?: TDeleteCouponResponse;
  getCouponsResponse?: TGetCouponsResponse;
  getPublicCouponResponse?: TGetPublicCouponResponse;
  getPublicCouponsResponse?: TGetPublicCouponsResponse;
  updateCouponResponse?: TUpdateCouponResponse;
};

const initialState: TCouponState = {
  createCouponResponse: undefined,
  deleteCouponResponse: undefined,
  getCouponsResponse: undefined,
  getPublicCouponResponse: undefined,
  getPublicCouponsResponse: undefined,
  updateCouponResponse: undefined,
};

const CouponReducer = createReducer(initialState, (handleAction) => [
  handleAction(createCouponAction.success, createCouponUpdateState),
  handleAction(deleteCouponAction.success, deleteCouponUpdateState),
  handleAction(getCouponsAction.success, getCouponsUpdateState),
  handleAction(getPublicCouponAction.success, getPublicCouponUpdateState),
  handleAction(getPublicCouponsAction.success, getPublicCouponsUpdateState),
  handleAction(updateCouponAction.success, updateCouponUpdateState),
]);

export default CouponReducer;
