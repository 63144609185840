import { TAcademy } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetAcademyPaths = {
  id: string | number;
};
export type TGetAcademyParams = unknown;

export type TGetAcademyMaterials = {
  paths?: TGetAcademyPaths;
  params?: TGetAcademyParams;
};

export type TGetAcademyResponse = TCommonResponse & {
  content: TAcademy;
};

// FUNCTION

export const getAcademy = async ({ paths, params }: TGetAcademyMaterials): Promise<TGetAcademyResponse> => {
  const response = await ApiService.get(`/api-admin/academies/${paths?.id}`, { params });
  return response?.data;
};
