import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteAcademyAction } from '@/redux/actions';
import { deleteAcademy, TDeleteAcademyResponse } from '@/services/api';

// FUNCTION

export function* deleteAcademySaga(action: ActionType<typeof deleteAcademyAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteAcademy, materials);
    const deleteAcademyResponse: TDeleteAcademyResponse = response as TDeleteAcademyResponse;
    yield put(deleteAcademyAction.success(deleteAcademyResponse));
    successCallback?.(deleteAcademyResponse);
  } catch (err) {
    yield put(deleteAcademyAction.failure(err));
    failedCallback?.(err);
  }
}
