/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import React from 'react';
import MetaTags from 'react-meta-tags';

import { TSEOProps } from './SEO.types.d';
import './SEO.scss';

const SEO: React.FC<TSEOProps> = ({ title = 'MindStar | Nền tảng quản lý trung tâm, trường học' }) => {
  return (
    <MetaTags>
      <title>{title}</title>
    </MetaTags>
  );
};

export default SEO;
