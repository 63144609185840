import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getPublicFeaturesAction } from '@/redux/actions';
import { getPublicFeatures, TGetPublicFeaturesResponse } from '@/services/api';

// FUNCTION

export function* getPublicFeaturesSaga(action: ActionType<typeof getPublicFeaturesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getPublicFeatures, materials);
    const getPublicFeaturesResponse: TGetPublicFeaturesResponse = response as TGetPublicFeaturesResponse;
    yield put(getPublicFeaturesAction.success(getPublicFeaturesResponse));
    successCallback?.(getPublicFeaturesResponse);
  } catch (err) {
    yield put(getPublicFeaturesAction.failure(err));
    failedCallback?.(err);
  }
}
