import { TAcademyState } from '@/redux/reducers/academy';
import { TCheckExistAcademySuccess } from '@/redux/actions/academy';

export const checkExistAcademyUpdateState = (
  state: TAcademyState,
  action: TCheckExistAcademySuccess,
): TAcademyState => ({
  ...state,
  checkExistAcademyResponse: action.payload.response,
});
