import ApiService from '@/services/api';

// TYPES

export type TDeleteTestimonialPaths = {
  id: string | number;
};
export type TDeleteTestimonialParams = unknown;

export type TDeleteTestimonialMaterials = {
  paths?: TDeleteTestimonialPaths;
  params?: TDeleteTestimonialParams;
};

export type TDeleteTestimonialResponse = unknown;

// FUNCTION

export const deleteTestimonial = async ({
  paths,
  params,
}: TDeleteTestimonialMaterials): Promise<TDeleteTestimonialResponse> => {
  const response = await ApiService.delete(`/api-admin/testimonials/${paths?.id}`, { params });
  return response?.data;
};
