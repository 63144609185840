import { createActionCreator } from 'deox';

import { TCheckExistAcademyMaterials, TCheckExistAcademyResponse } from '@/services/api/academy/check-exist-academy';

// CONSTANTS

export enum ECheckExistAcademyAction {
  CHECK_EXIST_ACADEMY = 'CHECK_EXIST_ACADEMY',
  CHECK_EXIST_ACADEMY_REQUEST = 'CHECK_EXIST_ACADEMY_REQUEST',
  CHECK_EXIST_ACADEMY_SUCCESS = 'CHECK_EXIST_ACADEMY_SUCCESS',
  CHECK_EXIST_ACADEMY_FAILED = 'CHECK_EXIST_ACADEMY_FAILED',
}

// TYPES

export type TCheckExistAcademyRequest = {
  type: ECheckExistAcademyAction.CHECK_EXIST_ACADEMY_REQUEST;
  payload: {
    materials: TCheckExistAcademyMaterials;
    successCallback?: (response: TCheckExistAcademyResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCheckExistAcademySuccess = {
  type: ECheckExistAcademyAction.CHECK_EXIST_ACADEMY_SUCCESS;
  payload: { response?: TCheckExistAcademyResponse };
};

export type TCheckExistAcademyFailed = { type: ECheckExistAcademyAction.CHECK_EXIST_ACADEMY_FAILED };

// FUNCTION

export const checkExistAcademyAction = {
  request: createActionCreator(
    ECheckExistAcademyAction.CHECK_EXIST_ACADEMY_REQUEST,
    (resolve) =>
      (
        materials: TCheckExistAcademyMaterials,
        successCallback?: (response: TCheckExistAcademyResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCheckExistAcademyRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECheckExistAcademyAction.CHECK_EXIST_ACADEMY_SUCCESS,
    (resolve) =>
      (response?: TCheckExistAcademyResponse): TCheckExistAcademySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECheckExistAcademyAction.CHECK_EXIST_ACADEMY_FAILED,
    (resolve) =>
      (error: unknown): TCheckExistAcademyFailed =>
        resolve({ error }),
  ),
};
