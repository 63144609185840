import ApiService from '@/services/api';

// TYPES

export type TCreateInvoiceParams = unknown;
export type TCreateInvoiceBody = unknown;

export type TCreateInvoiceMaterials = {
  params?: TCreateInvoiceParams;
  body?: TCreateInvoiceBody;
};

export type TCreateInvoiceResponse = unknown;

// FUNCTION

export const createInvoice = async ({ params, body }: TCreateInvoiceMaterials): Promise<TCreateInvoiceResponse> => {
  const response = await ApiService.post(`/api-admin/invoices`, body, { params });
  return response?.data;
};
