import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTestimonialsAction } from '@/redux/actions';
import { getTestimonials, TGetTestimonialsResponse } from '@/services/api';

// FUNCTION

export function* getTestimonialsSaga(action: ActionType<typeof getTestimonialsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTestimonials, materials);
    const getTestimonialsResponse: TGetTestimonialsResponse = response as TGetTestimonialsResponse;
    yield put(getTestimonialsAction.success(getTestimonialsResponse));
    successCallback?.(getTestimonialsResponse);
  } catch (err) {
    yield put(getTestimonialsAction.failure(err));
    failedCallback?.(err);
  }
}
