import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getPublicPackagesAction } from '@/redux/actions';
import { getPublicPackages, TGetPublicPackagesResponse } from '@/services/api';

// FUNCTION

export function* getPublicPackagesSaga(action: ActionType<typeof getPublicPackagesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getPublicPackages, materials);
    const getPublicPackagesResponse: TGetPublicPackagesResponse = response as TGetPublicPackagesResponse;
    yield put(getPublicPackagesAction.success(getPublicPackagesResponse));
    successCallback?.(getPublicPackagesResponse);
  } catch (err) {
    yield put(getPublicPackagesAction.failure(err));
    failedCallback?.(err);
  }
}
