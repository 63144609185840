import { TTestimonialState } from '@/redux/reducers/testimonial';
import { TUpdateTestimonialSuccess } from '@/redux/actions/testimonial';

export const updateTestimonialUpdateState = (
  state: TTestimonialState,
  action: TUpdateTestimonialSuccess,
): TTestimonialState => ({
  ...state,
  updateTestimonialResponse: action.payload.response,
});
