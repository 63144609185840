import { all, takeLatest } from 'redux-saga/effects';

import {
  checkExistAcademyAction,
  createAcademyAction,
  deleteAcademyAction,
  getAcademiesAction,
  getAcademyByDomainAction,
  getAcademyPaymentsAction,
  getAcademyAction,
  updateAcademyAction,
} from '@/redux/actions';

import { checkExistAcademySaga } from './check-exist-academy';
import { createAcademySaga } from './create-academy';
import { deleteAcademySaga } from './delete-academy';
import { getAcademiesSaga } from './get-academies';
import { getAcademyByDomainSaga } from './get-academy-by-domain';
import { getAcademyPaymentsSaga } from './get-academy-payments';
import { getAcademySaga } from './get-academy';
import { updateAcademySaga } from './update-academy';

export default function* root(): Generator {
  yield all([
    takeLatest(checkExistAcademyAction.request.type, checkExistAcademySaga),
    takeLatest(createAcademyAction.request.type, createAcademySaga),
    takeLatest(deleteAcademyAction.request.type, deleteAcademySaga),
    takeLatest(getAcademiesAction.request.type, getAcademiesSaga),
    takeLatest(getAcademyByDomainAction.request.type, getAcademyByDomainSaga),
    takeLatest(getAcademyPaymentsAction.request.type, getAcademyPaymentsSaga),
    takeLatest(getAcademyAction.request.type, getAcademySaga),
    takeLatest(updateAcademyAction.request.type, updateAcademySaga),
  ]);
}
