import { createActionCreator } from 'deox';

import { TDeleteCouponMaterials, TDeleteCouponResponse } from '@/services/api/coupon/delete-coupon';

// CONSTANTS

export enum EDeleteCouponAction {
  DELETE_COUPON = 'DELETE_COUPON',
  DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST',
  DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS',
  DELETE_COUPON_FAILED = 'DELETE_COUPON_FAILED',
}

// TYPES

export type TDeleteCouponRequest = {
  type: EDeleteCouponAction.DELETE_COUPON_REQUEST;
  payload: {
    materials: TDeleteCouponMaterials;
    successCallback?: (response: TDeleteCouponResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteCouponSuccess = {
  type: EDeleteCouponAction.DELETE_COUPON_SUCCESS;
  payload: { response: TDeleteCouponResponse };
};

export type TDeleteCouponFailed = { type: EDeleteCouponAction.DELETE_COUPON_FAILED };

// FUNCTION

export const deleteCouponAction = {
  request: createActionCreator(
    EDeleteCouponAction.DELETE_COUPON_REQUEST,
    (resolve) =>
      (
        materials: TDeleteCouponMaterials,
        successCallback?: (response: TDeleteCouponResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteCouponRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteCouponAction.DELETE_COUPON_SUCCESS,
    (resolve) =>
      (response: TDeleteCouponResponse): TDeleteCouponSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteCouponAction.DELETE_COUPON_FAILED,
    (resolve) =>
      (error: unknown): TDeleteCouponFailed =>
        resolve({ error }),
  ),
};
