import { createActionCreator } from 'deox';

import { TCreateTestimonialMaterials, TCreateTestimonialResponse } from '@/services/api/testimonial/create-testimonial';

// CONSTANTS

export enum ECreateTestimonialAction {
  CREATE_TESTIMONIAL = 'CREATE_TESTIMONIAL',
  CREATE_TESTIMONIAL_REQUEST = 'CREATE_TESTIMONIAL_REQUEST',
  CREATE_TESTIMONIAL_SUCCESS = 'CREATE_TESTIMONIAL_SUCCESS',
  CREATE_TESTIMONIAL_FAILED = 'CREATE_TESTIMONIAL_FAILED',
}

// TYPES

export type TCreateTestimonialRequest = {
  type: ECreateTestimonialAction.CREATE_TESTIMONIAL_REQUEST;
  payload: {
    materials: TCreateTestimonialMaterials;
    successCallback?: (response: TCreateTestimonialResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateTestimonialSuccess = {
  type: ECreateTestimonialAction.CREATE_TESTIMONIAL_SUCCESS;
  payload: { response: TCreateTestimonialResponse };
};

export type TCreateTestimonialFailed = { type: ECreateTestimonialAction.CREATE_TESTIMONIAL_FAILED };

// FUNCTION

export const createTestimonialAction = {
  request: createActionCreator(
    ECreateTestimonialAction.CREATE_TESTIMONIAL_REQUEST,
    (resolve) =>
      (
        materials: TCreateTestimonialMaterials,
        successCallback?: (response: TCreateTestimonialResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateTestimonialRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateTestimonialAction.CREATE_TESTIMONIAL_SUCCESS,
    (resolve) =>
      (response: TCreateTestimonialResponse): TCreateTestimonialSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateTestimonialAction.CREATE_TESTIMONIAL_FAILED,
    (resolve) =>
      (error: unknown): TCreateTestimonialFailed =>
        resolve({ error }),
  ),
};
