import { createReducer } from 'deox';

import {
  TCreateInvoiceByUserResponse,
  TCreateInvoiceResponse,
  TDeleteInvoiceResponse,
  TGetInvoiceResponse,
  TGetInvoicesResponse,
  TUpdateInvoiceResponse,
} from '@/services/api/invoice';
import {
  createInvoiceByUserAction,
  createInvoiceAction,
  deleteInvoiceAction,
  getInvoiceAction,
  getInvoicesAction,
  updateInvoiceAction,
} from '@/redux/actions';
import { createInvoiceByUserUpdateState } from './create-invoice-by-user';
import { createInvoiceUpdateState } from './create-invoice';
import { deleteInvoiceUpdateState } from './delete-invoice';
import { getInvoiceUpdateState } from './get-invoice';
import { getInvoicesUpdateState } from './get-invoices';
import { updateInvoiceUpdateState } from './update-invoice';

export type TInvoiceState = {
  createInvoiceByUserResponse?: TCreateInvoiceByUserResponse;
  createInvoiceResponse?: TCreateInvoiceResponse;
  deleteInvoiceResponse?: TDeleteInvoiceResponse;
  getInvoiceResponse?: TGetInvoiceResponse;
  getInvoicesResponse?: TGetInvoicesResponse;
  updateInvoiceResponse?: TUpdateInvoiceResponse;
};

const initialState: TInvoiceState = {
  createInvoiceByUserResponse: undefined,
  createInvoiceResponse: undefined,
  deleteInvoiceResponse: undefined,
  getInvoiceResponse: undefined,
  getInvoicesResponse: undefined,
  updateInvoiceResponse: undefined,
};

const InvoiceReducer = createReducer(initialState, (handleAction) => [
  handleAction(createInvoiceByUserAction.success, createInvoiceByUserUpdateState),
  handleAction(createInvoiceAction.success, createInvoiceUpdateState),
  handleAction(deleteInvoiceAction.success, deleteInvoiceUpdateState),
  handleAction(getInvoiceAction.success, getInvoiceUpdateState),
  handleAction(getInvoicesAction.success, getInvoicesUpdateState),
  handleAction(updateInvoiceAction.success, updateInvoiceUpdateState),
]);

export default InvoiceReducer;
