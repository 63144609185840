import ApiService from '@/services/api';

// TYPES

export type TDeleteAdvisoryPaths = {
  id: string | number;
};
export type TDeleteAdvisoryParams = unknown;

export type TDeleteAdvisoryMaterials = {
  paths?: TDeleteAdvisoryPaths;
  params?: TDeleteAdvisoryParams;
};

export type TDeleteAdvisoryResponse = unknown;

// FUNCTION

export const deleteAdvisory = async ({ paths, params }: TDeleteAdvisoryMaterials): Promise<TDeleteAdvisoryResponse> => {
  const response = await ApiService.delete(`/api-admin/advisories/${paths?.id}`, { params });
  return response?.data;
};
