import { TSettings } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetSettingsParams = unknown;

export type TGetSettingsMaterials = {
  params?: TGetSettingsParams;
};

export type TGetSettingsResponse = TCommonResponse & {
  content: TSettings;
};

// FUNCTION

export const getSettings = async ({ params }: TGetSettingsMaterials): Promise<TGetSettingsResponse> => {
  const response = await ApiService.get(`/api-admin/settings`, { params });
  return response?.data;
};
