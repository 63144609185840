import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updatePackageAction } from '@/redux/actions';
import { updatePackage, TUpdatePackageResponse } from '@/services/api';

// FUNCTION

export function* updatePackageSaga(action: ActionType<typeof updatePackageAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updatePackage, materials);
    const updatePackageResponse: TUpdatePackageResponse = response as TUpdatePackageResponse;
    yield put(updatePackageAction.success(updatePackageResponse));
    successCallback?.(updatePackageResponse);
  } catch (err) {
    yield put(updatePackageAction.failure(err));
    failedCallback?.(err);
  }
}
