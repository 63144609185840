import { createReducer } from 'deox';

import { TGetPublicFeaturesResponse } from '@/services/api/feature';
import { getPublicFeaturesAction } from '@/redux/actions';
import { getPublicFeaturesUpdateState } from './get-public-features';

export type TFeatureState = {
  getPublicFeaturesResponse?: TGetPublicFeaturesResponse;
};

const initialState: TFeatureState = {
  getPublicFeaturesResponse: undefined,
};

const FeatureReducer = createReducer(initialState, (handleAction) => [
  handleAction(getPublicFeaturesAction.success, getPublicFeaturesUpdateState),
]);

export default FeatureReducer;
