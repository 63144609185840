import { TTestimonialState } from '@/redux/reducers/testimonial';
import { TDeleteTestimonialSuccess } from '@/redux/actions/testimonial';

export const deleteTestimonialUpdateState = (
  state: TTestimonialState,
  action: TDeleteTestimonialSuccess,
): TTestimonialState => ({
  ...state,
  deleteTestimonialResponse: action.payload.response,
});
