import { TFeatureState } from '@/redux/reducers/feature';
import { TGetPublicFeaturesSuccess } from '@/redux/actions/feature';

export const getPublicFeaturesUpdateState = (
  state: TFeatureState,
  action: TGetPublicFeaturesSuccess,
): TFeatureState => ({
  ...state,
  getPublicFeaturesResponse: action.payload.response,
});
