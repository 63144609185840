import { createActionCreator } from 'deox';

import { TCheckExistUserMaterials, TCheckExistUserResponse } from '@/services/api/user/check-exist-user';

// CONSTANTS

export enum ECheckExistUserAction {
  CHECK_EXIST_USER = 'CHECK_EXIST_USER',
  CHECK_EXIST_USER_REQUEST = 'CHECK_EXIST_USER_REQUEST',
  CHECK_EXIST_USER_SUCCESS = 'CHECK_EXIST_USER_SUCCESS',
  CHECK_EXIST_USER_FAILED = 'CHECK_EXIST_USER_FAILED',
}

// TYPES

export type TCheckExistUserRequest = {
  type: ECheckExistUserAction.CHECK_EXIST_USER_REQUEST;
  payload: {
    materials: TCheckExistUserMaterials;
    successCallback?: (response: TCheckExistUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCheckExistUserSuccess = {
  type: ECheckExistUserAction.CHECK_EXIST_USER_SUCCESS;
  payload: { response?: TCheckExistUserResponse };
};

export type TCheckExistUserFailed = { type: ECheckExistUserAction.CHECK_EXIST_USER_FAILED };

// FUNCTION

export const checkExistUserAction = {
  request: createActionCreator(
    ECheckExistUserAction.CHECK_EXIST_USER_REQUEST,
    (resolve) =>
      (
        materials: TCheckExistUserMaterials,
        successCallback?: (response: TCheckExistUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCheckExistUserRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECheckExistUserAction.CHECK_EXIST_USER_SUCCESS,
    (resolve) =>
      (response?: TCheckExistUserResponse): TCheckExistUserSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECheckExistUserAction.CHECK_EXIST_USER_FAILED,
    (resolve) =>
      (error: unknown): TCheckExistUserFailed =>
        resolve({ error }),
  ),
};
