import { createActionCreator } from 'deox';

import { TGetDashboardCountMaterials, TGetDashboardCountResponse } from '@/services/api/report/get-dashboard-count';

// CONSTANTS

export enum EGetDashboardCountAction {
  GET_DASHBOARD_COUNT = 'GET_DASHBOARD_COUNT',
  GET_DASHBOARD_COUNT_REQUEST = 'GET_DASHBOARD_COUNT_REQUEST',
  GET_DASHBOARD_COUNT_SUCCESS = 'GET_DASHBOARD_COUNT_SUCCESS',
  GET_DASHBOARD_COUNT_FAILED = 'GET_DASHBOARD_COUNT_FAILED',
}

// TYPES

export type TGetDashboardCountRequest = {
  type: EGetDashboardCountAction.GET_DASHBOARD_COUNT_REQUEST;
  payload: {
    materials: TGetDashboardCountMaterials;
    successCallback?: (response: TGetDashboardCountResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetDashboardCountSuccess = {
  type: EGetDashboardCountAction.GET_DASHBOARD_COUNT_SUCCESS;
  payload: { response: TGetDashboardCountResponse };
};

export type TGetDashboardCountFailed = { type: EGetDashboardCountAction.GET_DASHBOARD_COUNT_FAILED };

// FUNCTION

export const getDashboardCountAction = {
  request: createActionCreator(
    EGetDashboardCountAction.GET_DASHBOARD_COUNT_REQUEST,
    (resolve) =>
      (
        materials: TGetDashboardCountMaterials,
        successCallback?: (response: TGetDashboardCountResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetDashboardCountRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetDashboardCountAction.GET_DASHBOARD_COUNT_SUCCESS,
    (resolve) =>
      (response: TGetDashboardCountResponse): TGetDashboardCountSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetDashboardCountAction.GET_DASHBOARD_COUNT_FAILED,
    (resolve) =>
      (error: unknown): TGetDashboardCountFailed =>
        resolve({ error }),
  ),
};
