import { createReducer } from 'deox';

import {
  TCreateAdvisoryResponse,
  TDeleteAdvisoryResponse,
  TGetAdvisoriesResponse,
  TUpdateAdvisoryResponse,
} from '@/services/api/advisory';
import { createAdvisoryAction, deleteAdvisoryAction, getAdvisoriesAction, updateAdvisoryAction } from '@/redux/actions';
import { createAdvisoryUpdateState } from './create-advisory';
import { deleteAdvisoryUpdateState } from './delete-advisory';
import { getAdvisoriesUpdateState } from './get-advisories';
import { updateAdvisoryUpdateState } from './update-advisory';

export type TAdvisoryState = {
  createAdvisoryResponse?: TCreateAdvisoryResponse;
  deleteAdvisoryResponse?: TDeleteAdvisoryResponse;
  getAdvisoriesResponse?: TGetAdvisoriesResponse;
  updateAdvisoryResponse?: TUpdateAdvisoryResponse;
};

const initialState: TAdvisoryState = {
  createAdvisoryResponse: undefined,
  deleteAdvisoryResponse: undefined,
  getAdvisoriesResponse: undefined,
  updateAdvisoryResponse: undefined,
};

const AdvisoryReducer = createReducer(initialState, (handleAction) => [
  handleAction(createAdvisoryAction.success, createAdvisoryUpdateState),
  handleAction(deleteAdvisoryAction.success, deleteAdvisoryUpdateState),
  handleAction(getAdvisoriesAction.success, getAdvisoriesUpdateState),
  handleAction(updateAdvisoryAction.success, updateAdvisoryUpdateState),
]);

export default AdvisoryReducer;
