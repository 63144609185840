import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getAcademyPaymentsAction } from '@/redux/actions';
import { getAcademyPayments, TGetAcademyPaymentsResponse } from '@/services/api';

// FUNCTION

export function* getAcademyPaymentsSaga(action: ActionType<typeof getAcademyPaymentsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getAcademyPayments, materials);
    const getAcademyPaymentsResponse: TGetAcademyPaymentsResponse = response as TGetAcademyPaymentsResponse;
    yield put(getAcademyPaymentsAction.success(getAcademyPaymentsResponse));
    successCallback?.(getAcademyPaymentsResponse);
  } catch (err) {
    yield put(getAcademyPaymentsAction.failure(err));
    failedCallback?.(err);
  }
}
