import { all, takeLatest } from 'redux-saga/effects';

import {
  checkExistUserAction,
  deleteUserAction,
  getMyProfileAction,
  getUsersSearchAction,
  getUsersAction,
} from '@/redux/actions';

import { checkExistUserSaga } from './check-exist-user';
import { deleteUserSaga } from './delete-user';
import { getMyProfileSaga } from './get-my-profile';
import { getUsersSearchSaga } from './get-users-search';
import { getUsersSaga } from './get-users';

export default function* root(): Generator {
  yield all([
    takeLatest(checkExistUserAction.request.type, checkExistUserSaga),
    takeLatest(deleteUserAction.request.type, deleteUserSaga),
    takeLatest(getMyProfileAction.request.type, getMyProfileSaga),
    takeLatest(getUsersSearchAction.request.type, getUsersSearchSaga),
    takeLatest(getUsersAction.request.type, getUsersSaga),
  ]);
}
