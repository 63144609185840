import { createActionCreator } from 'deox';

import { TUpdateTestimonialMaterials, TUpdateTestimonialResponse } from '@/services/api/testimonial/update-testimonial';

// CONSTANTS

export enum EUpdateTestimonialAction {
  UPDATE_TESTIMONIAL = 'UPDATE_TESTIMONIAL',
  UPDATE_TESTIMONIAL_REQUEST = 'UPDATE_TESTIMONIAL_REQUEST',
  UPDATE_TESTIMONIAL_SUCCESS = 'UPDATE_TESTIMONIAL_SUCCESS',
  UPDATE_TESTIMONIAL_FAILED = 'UPDATE_TESTIMONIAL_FAILED',
}

// TYPES

export type TUpdateTestimonialRequest = {
  type: EUpdateTestimonialAction.UPDATE_TESTIMONIAL_REQUEST;
  payload: {
    materials: TUpdateTestimonialMaterials;
    successCallback?: (response: TUpdateTestimonialResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateTestimonialSuccess = {
  type: EUpdateTestimonialAction.UPDATE_TESTIMONIAL_SUCCESS;
  payload: { response: TUpdateTestimonialResponse };
};

export type TUpdateTestimonialFailed = { type: EUpdateTestimonialAction.UPDATE_TESTIMONIAL_FAILED };

// FUNCTION

export const updateTestimonialAction = {
  request: createActionCreator(
    EUpdateTestimonialAction.UPDATE_TESTIMONIAL_REQUEST,
    (resolve) =>
      (
        materials: TUpdateTestimonialMaterials,
        successCallback?: (response: TUpdateTestimonialResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateTestimonialRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateTestimonialAction.UPDATE_TESTIMONIAL_SUCCESS,
    (resolve) =>
      (response: TUpdateTestimonialResponse): TUpdateTestimonialSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateTestimonialAction.UPDATE_TESTIMONIAL_FAILED,
    (resolve) =>
      (error: unknown): TUpdateTestimonialFailed =>
        resolve({ error }),
  ),
};
