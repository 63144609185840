import { all, takeLatest } from 'redux-saga/effects';

import { createAdvisoryAction, deleteAdvisoryAction, getAdvisoriesAction, updateAdvisoryAction } from '@/redux/actions';

import { createAdvisorySaga } from './create-advisory';
import { deleteAdvisorySaga } from './delete-advisory';
import { getAdvisoriesSaga } from './get-advisories';
import { updateAdvisorySaga } from './update-advisory';

export default function* root(): Generator {
  yield all([
    takeLatest(createAdvisoryAction.request.type, createAdvisorySaga),
    takeLatest(deleteAdvisoryAction.request.type, deleteAdvisorySaga),
    takeLatest(getAdvisoriesAction.request.type, getAdvisoriesSaga),
    takeLatest(updateAdvisoryAction.request.type, updateAdvisorySaga),
  ]);
}
