import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getAcademiesAction } from '@/redux/actions';
import { getAcademies, TGetAcademiesResponse } from '@/services/api';

// FUNCTION

export function* getAcademiesSaga(action: ActionType<typeof getAcademiesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getAcademies, materials);
    const getAcademiesResponse: TGetAcademiesResponse = response as TGetAcademiesResponse;
    yield put(getAcademiesAction.success(getAcademiesResponse));
    successCallback?.(getAcademiesResponse);
  } catch (err) {
    yield put(getAcademiesAction.failure(err));
    failedCallback?.(err);
  }
}
