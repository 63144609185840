import { all, takeLatest } from 'redux-saga/effects';

import { googleLoginAction, loginAction, refreshTokenAction, registerAction } from '@/redux/actions';

import { googleLoginSaga } from './google-login';
import { loginSaga } from './login';
import { refreshTokenSaga } from './refresh-token';
import { registerSaga } from './register';

export default function* root(): Generator {
  yield all([
    takeLatest(googleLoginAction.request.type, googleLoginSaga),
    takeLatest(loginAction.request.type, loginSaga),
    takeLatest(refreshTokenAction.request.type, refreshTokenSaga),
    takeLatest(registerAction.request.type, registerSaga),
  ]);
}
