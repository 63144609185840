import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createAdvisoryAction } from '@/redux/actions';
import { createAdvisory, TCreateAdvisoryResponse } from '@/services/api';

// FUNCTION

export function* createAdvisorySaga(action: ActionType<typeof createAdvisoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createAdvisory, materials);
    const createAdvisoryResponse: TCreateAdvisoryResponse = response as TCreateAdvisoryResponse;
    yield put(createAdvisoryAction.success(createAdvisoryResponse));
    successCallback?.(createAdvisoryResponse);
  } catch (err) {
    yield put(createAdvisoryAction.failure(err));
    failedCallback?.(err);
  }
}
