import { createActionCreator } from 'deox';

import { TCreateAdvisoryMaterials, TCreateAdvisoryResponse } from '@/services/api/advisory/create-advisory';

// CONSTANTS

export enum ECreateAdvisoryAction {
  CREATE_ADVISORY = 'CREATE_ADVISORY',
  CREATE_ADVISORY_REQUEST = 'CREATE_ADVISORY_REQUEST',
  CREATE_ADVISORY_SUCCESS = 'CREATE_ADVISORY_SUCCESS',
  CREATE_ADVISORY_FAILED = 'CREATE_ADVISORY_FAILED',
}

// TYPES

export type TCreateAdvisoryRequest = {
  type: ECreateAdvisoryAction.CREATE_ADVISORY_REQUEST;
  payload: {
    materials: TCreateAdvisoryMaterials;
    successCallback?: (response: TCreateAdvisoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateAdvisorySuccess = {
  type: ECreateAdvisoryAction.CREATE_ADVISORY_SUCCESS;
  payload: { response: TCreateAdvisoryResponse };
};

export type TCreateAdvisoryFailed = { type: ECreateAdvisoryAction.CREATE_ADVISORY_FAILED };

// FUNCTION

export const createAdvisoryAction = {
  request: createActionCreator(
    ECreateAdvisoryAction.CREATE_ADVISORY_REQUEST,
    (resolve) =>
      (
        materials: TCreateAdvisoryMaterials,
        successCallback?: (response: TCreateAdvisoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateAdvisoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateAdvisoryAction.CREATE_ADVISORY_SUCCESS,
    (resolve) =>
      (response: TCreateAdvisoryResponse): TCreateAdvisorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateAdvisoryAction.CREATE_ADVISORY_FAILED,
    (resolve) =>
      (error: unknown): TCreateAdvisoryFailed =>
        resolve({ error }),
  ),
};
