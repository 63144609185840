import { createActionCreator } from 'deox';

import { TDeletePaymentMaterials, TDeletePaymentResponse } from '@/services/api/payment/delete-payment';

// CONSTANTS

export enum EDeletePaymentAction {
  DELETE_PAYMENT = 'DELETE_PAYMENT',
  DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST',
  DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS',
  DELETE_PAYMENT_FAILED = 'DELETE_PAYMENT_FAILED',
}

// TYPES

export type TDeletePaymentRequest = {
  type: EDeletePaymentAction.DELETE_PAYMENT_REQUEST;
  payload: {
    materials: TDeletePaymentMaterials;
    successCallback?: (response: TDeletePaymentResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeletePaymentSuccess = {
  type: EDeletePaymentAction.DELETE_PAYMENT_SUCCESS;
  payload: { response: TDeletePaymentResponse };
};

export type TDeletePaymentFailed = { type: EDeletePaymentAction.DELETE_PAYMENT_FAILED };

// FUNCTION

export const deletePaymentAction = {
  request: createActionCreator(
    EDeletePaymentAction.DELETE_PAYMENT_REQUEST,
    (resolve) =>
      (
        materials: TDeletePaymentMaterials,
        successCallback?: (response: TDeletePaymentResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeletePaymentRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeletePaymentAction.DELETE_PAYMENT_SUCCESS,
    (resolve) =>
      (response: TDeletePaymentResponse): TDeletePaymentSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeletePaymentAction.DELETE_PAYMENT_FAILED,
    (resolve) =>
      (error: unknown): TDeletePaymentFailed =>
        resolve({ error }),
  ),
};
