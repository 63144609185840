import { TPackageState } from '@/redux/reducers/package';
import { TGetPublicPackagesSuccess } from '@/redux/actions/package';

export const getPublicPackagesUpdateState = (
  state: TPackageState,
  action: TGetPublicPackagesSuccess,
): TPackageState => ({
  ...state,
  getPublicPackagesResponse: action.payload.response,
});
