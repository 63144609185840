import { TTestimonialState } from '@/redux/reducers/testimonial';
import { TCreateTestimonialSuccess } from '@/redux/actions/testimonial';

export const createTestimonialUpdateState = (
  state: TTestimonialState,
  action: TCreateTestimonialSuccess,
): TTestimonialState => ({
  ...state,
  createTestimonialResponse: action.payload.response,
});
