import { createActionCreator } from 'deox';

import { TGetPublicCouponsMaterials, TGetPublicCouponsResponse } from '@/services/api/coupon/get-public-coupons';

// CONSTANTS

export enum EGetPublicCouponsAction {
  GET_PUBLIC_COUPONS = 'GET_PUBLIC_COUPONS',
  GET_PUBLIC_COUPONS_REQUEST = 'GET_PUBLIC_COUPONS_REQUEST',
  GET_PUBLIC_COUPONS_SUCCESS = 'GET_PUBLIC_COUPONS_SUCCESS',
  GET_PUBLIC_COUPONS_FAILED = 'GET_PUBLIC_COUPONS_FAILED',
}

// TYPES

export type TGetPublicCouponsRequest = {
  type: EGetPublicCouponsAction.GET_PUBLIC_COUPONS_REQUEST;
  payload: {
    materials: TGetPublicCouponsMaterials;
    successCallback?: (response: TGetPublicCouponsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPublicCouponsSuccess = {
  type: EGetPublicCouponsAction.GET_PUBLIC_COUPONS_SUCCESS;
  payload: { response: TGetPublicCouponsResponse };
};

export type TGetPublicCouponsFailed = { type: EGetPublicCouponsAction.GET_PUBLIC_COUPONS_FAILED };

// FUNCTION

export const getPublicCouponsAction = {
  request: createActionCreator(
    EGetPublicCouponsAction.GET_PUBLIC_COUPONS_REQUEST,
    (resolve) =>
      (
        materials: TGetPublicCouponsMaterials,
        successCallback?: (response: TGetPublicCouponsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPublicCouponsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPublicCouponsAction.GET_PUBLIC_COUPONS_SUCCESS,
    (resolve) =>
      (response: TGetPublicCouponsResponse): TGetPublicCouponsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPublicCouponsAction.GET_PUBLIC_COUPONS_FAILED,
    (resolve) =>
      (error: unknown): TGetPublicCouponsFailed =>
        resolve({ error }),
  ),
};
