import { TTestimonial } from '@/common/models';
import { TCommonPaginate, TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetTestimonialsParams = {
  page: number;
  size: number;
  sort?: string;
  search?: string;
  auditingStatuses?: string;
};

export type TGetTestimonialsMaterials = {
  params?: TGetTestimonialsParams;
};

export type TGetTestimonialsResponse = TCommonResponse & {
  content: TCommonPaginate & {
    content: TTestimonial[];
  };
};

// FUNCTION

export const getTestimonials = async ({ params }: TGetTestimonialsMaterials): Promise<TGetTestimonialsResponse> => {
  const response = await ApiService.get(`/api-admin/testimonials`, { params });
  return response?.data;
};
