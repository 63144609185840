export enum EResponseCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  MULTI_STATUS = 207,
  ALREADY_REPORTED = 208,
  I_AM_USED = 226,

  MULTIPLE_CHOICE = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,

  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED_REQUEST = 421,
  UNPROCESSABLE_ENTITY = 422,
  LOCKED = 423,
  FAILED_DEPENDENCY = 424,
  TOO_EARLY = 425,
  UPGRADE_REQUIRED = 426,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
  VARIANT_ALSO_NEGOTIATES = 506,
  INSUFFICIENT_STORAGE = 507,
  LOOP_DETECTED = 508,
  NOT_EXTENDED = 510,
  NETWORK_AUTHENTICATION_REQUIRED = 511,
}

export enum ETypeNotification {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export enum ETimeoutDebounce {
  SEARCH = 300,
}

export enum EEmpty {
  DASH = '-',
  ZERO = 0,
}

export enum EFormat {
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'DD/MM/YYYY - HH:mm' = 'DD/MM/YYYY - HH:mm',
  'HH:mm:ss' = 'HH:mm:ss',
  'dddd, DD' = 'dddd, DD',
  'dddd' = 'dddd',
  'HH:mm' = 'HH:mm',
  'MM/YYYY' = 'MM/YYYY',
  'wo, YYYY' = 'wo, YYYY',
  'dddd | DD/MM/YYYY - HH:mm' = 'dddd | DD/MM/YYYY - HH:mm',
  'YYYY' = 'YYYY',
  'DD' = 'DD',
  'ms/YYYY' = 'ms/YYYY',
}

export enum EAuditingStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EAcademyStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
}

export enum ETransactionStatus {
  PAID = 1,
  PENDING = 2,
  FAILURE = 3,
  NEW = 4,
}

export enum EOrderStatus {
  PENDING = 1,
  CONFIRMED = 2,
  CANCELLED = 3,
  REFUNDED = 4,
  SHIPPER = 5,
  READY_FOR_DELIVERY = 6,
  COMPLETED = 7,
}

export enum EDegreeType {
  BEGINNER = 1,
  INTERMEDIATE = 2,
  ADVANCED = 3,
}

export enum ESalaryType {
  MONTHLY = 'MONTHLY',
  SESSION = 'SESSION',
}

export enum EUserType {
  PLAYER = 'player',
  TEACHER = 'teacher',
  MANAGER = 'manager',
  ADMIN = 'admin',
  USER = 'user',
  MANAGER_BRANCH = 'manager_branch',
  ACCOUNTANT = 'accountant',
  SALE_MARKETING = 'sale_marketing',
}

export enum EDayOfWeek {
  MONDAY = '1',
  TUESDAY = '2',
  WEDNESDAY = '3',
  THURSDAY = '4',
  FRIDAY = '5',
  SATURDAY = '6',
  SUNDAY = '7',
}

export enum ETypeCheckIn {
  NONE = -1,
  PRESENT = 1,
  ABSENT = 0,
}

export enum ETypeCategory {
  EXPENSE = 1,
  PRODUCT = 2,
  REVENUE = 3,
}

export enum ETypeProductUploadImages {
  REWARDS = 'rewards',
  PRODUCTS = 'products',
  ACADEMIES = 'academies',
}

export enum ETypeImageAcademy {
  LOGO = '1',
  QR_CODE = '2',
}

export enum ELevel {
  DIAMOND = 4,
  GOLD = 3,
  SILVER = 2,
  FRIENDLY = 1,
  COMMON = 0,
}

export enum ECategoryMeanType {
  REVENUE_MEMBERSHIP_FEE = 'REVENUE_MEMBERSHIP_FEE',
  REVENUE_TRANSPORT_FEE = 'REVENUE_TRANSPORT_FEE',
  PRODUCT_DIGITAL_CARD = 'PRODUCT_DIGITAL_CARD',
}

export enum EResetType {
  ATTENDANCE = 1,
  TRANSACTION_NUMBER_OF_UNITS = 2,
  TRANSACTION = 3,
  NOTIFICATION = 4,
}

export enum EPricingModelType {
  BASE_COURES = 1,
  MONTHLY = 2,
}

export enum EPointActionType {
  ATTENDANCES = 1,
  BIRTHDAY = 2,
  BUY_PRODUCT = 3,
  REFERRALS = 4,
}

export enum EReportUnitFilter {
  DATE = 'DATE',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
}

export enum EBusStopDirection {
  FORWARD = 1,
  BACK = 2,
}

export enum ETransportFeeType {
  FREE = 0,
  TURN = 1,
  MONTH = 2,
}

export enum EInventoryStatus {
  STOCKING = 1,
  OUT_OF_STOCK = 2,
}

export enum ERequestTimeOffStatus {
  PENDING = 0,
  APPROVE = 1,
  REJECT = 2,
}

export enum ECategoryTarget {
  PLAYER = 'PLAYER',
  TEACHER = 'TEACHER',
  NONE = 'NONE',
}

export enum EClassIndex {
  PRIMARY = 0,
  ANOTHER = 1,
}

export enum ERole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
}

export enum EUnitTypeCoupon {
  VND = 'VND',
  PERCENT = 'PERCENT',
}

export enum EPaymentStatus {
  FAIL = 'FAIL',
  SUCCESS = 'SUCCESS',
  NEW = 'NEW',
  PENDING = 'PENDING',
  CANCEL = 'CANCEL',
}

export enum EPaymentType {
  CASH = 'CASH',
  BANK = 'BANK',
  GATEWAY = 'GATEWAY',
}

export enum EUploadFileType {
  APPLICATION_HEADER_LOGO = 'APPLICATION_HEADER_LOGO',
  APPLICATION_FOOTER_LOGO = 'APPLICATION_FOOTER_LOGO',
  APPLICATION_BANNER = 'APPLICATION_BANNER',
  APPLICATION_BANK_QRCODE = 'APPLICATION_BANK_QRCODE',
  APPLICATION_FAVICON = 'APPLICATION_FAVICON',
  APPLICATION_POPUP_BANNER = 'APPLICATION_POPUP_BANNER',
  USER_AVATAR = 'USER_AVATAR',
}

export enum EInvoiceStatus {
  NEW = 'NEW',
  PAID = 'PAID',
  OVER_DUE = 'OVER_DUE',
}

export enum EServiceCostingType {
  INSTALLATION_HUB_FEE = 'INSTALLATION_HUB_FEE',
  INSTALLATION_MOBILE_FEE = 'INSTALLATION_MOBILE_FEE',
  MAINTENANCE_FEE = 'MAINTENANCE_FEE',
  MAINTENANCE_FEE_YEAR = 'MAINTENANCE_FEE_YEAR',
  OUTSIZE_FEE = 'OUTSIZE_FEE',
}

export enum EUnitDurationType {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  DAY = 'DAY',
  HOUR = 'HOUR',
  SECOND = 'SECOND',
}

export enum EApp {
  HUB = 'hub',
  ADMIN = 'admincp',
}

export enum EGatewayMode {
  LIVE = 'LIVE',
  SANDBOX = 'SANDBOX',
}

export enum ECouponPolicyType {
  MULTIPLE = 0,
  SINGLE = 1,
}

export enum ECourseTimeUnitType {
  YEAR = 5,
  HALF_YEAR = 4,
  QUARTER = 3,
  MONTH = 2,
  DAY = 1,
}
