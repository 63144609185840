import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteInvoiceAction } from '@/redux/actions';
import { deleteInvoice, TDeleteInvoiceResponse } from '@/services/api';

// FUNCTION

export function* deleteInvoiceSaga(action: ActionType<typeof deleteInvoiceAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteInvoice, materials);
    const deleteInvoiceResponse: TDeleteInvoiceResponse = response as TDeleteInvoiceResponse;
    yield put(deleteInvoiceAction.success(deleteInvoiceResponse));
    successCallback?.(deleteInvoiceResponse);
  } catch (err) {
    yield put(deleteInvoiceAction.failure(err));
    failedCallback?.(err);
  }
}
