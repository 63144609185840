import { TSettingState } from '@/redux/reducers/setting';
import { TGetPublicSettingsSuccess } from '@/redux/actions/setting';

export const getPublicSettingsUpdateState = (
  state: TSettingState,
  action: TGetPublicSettingsSuccess,
): TSettingState => ({
  ...state,
  getPublicSettingsResponse: action.payload.response,
});
