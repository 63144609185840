import {
  EAcademyStatus,
  EAuditingStatus,
  ECouponPolicyType,
  ECourseTimeUnitType,
  EDayOfWeek,
  EDegreeType,
  EGatewayMode,
  EInventoryStatus,
  EInvoiceStatus,
  ELevel,
  EOrderStatus,
  EPaymentStatus,
  EPaymentType,
  EPointActionType,
  EPricingModelType,
  EReportUnitFilter,
  ERequestTimeOffStatus,
  ESalaryType,
  EServiceCostingType,
  ETransportFeeType,
  ETypeCheckIn,
  EUnitDurationType,
  EUnitTypeCoupon,
  EUserType,
} from '@/common/enums';
import { EIconColor, EIconName } from '@/components/Icon';
import { EStatusStyleType } from '@/components/Status';
import { ETagType } from '@/components/Tags';

/* eslint-disable no-useless-escape */
export const REGEX = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i,
  domain: /^[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/i,
  alphabetic: /^[a-z\s]+$/i,
  alphanumerial: /^[a-z0-9\s]+$/i,
  numeric: /^\d+$/i,
  onlySpecialKey: /[$&+,:;=?@#|'<>.^*()%`~_!\-"\]\[\\}{'/]/g,
};

export const dataTablePerPageOptions = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '75', value: '75' },
  { label: '100', value: '100' },
];
export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE = 10;

export const dataPaymentTypeOptions = [
  { value: EPaymentType.CASH, label: 'Tiền Mặt' },
  { value: EPaymentType.BANK, label: 'Chuyển Khoản' },
  { value: EPaymentType.GATEWAY, label: 'Cổng Thanh Toán' },
];

export const dataOrderStatusOptions = [
  {
    value: EOrderStatus.PENDING,
    label: 'Đang Xử Lý',
    data: {
      color: EIconColor.SELECTIVE_YELLOW,
      statusType: EStatusStyleType.WARNING,
    },
  },
  {
    value: EOrderStatus.CONFIRMED,
    label: 'Xác Nhận',
    data: {
      color: EIconColor.CERULEAN,
      statusType: EStatusStyleType.SUCCESS,
    },
  },
  {
    value: EOrderStatus.CANCELLED,
    label: 'Đã Huỷ',
    data: {
      color: EIconColor.POMEGRANATE,
      statusType: EStatusStyleType.DANGER,
    },
  },
  {
    value: EOrderStatus.REFUNDED,
    label: 'Hoàn Trả',
    data: {
      color: EIconColor.SELECTIVE_YELLOW,
      statusType: EStatusStyleType.WARNING,
    },
  },
  {
    value: EOrderStatus.SHIPPER,
    label: 'Đang Giao',
    data: {
      color: EIconColor.PURPLE_HEART,
      statusType: EStatusStyleType.SUCCESS,
    },
  },
  {
    value: EOrderStatus.READY_FOR_DELIVERY,
    label: 'Chuẩn Bị Vận Chuyển',
    data: {
      color: EIconColor.CERULEAN,
      statusType: EStatusStyleType.WARNING,
    },
  },
  {
    value: EOrderStatus.COMPLETED,
    label: 'Hoàn Thành',
    data: {
      color: EIconColor.APPLE,
      statusType: EStatusStyleType.SUCCESS,
    },
  },
];

export const dataAuditingStatusOptions = [
  { value: EAuditingStatus.ACTIVE, label: 'Hoạt Động', data: { statusType: EStatusStyleType.SUCCESS } },
  { value: EAuditingStatus.INACTIVE, label: 'Ngừng Hoạt Động', data: { statusType: EStatusStyleType.DANGER } },
];

export const dataProductStatusOptions = [
  { value: EAuditingStatus.ACTIVE, label: 'Đang Bán', data: { statusType: EStatusStyleType.SUCCESS } },
  { value: EAuditingStatus.INACTIVE, label: 'Ngừng Bán', data: { statusType: EStatusStyleType.DANGER } },
];

export const dataInventoryStatusOptions = [
  { value: EInventoryStatus.STOCKING, label: 'Còn Hàng', data: { statusType: EStatusStyleType.SUCCESS } },
  { value: EInventoryStatus.OUT_OF_STOCK, label: 'Hết Hàng', data: { statusType: EStatusStyleType.DANGER } },
];

export const dataDegreeTypeOptions = [
  { value: EDegreeType.BEGINNER, label: 'Tập Sự', data: { color: EIconColor.CERULEAN } },
  { value: EDegreeType.INTERMEDIATE, label: 'Trung Cấp', data: { color: EIconColor.SELECTIVE_YELLOW } },
  { value: EDegreeType.ADVANCED, label: 'Chuyên Nghiệp', data: { color: EIconColor.POMEGRANATE } },
];

export const dataSalaryTypeOptions = [
  { value: ESalaryType.MONTHLY, label: 'Theo Tháng' },
  { value: ESalaryType.SESSION, label: 'Theo Buổi' },
];

export const dataDayOfWeeksOptions = [
  { value: EDayOfWeek.MONDAY, label: 'Thứ 2' },
  { value: EDayOfWeek.TUESDAY, label: 'Thứ 3' },
  { value: EDayOfWeek.WEDNESDAY, label: 'Thứ 4' },
  { value: EDayOfWeek.THURSDAY, label: 'Thứ 5' },
  { value: EDayOfWeek.FRIDAY, label: 'Thứ 6' },
  { value: EDayOfWeek.SATURDAY, label: 'Thứ 7' },
  { value: EDayOfWeek.SUNDAY, label: 'CN' },
];

export const dataTypeCheckInOptions = [
  {
    value: ETypeCheckIn.PRESENT,
    label: 'Có Mặt',
    data: { statusType: EStatusStyleType.SUCCESS, color: EIconColor.APPLE, iconName: EIconName.Check },
  },
  {
    value: ETypeCheckIn.ABSENT,
    label: 'Vắng Mặt',
    data: { statusType: EStatusStyleType.DANGER, color: EIconColor.POMEGRANATE, iconName: EIconName.X },
  },
  {
    value: ETypeCheckIn.NONE,
    label: 'Chưa Điểm Danh',
    data: { statusType: EStatusStyleType.WARNING, color: EIconColor.SELECTIVE_YELLOW },
  },
];

export const dataPointsRangeOptions = [
  { value: '1000-', label: '> 1000', data: { fromPoint: 1000, toPoint: undefined } },
  { value: '800-1000', label: '800 - 1000', data: { fromPoint: 800, toPoint: 1000 } },
  { value: '500-800', label: '500 - 800', data: { fromPoint: 500, toPoint: 800 } },
  { value: '300-500', label: '300 - 500', data: { fromPoint: 300, toPoint: 500 } },
  { value: '200-300', label: '200 - 300', data: { fromPoint: 200, toPoint: 300 } },
  { value: '-200', label: '< 200', data: { fromPoint: undefined, toPoint: 200 } },
];

export const dataAcademySizeOptions = [
  { value: 1, label: '0-100' },
  { value: 2, label: '101-200' },
  { value: 3, label: '201-300' },
  { value: 4, label: '301-500' },
  { value: 5, label: '501-1000' },
  { value: 6, label: '>1000' },
];

export const dataUserTypeOptions = [
  { value: EUserType.ADMIN, label: 'Admin', data: { tagType: ETagType.DANGER } },
  { value: EUserType.TEACHER, label: 'Giáo Viên', data: { tagType: ETagType.NORMAL } },
  { value: EUserType.MANAGER, label: 'Quản Lý', data: { tagType: ETagType.WARNING } },
  { value: EUserType.PLAYER, label: 'Học Viên', data: { tagType: ETagType.SUCCESS } },
  { value: EUserType.MANAGER_BRANCH, label: 'Quản Lý Chi Nhánh', data: { tagType: ETagType.WARNING } },
  { value: EUserType.ACCOUNTANT, label: 'Kế Toán', data: { tagType: ETagType.NORMAL } },
  { value: EUserType.SALE_MARKETING, label: 'Sale & Marketing', data: { tagType: ETagType.NORMAL } },
];

export const dataLevelOptions = [
  { value: ELevel.DIAMOND, label: 'Kim cương', data: { color: EIconColor.CERULEAN } },
  { value: ELevel.GOLD, label: 'Vàng', data: { color: EIconColor.SELECTIVE_YELLOW } },
  { value: ELevel.SILVER, label: 'Bạc', data: { color: EIconColor.SILVER } },
  { value: ELevel.FRIENDLY, label: 'Thân thiết', data: { color: EIconColor.APPLE } },
  { value: ELevel.COMMON, label: 'Tiềm năng', data: { color: EIconColor.PURPLE_HEART } },
];

export const dataPricingModelOptions = [
  { value: EPricingModelType.BASE_COURES, label: 'Phí Theo Buổi' },
  { value: EPricingModelType.MONTHLY, label: 'Phí Theo Thời Gian' },
];

export const dataPointActionOptions = [
  { value: EPointActionType.ATTENDANCES, label: 'Điểm Danh' },
  { value: EPointActionType.BIRTHDAY, label: 'Sinh Nhật' },
  { value: EPointActionType.BUY_PRODUCT, label: 'Mua Sản Phẩm' },
  { value: EPointActionType.REFERRALS, label: 'Mã Giới Thiệu' },
];

export const dataReportUnitTypeOptions = [
  { value: EReportUnitFilter.DATE, label: 'Hàng Ngày', data: { unit: 'report-by-day' } },
  { value: EReportUnitFilter.MONTH, label: 'Hàng Tháng', data: { unit: 'report-by-month' } },
  { value: EReportUnitFilter.YEAR, label: 'Hàng Năm', data: { unit: 'report-by-year' } },
];

export const dataTransportFeeTypeOptions = [
  { value: ETransportFeeType.FREE, label: 'Miễn Phí' },
  // { value: ETransportFeeType.TURN, label: 'Theo Lượt' },
  { value: ETransportFeeType.MONTH, label: 'Theo Tháng' },
];

export const dataRequestTimeOffStatusOptions = [
  { value: ERequestTimeOffStatus.PENDING, label: 'Chờ Duyệt', data: { statusType: EStatusStyleType.WARNING } },
  { value: ERequestTimeOffStatus.APPROVE, label: 'Đã Duyệt', data: { statusType: EStatusStyleType.SUCCESS } },
  { value: ERequestTimeOffStatus.REJECT, label: 'Không Duyệt', data: { statusType: EStatusStyleType.DANGER } },
];

export const dataNumberOfUnitsFilterOptions = [
  { value: '3', label: '3 buổi' },
  { value: '2', label: '2 buổi' },
  { value: '1', label: '1 buổi' },
  { value: '0', label: '0 buổi' },
  { value: '-1', label: 'Nợ buổi' },
];

export const dataVietQrBanks = {
  'code': '00',
  'desc': 'Get Bank list successful! Total 63 banks',
  'data': [
    {
      'id': 17,
      'name': 'Ngân hàng TMCP Công thương Việt Nam',
      'code': 'ICB',
      'bin': '970415',
      'shortName': 'VietinBank',
      'logo': 'https://api.vietqr.io/img/ICB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'VietinBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'ICBVVNVX',
    },
    {
      'id': 43,
      'name': 'Ngân hàng TMCP Ngoại Thương Việt Nam',
      'code': 'VCB',
      'bin': '970436',
      'shortName': 'Vietcombank',
      'logo': 'https://api.vietqr.io/img/VCB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'Vietcombank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'BFTVVNVX',
    },
    {
      'id': 4,
      'name': 'Ngân hàng TMCP Đầu tư và Phát triển Việt Nam',
      'code': 'BIDV',
      'bin': '970418',
      'shortName': 'BIDV',
      'logo': 'https://api.vietqr.io/img/BIDV.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'BIDV',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'BIDVVNVX',
    },
    {
      'id': 42,
      'name': 'Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam',
      'code': 'VBA',
      'bin': '970405',
      'shortName': 'Agribank',
      'logo': 'https://api.vietqr.io/img/VBA.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'Agribank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'VBAAVNVX',
    },
    {
      'id': 26,
      'name': 'Ngân hàng TMCP Phương Đông',
      'code': 'OCB',
      'bin': '970448',
      'shortName': 'OCB',
      'logo': 'https://api.vietqr.io/img/OCB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'OCB',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'ORCOVNVX',
    },
    {
      'id': 21,
      'name': 'Ngân hàng TMCP Quân đội',
      'code': 'MB',
      'bin': '970422',
      'shortName': 'MBBank',
      'logo': 'https://api.vietqr.io/img/MB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'MBBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'MSCBVNVX',
    },
    {
      'id': 38,
      'name': 'Ngân hàng TMCP Kỹ thương Việt Nam',
      'code': 'TCB',
      'bin': '970407',
      'shortName': 'Techcombank',
      'logo': 'https://api.vietqr.io/img/TCB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'Techcombank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'VTCBVNVX',
    },
    {
      'id': 2,
      'name': 'Ngân hàng TMCP Á Châu',
      'code': 'ACB',
      'bin': '970416',
      'shortName': 'ACB',
      'logo': 'https://api.vietqr.io/img/ACB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'ACB',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'ASCBVNVX',
    },
    {
      'id': 47,
      'name': 'Ngân hàng TMCP Việt Nam Thịnh Vượng',
      'code': 'VPB',
      'bin': '970432',
      'shortName': 'VPBank',
      'logo': 'https://api.vietqr.io/img/VPB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'VPBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'VPBKVNVX',
    },
    {
      'id': 39,
      'name': 'Ngân hàng TMCP Tiên Phong',
      'code': 'TPB',
      'bin': '970423',
      'shortName': 'TPBank',
      'logo': 'https://api.vietqr.io/img/TPB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'TPBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'TPBVVNVX',
    },
    {
      'id': 36,
      'name': 'Ngân hàng TMCP Sài Gòn Thương Tín',
      'code': 'STB',
      'bin': '970403',
      'shortName': 'Sacombank',
      'logo': 'https://api.vietqr.io/img/STB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'Sacombank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'SGTTVNVX',
    },
    {
      'id': 12,
      'name': 'Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh',
      'code': 'HDB',
      'bin': '970437',
      'shortName': 'HDBank',
      'logo': 'https://api.vietqr.io/img/HDB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'HDBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'HDBCVNVX',
    },
    {
      'id': 44,
      'name': 'Ngân hàng TMCP Bản Việt',
      'code': 'VCCB',
      'bin': '970454',
      'shortName': 'VietCapitalBank',
      'logo': 'https://api.vietqr.io/img/VCCB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'VietCapitalBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'VCBCVNVX',
    },
    {
      'id': 31,
      'name': 'Ngân hàng TMCP Sài Gòn',
      'code': 'SCB',
      'bin': '970429',
      'shortName': 'SCB',
      'logo': 'https://api.vietqr.io/img/SCB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'SCB',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'SACLVNVX',
    },
    {
      'id': 45,
      'name': 'Ngân hàng TMCP Quốc tế Việt Nam',
      'code': 'VIB',
      'bin': '970441',
      'shortName': 'VIB',
      'logo': 'https://api.vietqr.io/img/VIB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'VIB',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'VNIBVNVX',
    },
    {
      'id': 35,
      'name': 'Ngân hàng TMCP Sài Gòn - Hà Nội',
      'code': 'SHB',
      'bin': '970443',
      'shortName': 'SHB',
      'logo': 'https://api.vietqr.io/img/SHB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'SHB',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'SHBAVNVX',
    },
    {
      'id': 10,
      'name': 'Ngân hàng TMCP Xuất Nhập khẩu Việt Nam',
      'code': 'EIB',
      'bin': '970431',
      'shortName': 'Eximbank',
      'logo': 'https://api.vietqr.io/img/EIB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'Eximbank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'EBVIVNVX',
    },
    {
      'id': 22,
      'name': 'Ngân hàng TMCP Hàng Hải',
      'code': 'MSB',
      'bin': '970426',
      'shortName': 'MSB',
      'logo': 'https://api.vietqr.io/img/MSB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'MSB',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'MCOBVNVX',
    },
    {
      'id': 53,
      'name': 'TMCP Việt Nam Thịnh Vượng - Ngân hàng số CAKE by VPBank',
      'code': 'CAKE',
      'bin': '546034',
      'shortName': 'CAKE',
      'logo': 'https://api.vietqr.io/img/CAKE.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'CAKE',
      'support': 3,
      'isTransfer': 1,
      'swift_code': null,
    },
    {
      'id': 54,
      'name': 'TMCP Việt Nam Thịnh Vượng - Ngân hàng số Ubank by VPBank',
      'code': 'Ubank',
      'bin': '546035',
      'shortName': 'Ubank',
      'logo': 'https://api.vietqr.io/img/UBANK.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'Ubank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': null,
    },
    {
      'id': 58,
      'name': 'Ngân hàng số Timo by Ban Viet Bank (Timo by Ban Viet Bank)',
      'code': 'TIMO',
      'bin': '963388',
      'shortName': 'Timo',
      'logo': 'https://vietqr.net/portal-service/resources/icons/TIMO.png',
      'transferSupported': 1,
      'lookupSupported': 0,
      'short_name': 'Timo',
      'support': 0,
      'isTransfer': 1,
      'swift_code': null,
    },
    {
      'id': 57,
      'name': 'Tổng Công ty Dịch vụ số Viettel - Chi nhánh tập đoàn công nghiệp viễn thông Quân Đội',
      'code': 'VTLMONEY',
      'bin': '971005',
      'shortName': 'ViettelMoney',
      'logo': 'https://api.vietqr.io/img/VIETTELMONEY.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'ViettelMoney',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 56,
      'name': 'VNPT Money',
      'code': 'VNPTMONEY',
      'bin': '971011',
      'shortName': 'VNPTMoney',
      'logo': 'https://api.vietqr.io/img/VNPTMONEY.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'VNPTMoney',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 34,
      'name': 'Ngân hàng TMCP Sài Gòn Công Thương',
      'code': 'SGICB',
      'bin': '970400',
      'shortName': 'SaigonBank',
      'logo': 'https://api.vietqr.io/img/SGICB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'SaigonBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'SBITVNVX',
    },
    {
      'id': 3,
      'name': 'Ngân hàng TMCP Bắc Á',
      'code': 'BAB',
      'bin': '970409',
      'shortName': 'BacABank',
      'logo': 'https://api.vietqr.io/img/BAB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'BacABank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'NASCVNVX',
    },
    {
      'id': 30,
      'name': 'Ngân hàng TMCP Đại Chúng Việt Nam',
      'code': 'PVCB',
      'bin': '970412',
      'shortName': 'PVcomBank',
      'logo': 'https://api.vietqr.io/img/PVCB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'PVcomBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'WBVNVNVX',
    },
    {
      'id': 27,
      'name': 'Ngân hàng Thương mại TNHH MTV Đại Dương',
      'code': 'Oceanbank',
      'bin': '970414',
      'shortName': 'Oceanbank',
      'logo': 'https://api.vietqr.io/img/OCEANBANK.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'Oceanbank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'OCBKUS3M',
    },
    {
      'id': 24,
      'name': 'Ngân hàng TMCP Quốc Dân',
      'code': 'NCB',
      'bin': '970419',
      'shortName': 'NCB',
      'logo': 'https://api.vietqr.io/img/NCB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'NCB',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'NVBAVNVX',
    },
    {
      'id': 37,
      'name': 'Ngân hàng TNHH MTV Shinhan Việt Nam',
      'code': 'SHBVN',
      'bin': '970424',
      'shortName': 'ShinhanBank',
      'logo': 'https://api.vietqr.io/img/SHBVN.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'ShinhanBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'SHBKVNVX',
    },
    {
      'id': 1,
      'name': 'Ngân hàng TMCP An Bình',
      'code': 'ABB',
      'bin': '970425',
      'shortName': 'ABBANK',
      'logo': 'https://api.vietqr.io/img/ABB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'ABBANK',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'ABBKVNVX',
    },
    {
      'id': 41,
      'name': 'Ngân hàng TMCP Việt Á',
      'code': 'VAB',
      'bin': '970427',
      'shortName': 'VietABank',
      'logo': 'https://api.vietqr.io/img/VAB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'VietABank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'VNACVNVX',
    },
    {
      'id': 23,
      'name': 'Ngân hàng TMCP Nam Á',
      'code': 'NAB',
      'bin': '970428',
      'shortName': 'NamABank',
      'logo': 'https://api.vietqr.io/img/NAB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'NamABank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'NAMAVNVX',
    },
    {
      'id': 29,
      'name': 'Ngân hàng TMCP Xăng dầu Petrolimex',
      'code': 'PGB',
      'bin': '970430',
      'shortName': 'PGBank',
      'logo': 'https://api.vietqr.io/img/PGB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'PGBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'PGBLVNVX',
    },
    {
      'id': 46,
      'name': 'Ngân hàng TMCP Việt Nam Thương Tín',
      'code': 'VIETBANK',
      'bin': '970433',
      'shortName': 'VietBank',
      'logo': 'https://api.vietqr.io/img/VIETBANK.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'VietBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'VNTTVNVX',
    },
    {
      'id': 5,
      'name': 'Ngân hàng TMCP Bảo Việt',
      'code': 'BVB',
      'bin': '970438',
      'shortName': 'BaoVietBank',
      'logo': 'https://api.vietqr.io/img/BVB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'BaoVietBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'BVBVVNVX',
    },
    {
      'id': 33,
      'name': 'Ngân hàng TMCP Đông Nam Á',
      'code': 'SEAB',
      'bin': '970440',
      'shortName': 'SeABank',
      'logo': 'https://api.vietqr.io/img/SEAB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'SeABank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'SEAVVNVX',
    },
    {
      'id': 52,
      'name': 'Ngân hàng Hợp tác xã Việt Nam',
      'code': 'COOPBANK',
      'bin': '970446',
      'shortName': 'COOPBANK',
      'logo': 'https://api.vietqr.io/img/COOPBANK.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'COOPBANK',
      'support': 3,
      'isTransfer': 1,
      'swift_code': null,
    },
    {
      'id': 20,
      'name': 'Ngân hàng TMCP Bưu Điện Liên Việt',
      'code': 'LPB',
      'bin': '970449',
      'shortName': 'LienVietPostBank',
      'logo': 'https://api.vietqr.io/img/LPB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'LienVietPostBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'LVBKVNVX',
    },
    {
      'id': 19,
      'name': 'Ngân hàng TMCP Kiên Long',
      'code': 'KLB',
      'bin': '970452',
      'shortName': 'KienLongBank',
      'logo': 'https://api.vietqr.io/img/KLB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'KienLongBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'KLBKVNVX',
    },
    {
      'id': 55,
      'name': 'Ngân hàng Đại chúng TNHH Kasikornbank',
      'code': 'KBank',
      'bin': '668888',
      'shortName': 'KBank',
      'logo': 'https://api.vietqr.io/img/KBANK.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'KBank',
      'support': 3,
      'isTransfer': 1,
      'swift_code': 'KASIVNVX',
    },
    {
      'id': 40,
      'name': 'Ngân hàng United Overseas - Chi nhánh TP. Hồ Chí Minh',
      'code': 'UOB',
      'bin': '970458',
      'shortName': 'UnitedOverseas',
      'logo': 'https://api.vietqr.io/img/UOB.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'UnitedOverseas',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 32,
      'name': 'Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam',
      'code': 'SCVN',
      'bin': '970410',
      'shortName': 'StandardChartered',
      'logo': 'https://api.vietqr.io/img/SCVN.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'StandardChartered',
      'support': 0,
      'isTransfer': 0,
      'swift_code': 'SCBLVNVX',
    },
    {
      'id': 28,
      'name': 'Ngân hàng TNHH MTV Public Việt Nam',
      'code': 'PBVN',
      'bin': '970439',
      'shortName': 'PublicBank',
      'logo': 'https://api.vietqr.io/img/PBVN.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'PublicBank',
      'support': 0,
      'isTransfer': 0,
      'swift_code': 'VIDPVNVX',
    },
    {
      'id': 25,
      'name': 'Ngân hàng Nonghyup - Chi nhánh Hà Nội',
      'code': 'NHB HN',
      'bin': '801011',
      'shortName': 'Nonghyup',
      'logo': 'https://api.vietqr.io/img/NHB.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'Nonghyup',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 18,
      'name': 'Ngân hàng TNHH Indovina',
      'code': 'IVB',
      'bin': '970434',
      'shortName': 'IndovinaBank',
      'logo': 'https://api.vietqr.io/img/IVB.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'IndovinaBank',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 16,
      'name': 'Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh TP. Hồ Chí Minh',
      'code': 'IBK - HCM',
      'bin': '970456',
      'shortName': 'IBKHCM',
      'logo': 'https://api.vietqr.io/img/IBK.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'IBKHCM',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 15,
      'name': 'Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh Hà Nội',
      'code': 'IBK - HN',
      'bin': '970455',
      'shortName': 'IBKHN',
      'logo': 'https://api.vietqr.io/img/IBK.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'IBKHN',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 48,
      'name': 'Ngân hàng Liên doanh Việt - Nga',
      'code': 'VRB',
      'bin': '970421',
      'shortName': 'VRB',
      'logo': 'https://api.vietqr.io/img/VRB.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'VRB',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 49,
      'name': 'Ngân hàng TNHH MTV Woori Việt Nam',
      'code': 'WVN',
      'bin': '970457',
      'shortName': 'Woori',
      'logo': 'https://api.vietqr.io/img/WVN.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'Woori',
      'support': 0,
      'isTransfer': 1,
      'swift_code': null,
    },
    {
      'id': 50,
      'name': 'Ngân hàng Kookmin - Chi nhánh Hà Nội',
      'code': 'KBHN',
      'bin': '970462',
      'shortName': 'KookminHN',
      'logo': 'https://api.vietqr.io/img/KBHN.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'KookminHN',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 51,
      'name': 'Ngân hàng Kookmin - Chi nhánh Thành phố Hồ Chí Minh',
      'code': 'KBHCM',
      'bin': '970463',
      'shortName': 'KookminHCM',
      'logo': 'https://api.vietqr.io/img/KBHCM.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'KookminHCM',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 14,
      'name': 'Ngân hàng TNHH MTV HSBC (Việt Nam)',
      'code': 'HSBC',
      'bin': '458761',
      'shortName': 'HSBC',
      'logo': 'https://api.vietqr.io/img/HSBC.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'HSBC',
      'support': 0,
      'isTransfer': 0,
      'swift_code': 'HSBCVNVX',
    },
    {
      'id': 13,
      'name': 'Ngân hàng TNHH MTV Hong Leong Việt Nam',
      'code': 'HLBVN',
      'bin': '970442',
      'shortName': 'HongLeong',
      'logo': 'https://api.vietqr.io/img/HLBVN.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'HongLeong',
      'support': 0,
      'isTransfer': 0,
      'swift_code': 'HLBBVNVX',
    },
    {
      'id': 11,
      'name': 'Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu',
      'code': 'GPB',
      'bin': '970408',
      'shortName': 'GPBank',
      'logo': 'https://api.vietqr.io/img/GPB.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'GPBank',
      'support': 0,
      'isTransfer': 0,
      'swift_code': 'GBNKVNVX',
    },
    {
      'id': 9,
      'name': 'Ngân hàng TMCP Đông Á',
      'code': 'DOB',
      'bin': '970406',
      'shortName': 'DongABank',
      'logo': 'https://api.vietqr.io/img/DOB.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'DongABank',
      'support': 0,
      'isTransfer': 0,
      'swift_code': 'EACBVNVX',
    },
    {
      'id': 8,
      'name': 'DBS Bank Ltd - Chi nhánh Thành phố Hồ Chí Minh',
      'code': 'DBS',
      'bin': '796500',
      'shortName': 'DBSBank',
      'logo': 'https://api.vietqr.io/img/DBS.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'DBSBank',
      'support': 0,
      'isTransfer': 0,
      'swift_code': 'DBSSVNVX',
    },
    {
      'id': 7,
      'name': 'Ngân hàng TNHH MTV CIMB Việt Nam',
      'code': 'CIMB',
      'bin': '422589',
      'shortName': 'CIMB',
      'logo': 'https://api.vietqr.io/img/CIMB.png',
      'transferSupported': 1,
      'lookupSupported': 1,
      'short_name': 'CIMB',
      'support': 0,
      'isTransfer': 1,
      'swift_code': 'CIBBVNVN',
    },
    {
      'id': 6,
      'name': 'Ngân hàng Thương mại TNHH MTV Xây dựng Việt Nam',
      'code': 'CBB',
      'bin': '970444',
      'shortName': 'CBBank',
      'logo': 'https://api.vietqr.io/img/CBB.png',
      'transferSupported': 0,
      'lookupSupported': 1,
      'short_name': 'CBBank',
      'support': 0,
      'isTransfer': 0,
      'swift_code': 'GTBAVNVX',
    },
    {
      'id': 59,
      'name': 'Ngân hàng Citibank, N.A. - Chi nhánh Hà Nội',
      'code': 'CITIBANK',
      'bin': '533948',
      'shortName': 'Citibank',
      'logo': 'https://api.vietqr.io/img/CITIBANK.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'Citibank',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 60,
      'name': 'Ngân hàng KEB Hana – Chi nhánh Thành phố Hồ Chí Minh',
      'code': 'KEBHANAHCM',
      'bin': '970466',
      'shortName': 'KEBHanaHCM',
      'logo': 'https://api.vietqr.io/img/KEBHANAHCM.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'KEBHanaHCM',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 61,
      'name': 'Ngân hàng KEB Hana – Chi nhánh Hà Nội',
      'code': 'KEBHANAHN',
      'bin': '970467',
      'shortName': 'KEBHANAHN',
      'logo': 'https://api.vietqr.io/img/KEBHANAHN.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'KEBHANAHN',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 62,
      'name': 'Công ty Tài chính TNHH MTV Mirae Asset (Việt Nam) ',
      'code': 'MAFC',
      'bin': '977777',
      'shortName': 'MAFC',
      'logo': 'https://api.vietqr.io/img/MAFC.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'MAFC',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
    {
      'id': 63,
      'name': 'Ngân hàng Chính sách Xã hội',
      'code': 'VBSP',
      'bin': '999888',
      'shortName': 'VBSP',
      'logo': 'https://api.vietqr.io/img/VBSP.png',
      'transferSupported': 0,
      'lookupSupported': 0,
      'short_name': 'VBSP',
      'support': 0,
      'isTransfer': 0,
      'swift_code': null,
    },
  ],
};

export const dataAcademyStatusOptions = [
  {
    value: EAcademyStatus.ACTIVE,
    label: 'Hoạt Động',
    data: { statusType: EStatusStyleType.SUCCESS, color: EIconColor.APPLE },
  },
  {
    value: EAcademyStatus.PENDING,
    label: 'Đang Chờ Duyệt',
    data: { statusType: EStatusStyleType.WARNING, color: EIconColor.AMBER },
  },
  {
    value: EAcademyStatus.INACTIVE,
    label: 'Ngừng Hoạt Động',
    data: { statusType: EStatusStyleType.DANGER, color: EIconColor.POMEGRANATE },
  },
];

export const dataUnitTypeCouponOptions = [
  { value: EUnitTypeCoupon.VND, label: 'VNĐ' },
  { value: EUnitTypeCoupon.PERCENT, label: '%' },
];

export const dataPaymentStatusOptions = [
  { value: EPaymentStatus.NEW, label: 'Mới', data: { statusType: EStatusStyleType.NORMAL } },
  { value: EPaymentStatus.SUCCESS, label: 'Thành Công', data: { statusType: EStatusStyleType.SUCCESS } },
  { value: EPaymentStatus.FAIL, label: 'Thất Bại', data: { statusType: EStatusStyleType.DANGER } },
  { value: EPaymentStatus.PENDING, label: 'Đang Xử Lý', data: { statusType: EStatusStyleType.WARNING } },
  { value: EPaymentStatus.CANCEL, label: 'Huỷ Bỏ', data: { statusType: EStatusStyleType.CANCEL } },
];

export const MAX_FILE_IMAGE_SIZE = 5000000;

export const dataInvoiceStatusOptions = [
  {
    value: EInvoiceStatus.NEW,
    label: 'Chưa Thanh Toán',
    data: { color: EIconColor.AMBER, statusType: EStatusStyleType.WARNING },
  },
  {
    value: EInvoiceStatus.PAID,
    label: 'Đã Thanh Toán',
    data: { color: EIconColor.APPLE, statusType: EStatusStyleType.SUCCESS },
  },
  {
    value: EInvoiceStatus.OVER_DUE,
    label: 'Quá Hạn',
    data: { color: EIconColor.POMEGRANATE, statusType: EStatusStyleType.DANGER },
  },
];

export const dataServiceCostingTypeOptions = [
  {
    value: JSON.stringify([EServiceCostingType.INSTALLATION_HUB_FEE]),
    label: 'Mindstar Hub',
    data: {
      isAdmin: false,
      icon: EIconName.DeviceDesktop,
      description:
        'Chỉ sử dụng hệ thống quản lý qua phần mềm website trên các thiết bị máy tính, máy tính bảng và điện thoại.',
    },
  },
  {
    value: JSON.stringify([EServiceCostingType.INSTALLATION_HUB_FEE, EServiceCostingType.INSTALLATION_MOBILE_FEE]),
    label: 'Mindstar Hub + Mobile App',
    data: {
      isAdmin: false,
      icon: EIconName.Devices,
      description:
        'Kết hợp với hệ thống mobile app giúp kết nối với người dùng, tăng hiệu quả quản lý, tiện ích và bán hàng.',
    },
  },
  {
    value: JSON.stringify([EServiceCostingType.MAINTENANCE_FEE]),
    label: 'Phí Thường Niên Hàng Tháng',
    data: { isAdmin: true },
  },
  {
    value: JSON.stringify([EServiceCostingType.MAINTENANCE_FEE_YEAR]),
    label: 'Phí Thường Niên Hàng Năm',
    data: { isAdmin: true },
  },
  {
    value: JSON.stringify([EServiceCostingType.OUTSIZE_FEE]),
    label: 'Phí Phát Sinh',
    data: { isAdmin: true },
  },
];

export const dataInvoiceServiceCostingTypeOptions = [
  {
    value: JSON.stringify([EServiceCostingType.INSTALLATION_HUB_FEE]),
    label: 'Mindstar Hub',
    data: {
      isAdmin: false,
      icon: EIconName.DeviceDesktop,
      description:
        'Chỉ sử dụng hệ thống quản lý qua phần mềm website trên các thiết bị máy tính, máy tính bảng và điện thoại.',
    },
  },
  {
    value: JSON.stringify([EServiceCostingType.INSTALLATION_MOBILE_FEE]),
    label: 'Mobile App',
    data: {
      isAdmin: false,
      icon: EIconName.Devices,
      description:
        'Kết hợp với hệ thống mobile app giúp kết nối với người dùng, tăng hiệu quả quản lý, tiện ích và bán hàng.',
    },
  },
  {
    value: JSON.stringify([EServiceCostingType.MAINTENANCE_FEE]),
    label: 'Phí Thường Niên Hàng Tháng',
    data: { isAdmin: true },
  },
  {
    value: JSON.stringify([EServiceCostingType.MAINTENANCE_FEE_YEAR]),
    label: 'Phí Thường Niên Hàng Năm',
    data: { isAdmin: true },
  },
  {
    value: JSON.stringify([EServiceCostingType.OUTSIZE_FEE]),
    label: 'Phí Phát Sinh',
    data: { isAdmin: true },
  },
];

export const dataPaymentPerTypeOption = [
  // {
  //   value: `1-${EUnitDurationType.MONTH}`,
  //   label: '1 tháng',
  //   data: { duration_unit_type: EUnitDurationType.MONTH, academy_duration: 1 },
  // },
  // {
  //   value: `3-${EUnitDurationType.MONTH}`,
  //   label: '3 tháng',
  //   data: { duration_unit_type: EUnitDurationType.MONTH, academy_duration: 3 },
  // },
  {
    value: `6-${EUnitDurationType.MONTH}`,
    label: '6 tháng',
    data: { duration_unit_type: EUnitDurationType.MONTH, academy_duration: 6 },
  },
  {
    value: `1-${EUnitDurationType.YEAR}`,
    label: '1 năm',
    data: { duration_unit_type: EUnitDurationType.YEAR, academy_duration: 1 },
  },
  {
    value: `2-${EUnitDurationType.YEAR}`,
    label: '2 năm',
    data: { duration_unit_type: EUnitDurationType.YEAR, academy_duration: 2 },
  },
  {
    value: `3-${EUnitDurationType.YEAR}`,
    label: '3 năm',
    data: { duration_unit_type: EUnitDurationType.YEAR, academy_duration: 3 },
  },
];

export const dataFilterPaymentPerTypeOption = [
  {
    value: EUnitDurationType.MONTH,
    label: 'Theo Tháng',
    data: { duration_unit_type: EUnitDurationType.MONTH, academy_duration: 1 },
  },
  {
    value: EUnitDurationType.YEAR,
    label: 'Theo Năm',
    data: { duration_unit_type: EUnitDurationType.YEAR, academy_duration: 1 },
  },
];

export const dataUnitDurationTypeOption = [
  { value: EUnitDurationType.YEAR, label: 'Năm' },
  { value: EUnitDurationType.MONTH, label: 'Tháng' },
  { value: EUnitDurationType.DAY, label: 'Ngày' },
  { value: EUnitDurationType.HOUR, label: 'Giờ' },
  { value: EUnitDurationType.SECOND, label: 'Giây' },
];

export const dataGatewayModeOptions = [
  { value: EGatewayMode.SANDBOX, label: 'Sandbox' },
  { value: EGatewayMode.LIVE, label: 'Live' },
];

export const serviceCostingType = {
  [EServiceCostingType.INSTALLATION_HUB_FEE]: 'MindStar Hub',
  [EServiceCostingType.INSTALLATION_MOBILE_FEE]: 'Mobile App',
};

export const dataBaseAppType = [
  {
    value: EServiceCostingType.INSTALLATION_HUB_FEE,
    label: serviceCostingType[EServiceCostingType.INSTALLATION_HUB_FEE],
  },
  {
    value: EServiceCostingType.INSTALLATION_MOBILE_FEE,
    label: serviceCostingType[EServiceCostingType.INSTALLATION_MOBILE_FEE],
  },
];

export const dataCouponPolicyOptions = [
  { value: ECouponPolicyType.SINGLE, label: 'Một lần' },
  { value: ECouponPolicyType.MULTIPLE, label: 'Không giới hạn số lần' },
];

export const dataCourseTimeUnitTypeOptions = [
  { value: ECourseTimeUnitType.YEAR, label: 'Năm' },
  { value: ECourseTimeUnitType.QUARTER, label: 'Quý' },
  { value: ECourseTimeUnitType.MONTH, label: 'Tháng' },
  { value: ECourseTimeUnitType.DAY, label: 'Ngày' },
];
