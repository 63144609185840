import { createActionCreator } from 'deox';

import { TGetAdvisoriesMaterials, TGetAdvisoriesResponse } from '@/services/api/advisory/get-advisories';

// CONSTANTS

export enum EGetAdvisoriesAction {
  GET_ADVISORIES = 'GET_ADVISORIES',
  GET_ADVISORIES_REQUEST = 'GET_ADVISORIES_REQUEST',
  GET_ADVISORIES_SUCCESS = 'GET_ADVISORIES_SUCCESS',
  GET_ADVISORIES_FAILED = 'GET_ADVISORIES_FAILED',
}

// TYPES

export type TGetAdvisoriesRequest = {
  type: EGetAdvisoriesAction.GET_ADVISORIES_REQUEST;
  payload: {
    materials: TGetAdvisoriesMaterials;
    successCallback?: (response: TGetAdvisoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAdvisoriesSuccess = {
  type: EGetAdvisoriesAction.GET_ADVISORIES_SUCCESS;
  payload: { response: TGetAdvisoriesResponse };
};

export type TGetAdvisoriesFailed = { type: EGetAdvisoriesAction.GET_ADVISORIES_FAILED };

// FUNCTION

export const getAdvisoriesAction = {
  request: createActionCreator(
    EGetAdvisoriesAction.GET_ADVISORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetAdvisoriesMaterials,
        successCallback?: (response: TGetAdvisoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAdvisoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAdvisoriesAction.GET_ADVISORIES_SUCCESS,
    (resolve) =>
      (response: TGetAdvisoriesResponse): TGetAdvisoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAdvisoriesAction.GET_ADVISORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetAdvisoriesFailed =>
        resolve({ error }),
  ),
};
