import { TInvoice } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetInvoicePaths = {
  id: string | number;
};
export type TGetInvoiceParams = unknown;

export type TGetInvoiceMaterials = {
  paths?: TGetInvoicePaths;
  params?: TGetInvoiceParams;
};

export type TGetInvoiceResponse = TCommonResponse & {
  content: TInvoice;
};

// FUNCTION

export const getInvoice = async ({ paths, params }: TGetInvoiceMaterials): Promise<TGetInvoiceResponse> => {
  const response = await ApiService.get(`/api-admin/invoices/${paths?.id}`, { params });
  return response?.data;
};
