import { createActionCreator } from 'deox';

import { TGetAcademyMaterials, TGetAcademyResponse } from '@/services/api/academy/get-academy';

// CONSTANTS

export enum EGetAcademyAction {
  GET_ACADEMY = 'GET_ACADEMY',
  GET_ACADEMY_REQUEST = 'GET_ACADEMY_REQUEST',
  GET_ACADEMY_SUCCESS = 'GET_ACADEMY_SUCCESS',
  GET_ACADEMY_FAILED = 'GET_ACADEMY_FAILED',
}

// TYPES

export type TGetAcademyRequest = {
  type: EGetAcademyAction.GET_ACADEMY_REQUEST;
  payload: {
    materials: TGetAcademyMaterials;
    successCallback?: (response: TGetAcademyResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAcademySuccess = {
  type: EGetAcademyAction.GET_ACADEMY_SUCCESS;
  payload: { response: TGetAcademyResponse };
};

export type TGetAcademyFailed = { type: EGetAcademyAction.GET_ACADEMY_FAILED };

// FUNCTION

export const getAcademyAction = {
  request: createActionCreator(
    EGetAcademyAction.GET_ACADEMY_REQUEST,
    (resolve) =>
      (
        materials: TGetAcademyMaterials,
        successCallback?: (response: TGetAcademyResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAcademyRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAcademyAction.GET_ACADEMY_SUCCESS,
    (resolve) =>
      (response: TGetAcademyResponse): TGetAcademySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAcademyAction.GET_ACADEMY_FAILED,
    (resolve) =>
      (error: unknown): TGetAcademyFailed =>
        resolve({ error }),
  ),
};
