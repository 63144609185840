import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getAdvisoriesAction } from '@/redux/actions';
import { getAdvisories, TGetAdvisoriesResponse } from '@/services/api';

// FUNCTION

export function* getAdvisoriesSaga(action: ActionType<typeof getAdvisoriesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getAdvisories, materials);
    const getAdvisoriesResponse: TGetAdvisoriesResponse = response as TGetAdvisoriesResponse;
    yield put(getAdvisoriesAction.success(getAdvisoriesResponse));
    successCallback?.(getAdvisoriesResponse);
  } catch (err) {
    yield put(getAdvisoriesAction.failure(err));
    failedCallback?.(err);
  }
}
