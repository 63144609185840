import { TAcademy } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetAcademyByDomainPaths = {
  domain: string | number;
};
export type TGetAcademyByDomainParams = unknown;

export type TGetAcademyByDomainMaterials = {
  paths?: TGetAcademyByDomainPaths;
  params?: TGetAcademyByDomainParams;
};

export type TGetAcademyByDomainResponse = TCommonResponse & {
  content: TAcademy;
};

// FUNCTION

export const getAcademyByDomain = async ({
  paths,
  params,
}: TGetAcademyByDomainMaterials): Promise<TGetAcademyByDomainResponse> => {
  const response = await ApiService.get(`/api-public/academies/${paths?.domain}`, { params });
  return response?.data;
};
