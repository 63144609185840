import { createActionCreator } from 'deox';

import { TGetPackagesMaterials, TGetPackagesResponse } from '@/services/api/package/get-packages';

// CONSTANTS

export enum EGetPackagesAction {
  GET_PACKAGES = 'GET_PACKAGES',
  GET_PACKAGES_REQUEST = 'GET_PACKAGES_REQUEST',
  GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS',
  GET_PACKAGES_FAILED = 'GET_PACKAGES_FAILED',
}

// TYPES

export type TGetPackagesRequest = {
  type: EGetPackagesAction.GET_PACKAGES_REQUEST;
  payload: {
    materials: TGetPackagesMaterials;
    successCallback?: (response: TGetPackagesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPackagesSuccess = {
  type: EGetPackagesAction.GET_PACKAGES_SUCCESS;
  payload: { response: TGetPackagesResponse };
};

export type TGetPackagesFailed = { type: EGetPackagesAction.GET_PACKAGES_FAILED };

// FUNCTION

export const getPackagesAction = {
  request: createActionCreator(
    EGetPackagesAction.GET_PACKAGES_REQUEST,
    (resolve) =>
      (
        materials: TGetPackagesMaterials,
        successCallback?: (response: TGetPackagesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPackagesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPackagesAction.GET_PACKAGES_SUCCESS,
    (resolve) =>
      (response: TGetPackagesResponse): TGetPackagesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPackagesAction.GET_PACKAGES_FAILED,
    (resolve) =>
      (error: unknown): TGetPackagesFailed =>
        resolve({ error }),
  ),
};
