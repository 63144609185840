import { all, takeLatest } from 'redux-saga/effects';

import {
  createCouponAction,
  deleteCouponAction,
  getCouponsAction,
  getPublicCouponAction,
  getPublicCouponsAction,
  updateCouponAction,
} from '@/redux/actions';

import { createCouponSaga } from './create-coupon';
import { deleteCouponSaga } from './delete-coupon';
import { getCouponsSaga } from './get-coupons';
import { getPublicCouponSaga } from './get-public-coupon';
import { getPublicCouponsSaga } from './get-public-coupons';
import { updateCouponSaga } from './update-coupon';

export default function* root(): Generator {
  yield all([
    takeLatest(createCouponAction.request.type, createCouponSaga),
    takeLatest(deleteCouponAction.request.type, deleteCouponSaga),
    takeLatest(getCouponsAction.request.type, getCouponsSaga),
    takeLatest(getPublicCouponAction.request.type, getPublicCouponSaga),
    takeLatest(getPublicCouponsAction.request.type, getPublicCouponsSaga),
    takeLatest(updateCouponAction.request.type, updateCouponSaga),
  ]);
}
