import { TInvoice } from '@/common/models';
import { TCommonPaginate, TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetInvoicesParams = {
  page: number;
  size: number;
  sort?: string;
  search?: string;
};

export type TGetInvoicesMaterials = {
  params?: TGetInvoicesParams;
};

export type TGetInvoicesResponse = TCommonResponse & {
  content: TCommonPaginate & {
    content: TInvoice[];
  };
};

// FUNCTION

export const getInvoices = async ({ params }: TGetInvoicesMaterials): Promise<TGetInvoicesResponse> => {
  const response = await ApiService.get(`/api-admin/invoices`, { params });
  return response?.data;
};
