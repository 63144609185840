import { all, takeLatest } from 'redux-saga/effects';

import {
  createPackageAction,
  deletePackageAction,
  getPackagesAction,
  getPublicPackagesAction,
  updatePackageAction,
} from '@/redux/actions';

import { createPackageSaga } from './create-package';
import { deletePackageSaga } from './delete-package';
import { getPackagesSaga } from './get-packages';
import { getPublicPackagesSaga } from './get-public-packages';
import { updatePackageSaga } from './update-package';

export default function* root(): Generator {
  yield all([
    takeLatest(createPackageAction.request.type, createPackageSaga),
    takeLatest(deletePackageAction.request.type, deletePackageSaga),
    takeLatest(getPackagesAction.request.type, getPackagesSaga),
    takeLatest(getPublicPackagesAction.request.type, getPublicPackagesSaga),
    takeLatest(updatePackageAction.request.type, updatePackageSaga),
  ]);
}
