import { all, takeLatest } from 'redux-saga/effects';

import {
  createPaymentByUserAction,
  createPaymentAction,
  deletePaymentAction,
  getPaymentsAction,
  getPublicPaymentsAction,
  updatePaymentAction,
} from '@/redux/actions';

import { createPaymentByUserSaga } from './create-payment-by-user';
import { createPaymentSaga } from './create-payment';
import { deletePaymentSaga } from './delete-payment';
import { getPaymentsSaga } from './get-payments';
import { getPublicPaymentsSaga } from './get-public-payments';
import { updatePaymentSaga } from './update-payment';

export default function* root(): Generator {
  yield all([
    takeLatest(createPaymentByUserAction.request.type, createPaymentByUserSaga),
    takeLatest(createPaymentAction.request.type, createPaymentSaga),
    takeLatest(deletePaymentAction.request.type, deletePaymentSaga),
    takeLatest(getPaymentsAction.request.type, getPaymentsSaga),
    takeLatest(getPublicPaymentsAction.request.type, getPublicPaymentsSaga),
    takeLatest(updatePaymentAction.request.type, updatePaymentSaga),
  ]);
}
