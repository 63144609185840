import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getPackagesAction } from '@/redux/actions';
import { getPackages, TGetPackagesResponse } from '@/services/api';

// FUNCTION

export function* getPackagesSaga(action: ActionType<typeof getPackagesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getPackages, materials);
    const getPackagesResponse: TGetPackagesResponse = response as TGetPackagesResponse;
    yield put(getPackagesAction.success(getPackagesResponse));
    successCallback?.(getPackagesResponse);
  } catch (err) {
    yield put(getPackagesAction.failure(err));
    failedCallback?.(err);
  }
}
