import { createActionCreator } from 'deox';

import {
  TGetAcademyByDomainMaterials,
  TGetAcademyByDomainResponse,
} from '@/services/api/academy/get-academy-by-domain';

// CONSTANTS

export enum EGetAcademyByDomainAction {
  GET_ACADEMY_BY_DOMAIN = 'GET_ACADEMY_BY_DOMAIN',
  GET_ACADEMY_BY_DOMAIN_REQUEST = 'GET_ACADEMY_BY_DOMAIN_REQUEST',
  GET_ACADEMY_BY_DOMAIN_SUCCESS = 'GET_ACADEMY_BY_DOMAIN_SUCCESS',
  GET_ACADEMY_BY_DOMAIN_FAILED = 'GET_ACADEMY_BY_DOMAIN_FAILED',
}

// TYPES

export type TGetAcademyByDomainRequest = {
  type: EGetAcademyByDomainAction.GET_ACADEMY_BY_DOMAIN_REQUEST;
  payload: {
    materials: TGetAcademyByDomainMaterials;
    successCallback?: (response: TGetAcademyByDomainResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAcademyByDomainSuccess = {
  type: EGetAcademyByDomainAction.GET_ACADEMY_BY_DOMAIN_SUCCESS;
  payload: { response: TGetAcademyByDomainResponse };
};

export type TGetAcademyByDomainFailed = { type: EGetAcademyByDomainAction.GET_ACADEMY_BY_DOMAIN_FAILED };

// FUNCTION

export const getAcademyByDomainAction = {
  request: createActionCreator(
    EGetAcademyByDomainAction.GET_ACADEMY_BY_DOMAIN_REQUEST,
    (resolve) =>
      (
        materials: TGetAcademyByDomainMaterials,
        successCallback?: (response: TGetAcademyByDomainResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAcademyByDomainRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAcademyByDomainAction.GET_ACADEMY_BY_DOMAIN_SUCCESS,
    (resolve) =>
      (response: TGetAcademyByDomainResponse): TGetAcademyByDomainSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAcademyByDomainAction.GET_ACADEMY_BY_DOMAIN_FAILED,
    (resolve) =>
      (error: unknown): TGetAcademyByDomainFailed =>
        resolve({ error }),
  ),
};
