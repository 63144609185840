import { TPayment, TPaymentValueModels } from '@/common/models';
import { TCommonPaginate, TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetAcademyPaymentsPaths = {
  id: string | number;
};
export type TGetAcademyPaymentsParams = {
  page: number;
  size: number;
  sort?: string;
  fromDate?: number;
  toDate?: number;
  paymentStatuses?: string;
};

export type TGetAcademyPaymentsMaterials = {
  paths?: TGetAcademyPaymentsPaths;
  params?: TGetAcademyPaymentsParams;
};

export type TGetAcademyPaymentsResponse = TCommonResponse & {
  content: TCommonPaginate & {
    content: TPayment[];
    payment_value_models: TPaymentValueModels[];
  };
};

// FUNCTION

export const getAcademyPayments = async ({
  paths,
  params,
}: TGetAcademyPaymentsMaterials): Promise<TGetAcademyPaymentsResponse> => {
  const response = await ApiService.get(`/api-admin/academies/${paths?.id}/payments`, { params });
  return response?.data;
};
