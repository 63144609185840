import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TRefreshTokenParams = unknown;
export type TRefreshTokenBody = unknown;

export type TRefreshTokenMaterials = {
  params?: TRefreshTokenParams;
  body?: TRefreshTokenBody;
};

export type TRefreshTokenResponse = TCommonResponse & {
  content: {
    access_token: string;
    refresh_token: string;
  };
};

// FUNCTION

export const refreshToken = async ({ params, body }: TRefreshTokenMaterials): Promise<TRefreshTokenResponse> => {
  const response = await ApiService.post(`/api-public/auth/refresh-token`, body, { params });
  return response?.data;
};
