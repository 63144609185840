import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TCheckExistAcademyParams = unknown;

export type TCheckExistAcademyMaterials = {
  params?: TCheckExistAcademyParams;
};

export type TCheckExistAcademyResponse = TCommonResponse & {
  content: { is_exist: boolean };
};

// FUNCTION

export const checkExistAcademy = async ({
  params,
}: TCheckExistAcademyMaterials): Promise<TCheckExistAcademyResponse> => {
  const response = await ApiService.get(`/api-public/academies/is-exist`, { params });
  return response?.data;
};
