import { TAcademyState } from '@/redux/reducers/academy';
import { TGetAcademyPaymentsSuccess } from '@/redux/actions/academy';

export const getAcademyPaymentsUpdateState = (
  state: TAcademyState,
  action: TGetAcademyPaymentsSuccess,
): TAcademyState => ({
  ...state,
  getAcademyPaymentsResponse: action.payload.response,
});
