import { createActionCreator } from 'deox';

import {
  TCreateInvoiceByUserMaterials,
  TCreateInvoiceByUserResponse,
} from '@/services/api/invoice/create-invoice-by-user';

// CONSTANTS

export enum ECreateInvoiceByUserAction {
  CREATE_INVOICE_BY_USER = 'CREATE_INVOICE_BY_USER',
  CREATE_INVOICE_BY_USER_REQUEST = 'CREATE_INVOICE_BY_USER_REQUEST',
  CREATE_INVOICE_BY_USER_SUCCESS = 'CREATE_INVOICE_BY_USER_SUCCESS',
  CREATE_INVOICE_BY_USER_FAILED = 'CREATE_INVOICE_BY_USER_FAILED',
}

// TYPES

export type TCreateInvoiceByUserRequest = {
  type: ECreateInvoiceByUserAction.CREATE_INVOICE_BY_USER_REQUEST;
  payload: {
    materials: TCreateInvoiceByUserMaterials;
    successCallback?: (response: TCreateInvoiceByUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateInvoiceByUserSuccess = {
  type: ECreateInvoiceByUserAction.CREATE_INVOICE_BY_USER_SUCCESS;
  payload: { response: TCreateInvoiceByUserResponse };
};

export type TCreateInvoiceByUserFailed = { type: ECreateInvoiceByUserAction.CREATE_INVOICE_BY_USER_FAILED };

// FUNCTION

export const createInvoiceByUserAction = {
  request: createActionCreator(
    ECreateInvoiceByUserAction.CREATE_INVOICE_BY_USER_REQUEST,
    (resolve) =>
      (
        materials: TCreateInvoiceByUserMaterials,
        successCallback?: (response: TCreateInvoiceByUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateInvoiceByUserRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateInvoiceByUserAction.CREATE_INVOICE_BY_USER_SUCCESS,
    (resolve) =>
      (response: TCreateInvoiceByUserResponse): TCreateInvoiceByUserSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateInvoiceByUserAction.CREATE_INVOICE_BY_USER_FAILED,
    (resolve) =>
      (error: unknown): TCreateInvoiceByUserFailed =>
        resolve({ error }),
  ),
};
