import { TTestimonialState } from '@/redux/reducers/testimonial';
import { TGetTestimonialsSuccess } from '@/redux/actions/testimonial';

export const getTestimonialsUpdateState = (
  state: TTestimonialState,
  action: TGetTestimonialsSuccess,
): TTestimonialState => ({
  ...state,
  getTestimonialsResponse: action.payload.response,
});
