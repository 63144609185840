import { TAcademy } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TRegisterParams = unknown;
export type TRegisterBody = unknown;

export type TRegisterMaterials = {
  params?: TRegisterParams;
  body?: TRegisterBody;
};

export type TRegisterResponse = TCommonResponse & {
  content: {
    access_token: string;
    refresh_token: string;
    academy: TAcademy;
  };
};

// FUNCTION

export const register = async ({ params, body }: TRegisterMaterials): Promise<TRegisterResponse> => {
  const response = await ApiService.post(`/api-public/academies`, body, { params });
  return response?.data;
};
