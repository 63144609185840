import { TUser } from '@/common/models';
import { TCommonResponse, TCommonPaginate } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetUsersSearchParams = unknown;

export type TGetUsersSearchMaterials = {
  params?: TGetUsersSearchParams;
};

export type TGetUsersSearchResponse = TCommonResponse & {
  content: TCommonPaginate & {
    content: TUser[];
  };
};

// FUNCTION

export const getUsersSearch = async ({ params }: TGetUsersSearchMaterials): Promise<TGetUsersSearchResponse> => {
  const response = await ApiService.get(`/api-admin/users/search`, { params });
  return response?.data;
};
