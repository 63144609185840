import { createActionCreator } from 'deox';

import { TGetPublicPackagesMaterials, TGetPublicPackagesResponse } from '@/services/api/package/get-public-packages';

// CONSTANTS

export enum EGetPublicPackagesAction {
  GET_PUBLIC_PACKAGES = 'GET_PUBLIC_PACKAGES',
  GET_PUBLIC_PACKAGES_REQUEST = 'GET_PUBLIC_PACKAGES_REQUEST',
  GET_PUBLIC_PACKAGES_SUCCESS = 'GET_PUBLIC_PACKAGES_SUCCESS',
  GET_PUBLIC_PACKAGES_FAILED = 'GET_PUBLIC_PACKAGES_FAILED',
}

// TYPES

export type TGetPublicPackagesRequest = {
  type: EGetPublicPackagesAction.GET_PUBLIC_PACKAGES_REQUEST;
  payload: {
    materials: TGetPublicPackagesMaterials;
    successCallback?: (response: TGetPublicPackagesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPublicPackagesSuccess = {
  type: EGetPublicPackagesAction.GET_PUBLIC_PACKAGES_SUCCESS;
  payload: { response?: TGetPublicPackagesResponse };
};

export type TGetPublicPackagesFailed = { type: EGetPublicPackagesAction.GET_PUBLIC_PACKAGES_FAILED };

// FUNCTION

export const getPublicPackagesAction = {
  request: createActionCreator(
    EGetPublicPackagesAction.GET_PUBLIC_PACKAGES_REQUEST,
    (resolve) =>
      (
        materials: TGetPublicPackagesMaterials,
        successCallback?: (response: TGetPublicPackagesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPublicPackagesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPublicPackagesAction.GET_PUBLIC_PACKAGES_SUCCESS,
    (resolve) =>
      (response?: TGetPublicPackagesResponse): TGetPublicPackagesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPublicPackagesAction.GET_PUBLIC_PACKAGES_FAILED,
    (resolve) =>
      (error: unknown): TGetPublicPackagesFailed =>
        resolve({ error }),
  ),
};
