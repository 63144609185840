import { TFeature } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetPublicFeaturesParams = unknown;

export type TGetPublicFeaturesMaterials = {
  params?: TGetPublicFeaturesParams;
};

export type TGetPublicFeaturesResponse = TCommonResponse & {
  content: TFeature[];
};

// FUNCTION

export const getPublicFeatures = async ({
  params,
}: TGetPublicFeaturesMaterials): Promise<TGetPublicFeaturesResponse> => {
  const response = await ApiService.get(`/api-public/features`, { params });
  return response?.data;
};
