import ApiService from '@/services/api';

// TYPES

export type TUpdateInvoicePaths = {
  id: string | number;
};
export type TUpdateInvoiceBody = unknown;

export type TUpdateInvoiceMaterials = {
  paths?: TUpdateInvoicePaths;
  body?: TUpdateInvoiceBody;
};

export type TUpdateInvoiceResponse = unknown;

// FUNCTION

export const updateInvoice = async ({ paths, body }: TUpdateInvoiceMaterials): Promise<TUpdateInvoiceResponse> => {
  const response = await ApiService.put(`/api-admin/invoices/${paths?.id}`, body);
  return response?.data;
};
