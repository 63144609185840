import { all, takeLatest } from 'redux-saga/effects';

import {
  createTestimonialAction,
  deleteTestimonialAction,
  getTestimonialsAction,
  updateTestimonialAction,
} from '@/redux/actions';

import { createTestimonialSaga } from './create-testimonial';
import { deleteTestimonialSaga } from './delete-testimonial';
import { getTestimonialsSaga } from './get-testimonials';
import { updateTestimonialSaga } from './update-testimonial';

export default function* root(): Generator {
  yield all([
    takeLatest(createTestimonialAction.request.type, createTestimonialSaga),
    takeLatest(deleteTestimonialAction.request.type, deleteTestimonialSaga),
    takeLatest(getTestimonialsAction.request.type, getTestimonialsSaga),
    takeLatest(updateTestimonialAction.request.type, updateTestimonialSaga),
  ]);
}
