import { createActionCreator } from 'deox';

import { TUpdateCouponMaterials, TUpdateCouponResponse } from '@/services/api/coupon/update-coupon';

// CONSTANTS

export enum EUpdateCouponAction {
  UPDATE_COUPON = 'UPDATE_COUPON',
  UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST',
  UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_FAILED = 'UPDATE_COUPON_FAILED',
}

// TYPES

export type TUpdateCouponRequest = {
  type: EUpdateCouponAction.UPDATE_COUPON_REQUEST;
  payload: {
    materials: TUpdateCouponMaterials;
    successCallback?: (response: TUpdateCouponResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateCouponSuccess = {
  type: EUpdateCouponAction.UPDATE_COUPON_SUCCESS;
  payload: { response: TUpdateCouponResponse };
};

export type TUpdateCouponFailed = { type: EUpdateCouponAction.UPDATE_COUPON_FAILED };

// FUNCTION

export const updateCouponAction = {
  request: createActionCreator(
    EUpdateCouponAction.UPDATE_COUPON_REQUEST,
    (resolve) =>
      (
        materials: TUpdateCouponMaterials,
        successCallback?: (response: TUpdateCouponResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateCouponRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateCouponAction.UPDATE_COUPON_SUCCESS,
    (resolve) =>
      (response: TUpdateCouponResponse): TUpdateCouponSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateCouponAction.UPDATE_COUPON_FAILED,
    (resolve) =>
      (error: unknown): TUpdateCouponFailed =>
        resolve({ error }),
  ),
};
