import { createActionCreator } from 'deox';

import { TGoogleLoginMaterials, TGoogleLoginResponse } from '@/services/api/auth/google-login';

// CONSTANTS

export enum EGoogleLoginAction {
  GOOGLE_LOGIN = 'GOOGLE_LOGIN',
  GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST',
  GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS',
  GOOGLE_LOGIN_FAILED = 'GOOGLE_LOGIN_FAILED',
}

// TYPES

export type TGoogleLoginRequest = {
  type: EGoogleLoginAction.GOOGLE_LOGIN_REQUEST;
  payload: {
    materials: TGoogleLoginMaterials;
    successCallback?: (response: TGoogleLoginResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGoogleLoginSuccess = {
  type: EGoogleLoginAction.GOOGLE_LOGIN_SUCCESS;
  payload: { response: TGoogleLoginResponse };
};

export type TGoogleLoginFailed = { type: EGoogleLoginAction.GOOGLE_LOGIN_FAILED };

// FUNCTION

export const googleLoginAction = {
  request: createActionCreator(
    EGoogleLoginAction.GOOGLE_LOGIN_REQUEST,
    (resolve) =>
      (
        materials: TGoogleLoginMaterials,
        successCallback?: (response: TGoogleLoginResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGoogleLoginRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGoogleLoginAction.GOOGLE_LOGIN_SUCCESS,
    (resolve) =>
      (response: TGoogleLoginResponse): TGoogleLoginSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGoogleLoginAction.GOOGLE_LOGIN_FAILED,
    (resolve) =>
      (error: unknown): TGoogleLoginFailed =>
        resolve({ error }),
  ),
};
