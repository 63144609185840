import ApiService from '@/services/api';

// TYPES

export type TCreateTestimonialParams = unknown;
export type TCreateTestimonialBody = unknown;

export type TCreateTestimonialMaterials = {
  params?: TCreateTestimonialParams;
  body?: TCreateTestimonialBody;
};

export type TCreateTestimonialResponse = unknown;

// FUNCTION

export const createTestimonial = async ({
  params,
  body,
}: TCreateTestimonialMaterials): Promise<TCreateTestimonialResponse> => {
  const response = await ApiService.post(`/api-admin/testimonials`, body, { params });
  return response?.data;
};
