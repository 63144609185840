import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGoogleLoginParams = unknown;
export type TGoogleLoginBody = unknown;

export type TGoogleLoginMaterials = {
  params?: TGoogleLoginParams;
  body?: TGoogleLoginBody;
};

export type TGoogleLoginResponse = TCommonResponse & {
  content: {
    access_token: string;
    refresh_token: string;
  };
};

// FUNCTION

export const googleLogin = async ({ params, body }: TGoogleLoginMaterials): Promise<TGoogleLoginResponse> => {
  const response = await ApiService.post(`/api-public/auth/login3rd`, body, { params });
  return response?.data;
};
