import { createActionCreator } from 'deox';

import { TUpdateInvoiceMaterials, TUpdateInvoiceResponse } from '@/services/api/invoice/update-invoice';

// CONSTANTS

export enum EUpdateInvoiceAction {
  UPDATE_INVOICE = 'UPDATE_INVOICE',
  UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST',
  UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS',
  UPDATE_INVOICE_FAILED = 'UPDATE_INVOICE_FAILED',
}

// TYPES

export type TUpdateInvoiceRequest = {
  type: EUpdateInvoiceAction.UPDATE_INVOICE_REQUEST;
  payload: {
    materials: TUpdateInvoiceMaterials;
    successCallback?: (response: TUpdateInvoiceResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateInvoiceSuccess = {
  type: EUpdateInvoiceAction.UPDATE_INVOICE_SUCCESS;
  payload: { response: TUpdateInvoiceResponse };
};

export type TUpdateInvoiceFailed = { type: EUpdateInvoiceAction.UPDATE_INVOICE_FAILED };

// FUNCTION

export const updateInvoiceAction = {
  request: createActionCreator(
    EUpdateInvoiceAction.UPDATE_INVOICE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateInvoiceMaterials,
        successCallback?: (response: TUpdateInvoiceResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateInvoiceRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateInvoiceAction.UPDATE_INVOICE_SUCCESS,
    (resolve) =>
      (response: TUpdateInvoiceResponse): TUpdateInvoiceSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateInvoiceAction.UPDATE_INVOICE_FAILED,
    (resolve) =>
      (error: unknown): TUpdateInvoiceFailed =>
        resolve({ error }),
  ),
};
