import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { checkExistUserAction } from '@/redux/actions';
import { checkExistUser, TCheckExistUserResponse } from '@/services/api';

// FUNCTION

export function* checkExistUserSaga(action: ActionType<typeof checkExistUserAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(checkExistUser, materials);
    const checkExistUserResponse: TCheckExistUserResponse = response as TCheckExistUserResponse;
    yield put(checkExistUserAction.success(checkExistUserResponse));
    successCallback?.(checkExistUserResponse);
  } catch (err) {
    yield put(checkExistUserAction.failure(err));
    failedCallback?.(err);
  }
}
