import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteTestimonialAction } from '@/redux/actions';
import { deleteTestimonial, TDeleteTestimonialResponse } from '@/services/api';

// FUNCTION

export function* deleteTestimonialSaga(action: ActionType<typeof deleteTestimonialAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteTestimonial, materials);
    const deleteTestimonialResponse: TDeleteTestimonialResponse = response as TDeleteTestimonialResponse;
    yield put(deleteTestimonialAction.success(deleteTestimonialResponse));
    successCallback?.(deleteTestimonialResponse);
  } catch (err) {
    yield put(deleteTestimonialAction.failure(err));
    failedCallback?.(err);
  }
}
