import { createActionCreator } from 'deox';

import {
  TCreatePaymentByUserMaterials,
  TCreatePaymentByUserResponse,
} from '@/services/api/payment/create-payment-by-user';

// CONSTANTS

export enum ECreatePaymentByUserAction {
  CREATE_PAYMENT_BY_USER = 'CREATE_PAYMENT_BY_USER',
  CREATE_PAYMENT_BY_USER_REQUEST = 'CREATE_PAYMENT_BY_USER_REQUEST',
  CREATE_PAYMENT_BY_USER_SUCCESS = 'CREATE_PAYMENT_BY_USER_SUCCESS',
  CREATE_PAYMENT_BY_USER_FAILED = 'CREATE_PAYMENT_BY_USER_FAILED',
}

// TYPES

export type TCreatePaymentByUserRequest = {
  type: ECreatePaymentByUserAction.CREATE_PAYMENT_BY_USER_REQUEST;
  payload: {
    materials: TCreatePaymentByUserMaterials;
    successCallback?: (response: TCreatePaymentByUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreatePaymentByUserSuccess = {
  type: ECreatePaymentByUserAction.CREATE_PAYMENT_BY_USER_SUCCESS;
  payload: { response: TCreatePaymentByUserResponse };
};

export type TCreatePaymentByUserFailed = { type: ECreatePaymentByUserAction.CREATE_PAYMENT_BY_USER_FAILED };

// FUNCTION

export const createPaymentByUserAction = {
  request: createActionCreator(
    ECreatePaymentByUserAction.CREATE_PAYMENT_BY_USER_REQUEST,
    (resolve) =>
      (
        materials: TCreatePaymentByUserMaterials,
        successCallback?: (response: TCreatePaymentByUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreatePaymentByUserRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreatePaymentByUserAction.CREATE_PAYMENT_BY_USER_SUCCESS,
    (resolve) =>
      (response: TCreatePaymentByUserResponse): TCreatePaymentByUserSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreatePaymentByUserAction.CREATE_PAYMENT_BY_USER_FAILED,
    (resolve) =>
      (error: unknown): TCreatePaymentByUserFailed =>
        resolve({ error }),
  ),
};
