import { createReducer } from 'deox';

import {
  TCheckExistAcademyResponse,
  TCreateAcademyResponse,
  TDeleteAcademyResponse,
  TGetAcademiesResponse,
  TGetAcademyByDomainResponse,
  TGetAcademyPaymentsResponse,
  TGetAcademyResponse,
  TUpdateAcademyResponse,
} from '@/services/api/academy';
import {
  checkExistAcademyAction,
  createAcademyAction,
  deleteAcademyAction,
  getAcademiesAction,
  getAcademyByDomainAction,
  getAcademyPaymentsAction,
  getAcademyAction,
  updateAcademyAction,
} from '@/redux/actions';
import { checkExistAcademyUpdateState } from './check-exist-academy';
import { createAcademyUpdateState } from './create-academy';
import { deleteAcademyUpdateState } from './delete-academy';
import { getAcademiesUpdateState } from './get-academies';
import { getAcademyByDomainUpdateState } from './get-academy-by-domain';
import { getAcademyPaymentsUpdateState } from './get-academy-payments';
import { getAcademyUpdateState } from './get-academy';
import { updateAcademyUpdateState } from './update-academy';

export type TAcademyState = {
  checkExistAcademyResponse?: TCheckExistAcademyResponse;
  createAcademyResponse?: TCreateAcademyResponse;
  deleteAcademyResponse?: TDeleteAcademyResponse;
  getAcademiesResponse?: TGetAcademiesResponse;
  getAcademyByDomainResponse?: TGetAcademyByDomainResponse;
  getAcademyPaymentsResponse?: TGetAcademyPaymentsResponse;
  getAcademyResponse?: TGetAcademyResponse;
  updateAcademyResponse?: TUpdateAcademyResponse;
};

const initialState: TAcademyState = {
  checkExistAcademyResponse: undefined,
  createAcademyResponse: undefined,
  deleteAcademyResponse: undefined,
  getAcademiesResponse: undefined,
  getAcademyByDomainResponse: undefined,
  getAcademyPaymentsResponse: undefined,
  getAcademyResponse: undefined,
  updateAcademyResponse: undefined,
};

const AcademyReducer = createReducer(initialState, (handleAction) => [
  handleAction(checkExistAcademyAction.success, checkExistAcademyUpdateState),
  handleAction(createAcademyAction.success, createAcademyUpdateState),
  handleAction(deleteAcademyAction.success, deleteAcademyUpdateState),
  handleAction(getAcademiesAction.success, getAcademiesUpdateState),
  handleAction(getAcademyByDomainAction.success, getAcademyByDomainUpdateState),
  handleAction(getAcademyPaymentsAction.success, getAcademyPaymentsUpdateState),
  handleAction(getAcademyAction.success, getAcademyUpdateState),
  handleAction(updateAcademyAction.success, updateAcademyUpdateState),
]);

export default AcademyReducer;
