import { TPackage } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetPublicPackagesParams = {
  sort?: string;
};

export type TGetPublicPackagesMaterials = {
  params?: TGetPublicPackagesParams;
};

export type TGetPublicPackagesResponse = TCommonResponse & {
  content: TPackage[];
};

// FUNCTION

export const getPublicPackages = async ({
  params,
}: TGetPublicPackagesMaterials): Promise<TGetPublicPackagesResponse> => {
  const response = await ApiService.get(`/api-public/packages`, { params });
  return response?.data;
};
