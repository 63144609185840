import { TCoupon } from '@/common/models';
import { TCommonPaginate, TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetCouponsParams = {
  page: number;
  size: number;
  sort?: string;
  search?: string;
};

export type TGetCouponsMaterials = {
  params?: TGetCouponsParams;
};

export type TGetCouponsResponse = TCommonResponse & {
  content: TCommonPaginate & {
    content: TCoupon[];
  };
};

// FUNCTION

export const getCoupons = async ({ params }: TGetCouponsMaterials): Promise<TGetCouponsResponse> => {
  const response = await ApiService.get(`/api-admin/coupons`, { params });
  return response?.data;
};
