import ApiService from '@/services/api';

// TYPES

export type TDeleteAcademyPaths = {
  id: string | number;
};
export type TDeleteAcademyParams = unknown;

export type TDeleteAcademyMaterials = {
  paths?: TDeleteAcademyPaths;
  params?: TDeleteAcademyParams;
};

export type TDeleteAcademyResponse = unknown;

// FUNCTION

export const deleteAcademy = async ({ paths, params }: TDeleteAcademyMaterials): Promise<TDeleteAcademyResponse> => {
  const response = await ApiService.delete(`/api-admin/academies/${paths?.id}`, { params });
  return response?.data;
};
