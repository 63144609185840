import { createActionCreator } from 'deox';

import { TUpdatePaymentMaterials, TUpdatePaymentResponse } from '@/services/api/payment/update-payment';

// CONSTANTS

export enum EUpdatePaymentAction {
  UPDATE_PAYMENT = 'UPDATE_PAYMENT',
  UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST',
  UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_FAILED = 'UPDATE_PAYMENT_FAILED',
}

// TYPES

export type TUpdatePaymentRequest = {
  type: EUpdatePaymentAction.UPDATE_PAYMENT_REQUEST;
  payload: {
    materials: TUpdatePaymentMaterials;
    successCallback?: (response: TUpdatePaymentResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdatePaymentSuccess = {
  type: EUpdatePaymentAction.UPDATE_PAYMENT_SUCCESS;
  payload: { response: TUpdatePaymentResponse };
};

export type TUpdatePaymentFailed = { type: EUpdatePaymentAction.UPDATE_PAYMENT_FAILED };

// FUNCTION

export const updatePaymentAction = {
  request: createActionCreator(
    EUpdatePaymentAction.UPDATE_PAYMENT_REQUEST,
    (resolve) =>
      (
        materials: TUpdatePaymentMaterials,
        successCallback?: (response: TUpdatePaymentResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdatePaymentRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdatePaymentAction.UPDATE_PAYMENT_SUCCESS,
    (resolve) =>
      (response: TUpdatePaymentResponse): TUpdatePaymentSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdatePaymentAction.UPDATE_PAYMENT_FAILED,
    (resolve) =>
      (error: unknown): TUpdatePaymentFailed =>
        resolve({ error }),
  ),
};
