import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateInvoiceAction } from '@/redux/actions';
import { updateInvoice, TUpdateInvoiceResponse } from '@/services/api';

// FUNCTION

export function* updateInvoiceSaga(action: ActionType<typeof updateInvoiceAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateInvoice, materials);
    const updateInvoiceResponse: TUpdateInvoiceResponse = response as TUpdateInvoiceResponse;
    yield put(updateInvoiceAction.success(updateInvoiceResponse));
    successCallback?.(updateInvoiceResponse);
  } catch (err) {
    yield put(updateInvoiceAction.failure(err));
    failedCallback?.(err);
  }
}
