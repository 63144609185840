import { createActionCreator } from 'deox';

import { TGetPublicCouponMaterials, TGetPublicCouponResponse } from '@/services/api/coupon/get-public-coupon';

// CONSTANTS

export enum EGetPublicCouponAction {
  GET_PUBLIC_COUPON = 'GET_PUBLIC_COUPON',
  GET_PUBLIC_COUPON_REQUEST = 'GET_PUBLIC_COUPON_REQUEST',
  GET_PUBLIC_COUPON_SUCCESS = 'GET_PUBLIC_COUPON_SUCCESS',
  GET_PUBLIC_COUPON_FAILED = 'GET_PUBLIC_COUPON_FAILED',
}

// TYPES

export type TGetPublicCouponRequest = {
  type: EGetPublicCouponAction.GET_PUBLIC_COUPON_REQUEST;
  payload: {
    materials: TGetPublicCouponMaterials;
    successCallback?: (response: TGetPublicCouponResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPublicCouponSuccess = {
  type: EGetPublicCouponAction.GET_PUBLIC_COUPON_SUCCESS;
  payload: { response?: TGetPublicCouponResponse };
};

export type TGetPublicCouponFailed = { type: EGetPublicCouponAction.GET_PUBLIC_COUPON_FAILED };

// FUNCTION

export const getPublicCouponAction = {
  request: createActionCreator(
    EGetPublicCouponAction.GET_PUBLIC_COUPON_REQUEST,
    (resolve) =>
      (
        materials: TGetPublicCouponMaterials,
        successCallback?: (response: TGetPublicCouponResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPublicCouponRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPublicCouponAction.GET_PUBLIC_COUPON_SUCCESS,
    (resolve) =>
      (response?: TGetPublicCouponResponse): TGetPublicCouponSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPublicCouponAction.GET_PUBLIC_COUPON_FAILED,
    (resolve) =>
      (error: unknown): TGetPublicCouponFailed =>
        resolve({ error }),
  ),
};
