import { TAdvisory } from '@/common/models';
import { TCommonPaginate, TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetAdvisoriesParams = {
  page: number;
  size: number;
  sort?: string;
  search?: string;
};

export type TGetAdvisoriesMaterials = {
  params?: TGetAdvisoriesParams;
};

export type TGetAdvisoriesResponse = TCommonResponse & {
  content: TCommonPaginate & {
    content: TAdvisory[];
  };
};

// FUNCTION

export const getAdvisories = async ({ params }: TGetAdvisoriesMaterials): Promise<TGetAdvisoriesResponse> => {
  const response = await ApiService.get(`/api-admin/advisories`, { params });
  return response?.data;
};
