import { createActionCreator } from 'deox';

import { TUpdatePackageMaterials, TUpdatePackageResponse } from '@/services/api/package/update-package';

// CONSTANTS

export enum EUpdatePackageAction {
  UPDATE_PACKAGE = 'UPDATE_PACKAGE',
  UPDATE_PACKAGE_REQUEST = 'UPDATE_PACKAGE_REQUEST',
  UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS',
  UPDATE_PACKAGE_FAILED = 'UPDATE_PACKAGE_FAILED',
}

// TYPES

export type TUpdatePackageRequest = {
  type: EUpdatePackageAction.UPDATE_PACKAGE_REQUEST;
  payload: {
    materials: TUpdatePackageMaterials;
    successCallback?: (response: TUpdatePackageResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdatePackageSuccess = {
  type: EUpdatePackageAction.UPDATE_PACKAGE_SUCCESS;
  payload: { response: TUpdatePackageResponse };
};

export type TUpdatePackageFailed = { type: EUpdatePackageAction.UPDATE_PACKAGE_FAILED };

// FUNCTION

export const updatePackageAction = {
  request: createActionCreator(
    EUpdatePackageAction.UPDATE_PACKAGE_REQUEST,
    (resolve) =>
      (
        materials: TUpdatePackageMaterials,
        successCallback?: (response: TUpdatePackageResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdatePackageRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdatePackageAction.UPDATE_PACKAGE_SUCCESS,
    (resolve) =>
      (response: TUpdatePackageResponse): TUpdatePackageSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdatePackageAction.UPDATE_PACKAGE_FAILED,
    (resolve) =>
      (error: unknown): TUpdatePackageFailed =>
        resolve({ error }),
  ),
};
