/* eslint-disable react/require-default-props */
import React, { lazy, Suspense } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';

import Helpers from '@/services/helpers';
import Loading from '@/components/Loading';

const retryLoadComponent = (fn: () => Promise<unknown>, retriesLeft = 5, interval = 1000): any =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retryLoadComponent(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

const Dashboard = lazy(() => retryLoadComponent(() => import('@/pages/Dashboard')));
const RegisterBranch = lazy(() => retryLoadComponent(() => import('@/pages/RegisterBranch')));
const Academy = lazy(() => retryLoadComponent(() => import('@/pages/Academy')));
const ContactUsers = lazy(() => retryLoadComponent(() => import('@/pages/ContactUsers')));
const AcademyDetail = lazy(() => retryLoadComponent(() => import('@/pages/AcademyDetail')));
const HistoriesPayment = lazy(() => retryLoadComponent(() => import('@/pages/HistoriesPayment')));
const CRMs = lazy(() => retryLoadComponent(() => import('@/pages/CRMs')));
const Coupons = lazy(() => retryLoadComponent(() => import('@/pages/Coupons')));
const Packages = lazy(() => retryLoadComponent(() => import('@/pages/Packages')));
const PaymentStatus = lazy(() => retryLoadComponent(() => import('@/pages/PaymentStatus')));

const Login = lazy(() => retryLoadComponent(() => import('@/pages/Login')));
const LoginDomain = lazy(() => retryLoadComponent(() => import('@/pages/LoginDomain')));

const SettingTrialMode = lazy(() => retryLoadComponent(() => import('@/pages/SettingTrialMode')));
const SettingVAT = lazy(() => retryLoadComponent(() => import('@/pages/SettingVAT')));
const SettingEmail = lazy(() => retryLoadComponent(() => import('@/pages/SettingEmail')));
const SettingMoney = lazy(() => retryLoadComponent(() => import('@/pages/SettingMoney')));
const SettingBank = lazy(() => retryLoadComponent(() => import('@/pages/SettingBank')));
const SettingGeneral = lazy(() => retryLoadComponent(() => import('@/pages/SettingGeneral')));
const Receipts = lazy(() => retryLoadComponent(() => import('@/pages/Receipts')));
const Feedback = lazy(() => retryLoadComponent(() => import('@/pages/Feedback')));

export const LayoutPaths = {
  Guest: '/guest',
  Auth: '/auth',
  View: '/view',
  Admin: '/',
};

export const ModulePaths = {};

export const Paths = {
  Dashboard: '/',
  Academy: '/academy',
  AcademyDetail: (id?: string): string => `/academy/${id || ':id'}`,
  HistoriesPayment: '/histories-paymemt',
  CRMs: '/customers',
  Coupons: '/coupons',
  Packages: '/packages',
  Receipts: '/receipts',
  PaymentStatus: '/payment-status',
  Register: '/register',
  Feedback: '/feedback',

  Login: '/login',
  LoginDomain: '/login-domain',

  SettingTrialMode: '/setting-trial-mode',
  SettingVAT: '/setting-vat',
  SettingEmail: '/setting-email',
  SettingMoney: '/setting-money',
  SettingBank: '/setting-bank',
  SettingGeneral: '/setting-general',
  ContactUsers: '/contact-users',

  Rest: '*',
};

export const Pages = {
  Dashboard,
  RegisterBranch,
  Academy,
  AcademyDetail,
  HistoriesPayment,
  CRMs,
  Coupons,
  Packages,
  PaymentStatus,
  Feedback,

  Login,
  LoginDomain,
  SettingTrialMode,
  SettingVAT,
  SettingEmail,
  SettingMoney,
  SettingBank,
  SettingGeneral,
  Receipts,
  ContactUsers,
};

interface IRouteProps extends RouteComponentProps {
  component: React.FC;
  managers?: boolean;
  revenues?: boolean;
  expenses?: boolean;
  forward?: boolean;
  back?: boolean;
}

export const AuthRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();

  return loggedIn ? (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Admin} />
  ) : (
    <Suspense fallback={<Loading />}>
      <Component {...rest} />
    </Suspense>
  );
};

export const ProtectedRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();

  return loggedIn ? (
    <Suspense fallback={<Loading />}>
      <Component {...rest} />
    </Suspense>
  ) : (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Auth} />
  );
};

export const PublicRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => (
  <Suspense fallback={<Loading />}>
    <Component {...rest} />
  </Suspense>
);
