import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getPublicPaymentsAction } from '@/redux/actions';
import { getPublicPayments, TGetPublicPaymentsResponse } from '@/services/api';

// FUNCTION

export function* getPublicPaymentsSaga(action: ActionType<typeof getPublicPaymentsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getPublicPayments, materials);
    const getPublicPaymentsResponse: TGetPublicPaymentsResponse = response as TGetPublicPaymentsResponse;
    yield put(getPublicPaymentsAction.success(getPublicPaymentsResponse));
    successCallback?.(getPublicPaymentsResponse);
  } catch (err) {
    yield put(getPublicPaymentsAction.failure(err));
    failedCallback?.(err);
  }
}
