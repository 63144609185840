import { all, fork } from 'redux-saga/effects';

import academySaga from './academy';
import advisorySaga from './advisory';
import authSaga from './auth';
import couponSaga from './coupon';
import featureSaga from './feature';
import invoiceSaga from './invoice';
import packageSaga from './package';
import paymentSaga from './payment';
import reportSaga from './report';
import settingSaga from './setting';
import testimonialSaga from './testimonial';
import userSaga from './user';

const rootSaga = function* root(): Generator {
  yield all([
    fork(academySaga),
    fork(advisorySaga),
    fork(authSaga),
    fork(couponSaga),
    fork(featureSaga),
    fork(invoiceSaga),
    fork(packageSaga),
    fork(paymentSaga),
    fork(reportSaga),
    fork(settingSaga),
    fork(testimonialSaga),
    fork(userSaga),
  ]);
};

export default rootSaga;
