import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getCouponsAction } from '@/redux/actions';
import { getCoupons, TGetCouponsResponse } from '@/services/api';

// FUNCTION

export function* getCouponsSaga(action: ActionType<typeof getCouponsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getCoupons, materials);
    const getCouponsResponse: TGetCouponsResponse = response as TGetCouponsResponse;
    yield put(getCouponsAction.success(getCouponsResponse));
    successCallback?.(getCouponsResponse);
  } catch (err) {
    yield put(getCouponsAction.failure(err));
    failedCallback?.(err);
  }
}
