import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateTestimonialAction } from '@/redux/actions';
import { updateTestimonial, TUpdateTestimonialResponse } from '@/services/api';

// FUNCTION

export function* updateTestimonialSaga(action: ActionType<typeof updateTestimonialAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateTestimonial, materials);
    const updateTestimonialResponse: TUpdateTestimonialResponse = response as TUpdateTestimonialResponse;
    yield put(updateTestimonialAction.success(updateTestimonialResponse));
    successCallback?.(updateTestimonialResponse);
  } catch (err) {
    yield put(updateTestimonialAction.failure(err));
    failedCallback?.(err);
  }
}
