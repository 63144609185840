import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteAdvisoryAction } from '@/redux/actions';
import { deleteAdvisory, TDeleteAdvisoryResponse } from '@/services/api';

// FUNCTION

export function* deleteAdvisorySaga(action: ActionType<typeof deleteAdvisoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteAdvisory, materials);
    const deleteAdvisoryResponse: TDeleteAdvisoryResponse = response as TDeleteAdvisoryResponse;
    yield put(deleteAdvisoryAction.success(deleteAdvisoryResponse));
    successCallback?.(deleteAdvisoryResponse);
  } catch (err) {
    yield put(deleteAdvisoryAction.failure(err));
    failedCallback?.(err);
  }
}
