import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: 'AIzaSyDYSlntcDzkWeySrVxJvlnImu_4JR5HA_E',
  authDomain: 'young-kid.firebaseapp.com',
  databaseURL: 'https://young-kid.firebaseio.com',
  projectId: 'young-kid',
  storageBucket: 'young-kid.appspot.com',
  messagingSenderId: '19826918016',
  appId: '1:19826918016:web:8449c9c49519d044fb5c65',
  measurementId: 'G-7PEYCPS24D',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
getAuth(app);
