import { TAcademyState } from '@/redux/reducers/academy';
import { TGetAcademyByDomainSuccess } from '@/redux/actions/academy';

export const getAcademyByDomainUpdateState = (
  state: TAcademyState,
  action: TGetAcademyByDomainSuccess,
): TAcademyState => ({
  ...state,
  getAcademyByDomainResponse: action.payload.response,
});
