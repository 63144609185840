import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createPaymentAction } from '@/redux/actions';
import { createPayment, TCreatePaymentResponse } from '@/services/api';

// FUNCTION

export function* createPaymentSaga(action: ActionType<typeof createPaymentAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createPayment, materials);
    const createPaymentResponse: TCreatePaymentResponse = response as TCreatePaymentResponse;
    yield put(createPaymentAction.success(createPaymentResponse));
    successCallback?.(createPaymentResponse);
  } catch (err) {
    yield put(createPaymentAction.failure(err));
    failedCallback?.(err);
  }
}
