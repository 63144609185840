import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getInvoiceAction } from '@/redux/actions';
import { getInvoice, TGetInvoiceResponse } from '@/services/api';

// FUNCTION

export function* getInvoiceSaga(action: ActionType<typeof getInvoiceAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getInvoice, materials);
    const getInvoiceResponse: TGetInvoiceResponse = response as TGetInvoiceResponse;
    yield put(getInvoiceAction.success(getInvoiceResponse));
    successCallback?.(getInvoiceResponse);
  } catch (err) {
    yield put(getInvoiceAction.failure(err));
    failedCallback?.(err);
  }
}
