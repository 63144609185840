import ApiService from '@/services/api';

// TYPES

export type TUpdatePaymentPaths = {
  id: string | number;
};
export type TUpdatePaymentBody = unknown;

export type TUpdatePaymentMaterials = {
  paths?: TUpdatePaymentPaths;
  body?: TUpdatePaymentBody;
};

export type TUpdatePaymentResponse = unknown;

// FUNCTION

export const updatePayment = async ({ paths, body }: TUpdatePaymentMaterials): Promise<TUpdatePaymentResponse> => {
  const response = await ApiService.put(`/api-admin/payments/${paths?.id}`, body);
  return response?.data;
};
