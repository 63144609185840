import ApiService from '@/services/api';

// TYPES

export type TUpdateAcademyBody = unknown;

export type TUpdateAcademyPaths = {
  id: string | number;
};

export type TUpdateAcademyMaterials = {
  body?: TUpdateAcademyBody;
  paths?: TUpdateAcademyPaths;
};

export type TUpdateAcademyResponse = unknown;

// FUNCTION

export const updateAcademy = async ({ body, paths }: TUpdateAcademyMaterials): Promise<TUpdateAcademyResponse> => {
  const response = await ApiService.put(`/api-admin/academies/${paths?.id}`, body);
  return response?.data;
};
