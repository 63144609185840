import { TUser } from '@/common/models';
import { TCommonPaginate, TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetUsersParams = {
  page: number;
  size: number;
  search?: string;
  sort?: string;
};

export type TGetUsersMaterials = {
  params?: TGetUsersParams;
};

export type TGetUsersResponse = TCommonResponse & {
  content: TCommonPaginate & {
    content: TUser[];
  };
};

// FUNCTION

export const getUsers = async ({ params }: TGetUsersMaterials): Promise<TGetUsersResponse> => {
  const response = await ApiService.get(`/api-admin/users`, { params });
  return response?.data;
};
