import { createActionCreator } from 'deox';

import { TCreatePackageMaterials, TCreatePackageResponse } from '@/services/api/package/create-package';

// CONSTANTS

export enum ECreatePackageAction {
  CREATE_PACKAGE = 'CREATE_PACKAGE',
  CREATE_PACKAGE_REQUEST = 'CREATE_PACKAGE_REQUEST',
  CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS',
  CREATE_PACKAGE_FAILED = 'CREATE_PACKAGE_FAILED',
}

// TYPES

export type TCreatePackageRequest = {
  type: ECreatePackageAction.CREATE_PACKAGE_REQUEST;
  payload: {
    materials: TCreatePackageMaterials;
    successCallback?: (response: TCreatePackageResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreatePackageSuccess = {
  type: ECreatePackageAction.CREATE_PACKAGE_SUCCESS;
  payload: { response: TCreatePackageResponse };
};

export type TCreatePackageFailed = { type: ECreatePackageAction.CREATE_PACKAGE_FAILED };

// FUNCTION

export const createPackageAction = {
  request: createActionCreator(
    ECreatePackageAction.CREATE_PACKAGE_REQUEST,
    (resolve) =>
      (
        materials: TCreatePackageMaterials,
        successCallback?: (response: TCreatePackageResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreatePackageRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreatePackageAction.CREATE_PACKAGE_SUCCESS,
    (resolve) =>
      (response: TCreatePackageResponse): TCreatePackageSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreatePackageAction.CREATE_PACKAGE_FAILED,
    (resolve) =>
      (error: unknown): TCreatePackageFailed =>
        resolve({ error }),
  ),
};
