import ApiService from '@/services/api';

// TYPES

export type TDeletePackagePaths = {
  id: string | number;
};
export type TDeletePackageParams = unknown;

export type TDeletePackageMaterials = {
  paths?: TDeletePackagePaths;
  params?: TDeletePackageParams;
};

export type TDeletePackageResponse = unknown;

// FUNCTION

export const deletePackage = async ({ paths, params }: TDeletePackageMaterials): Promise<TDeletePackageResponse> => {
  const response = await ApiService.delete(`/api-admin/packages/${paths?.id}`, { params });
  return response?.data;
};
