import { TPackage } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetPackagesParams = {
  auditingStatuses?: string;
  sort?: string;
  search?: string;
};

export type TGetPackagesMaterials = {
  params?: TGetPackagesParams;
};

export type TGetPackagesResponse = TCommonResponse & {
  content: TPackage[];
};

// FUNCTION

export const getPackages = async ({ params }: TGetPackagesMaterials): Promise<TGetPackagesResponse> => {
  const response = await ApiService.get(`/api-admin/packages`, { params });
  return response?.data;
};
