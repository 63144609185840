import { createActionCreator } from 'deox';

import { TGetPublicPaymentsMaterials, TGetPublicPaymentsResponse } from '@/services/api/payment/get-public-payments';

// CONSTANTS

export enum EGetPublicPaymentsAction {
  GET_PUBLIC_PAYMENTS = 'GET_PUBLIC_PAYMENTS',
  GET_PUBLIC_PAYMENTS_REQUEST = 'GET_PUBLIC_PAYMENTS_REQUEST',
  GET_PUBLIC_PAYMENTS_SUCCESS = 'GET_PUBLIC_PAYMENTS_SUCCESS',
  GET_PUBLIC_PAYMENTS_FAILED = 'GET_PUBLIC_PAYMENTS_FAILED',
}

// TYPES

export type TGetPublicPaymentsRequest = {
  type: EGetPublicPaymentsAction.GET_PUBLIC_PAYMENTS_REQUEST;
  payload: {
    materials: TGetPublicPaymentsMaterials;
    successCallback?: (response: TGetPublicPaymentsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPublicPaymentsSuccess = {
  type: EGetPublicPaymentsAction.GET_PUBLIC_PAYMENTS_SUCCESS;
  payload: { response: TGetPublicPaymentsResponse };
};

export type TGetPublicPaymentsFailed = { type: EGetPublicPaymentsAction.GET_PUBLIC_PAYMENTS_FAILED };

// FUNCTION

export const getPublicPaymentsAction = {
  request: createActionCreator(
    EGetPublicPaymentsAction.GET_PUBLIC_PAYMENTS_REQUEST,
    (resolve) =>
      (
        materials: TGetPublicPaymentsMaterials,
        successCallback?: (response: TGetPublicPaymentsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPublicPaymentsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPublicPaymentsAction.GET_PUBLIC_PAYMENTS_SUCCESS,
    (resolve) =>
      (response: TGetPublicPaymentsResponse): TGetPublicPaymentsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPublicPaymentsAction.GET_PUBLIC_PAYMENTS_FAILED,
    (resolve) =>
      (error: unknown): TGetPublicPaymentsFailed =>
        resolve({ error }),
  ),
};
