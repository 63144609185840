import { createActionCreator } from 'deox';

import { TCreateAcademyMaterials, TCreateAcademyResponse } from '@/services/api/academy/create-academy';

// CONSTANTS

export enum ECreateAcademyAction {
  CREATE_ACADEMY = 'CREATE_ACADEMY',
  CREATE_ACADEMY_REQUEST = 'CREATE_ACADEMY_REQUEST',
  CREATE_ACADEMY_SUCCESS = 'CREATE_ACADEMY_SUCCESS',
  CREATE_ACADEMY_FAILED = 'CREATE_ACADEMY_FAILED',
}

// TYPES

export type TCreateAcademyRequest = {
  type: ECreateAcademyAction.CREATE_ACADEMY_REQUEST;
  payload: {
    materials: TCreateAcademyMaterials;
    successCallback?: (response: TCreateAcademyResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateAcademySuccess = {
  type: ECreateAcademyAction.CREATE_ACADEMY_SUCCESS;
  payload: { response: TCreateAcademyResponse };
};

export type TCreateAcademyFailed = { type: ECreateAcademyAction.CREATE_ACADEMY_FAILED };

// FUNCTION

export const createAcademyAction = {
  request: createActionCreator(
    ECreateAcademyAction.CREATE_ACADEMY_REQUEST,
    (resolve) =>
      (
        materials: TCreateAcademyMaterials,
        successCallback?: (response: TCreateAcademyResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateAcademyRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateAcademyAction.CREATE_ACADEMY_SUCCESS,
    (resolve) =>
      (response: TCreateAcademyResponse): TCreateAcademySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateAcademyAction.CREATE_ACADEMY_FAILED,
    (resolve) =>
      (error: unknown): TCreateAcademyFailed =>
        resolve({ error }),
  ),
};
