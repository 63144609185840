import { createReducer } from 'deox';

import {
  TCreateTestimonialResponse,
  TDeleteTestimonialResponse,
  TGetTestimonialsResponse,
  TUpdateTestimonialResponse,
} from '@/services/api/testimonial';
import {
  createTestimonialAction,
  deleteTestimonialAction,
  getTestimonialsAction,
  updateTestimonialAction,
} from '@/redux/actions';
import { createTestimonialUpdateState } from './create-testimonial';
import { deleteTestimonialUpdateState } from './delete-testimonial';
import { getTestimonialsUpdateState } from './get-testimonials';
import { updateTestimonialUpdateState } from './update-testimonial';

export type TTestimonialState = {
  createTestimonialResponse?: TCreateTestimonialResponse;
  deleteTestimonialResponse?: TDeleteTestimonialResponse;
  getTestimonialsResponse?: TGetTestimonialsResponse;
  updateTestimonialResponse?: TUpdateTestimonialResponse;
};

const initialState: TTestimonialState = {
  createTestimonialResponse: undefined,
  deleteTestimonialResponse: undefined,
  getTestimonialsResponse: undefined,
  updateTestimonialResponse: undefined,
};

const TestimonialReducer = createReducer(initialState, (handleAction) => [
  handleAction(createTestimonialAction.success, createTestimonialUpdateState),
  handleAction(deleteTestimonialAction.success, deleteTestimonialUpdateState),
  handleAction(getTestimonialsAction.success, getTestimonialsUpdateState),
  handleAction(updateTestimonialAction.success, updateTestimonialUpdateState),
]);

export default TestimonialReducer;
