import { createActionCreator } from 'deox';

import { TDeleteAcademyMaterials, TDeleteAcademyResponse } from '@/services/api/academy/delete-academy';

// CONSTANTS

export enum EDeleteAcademyAction {
  DELETE_ACADEMY = 'DELETE_ACADEMY',
  DELETE_ACADEMY_REQUEST = 'DELETE_ACADEMY_REQUEST',
  DELETE_ACADEMY_SUCCESS = 'DELETE_ACADEMY_SUCCESS',
  DELETE_ACADEMY_FAILED = 'DELETE_ACADEMY_FAILED',
}

// TYPES

export type TDeleteAcademyRequest = {
  type: EDeleteAcademyAction.DELETE_ACADEMY_REQUEST;
  payload: {
    materials: TDeleteAcademyMaterials;
    successCallback?: (response: TDeleteAcademyResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteAcademySuccess = {
  type: EDeleteAcademyAction.DELETE_ACADEMY_SUCCESS;
  payload: { response: TDeleteAcademyResponse };
};

export type TDeleteAcademyFailed = { type: EDeleteAcademyAction.DELETE_ACADEMY_FAILED };

// FUNCTION

export const deleteAcademyAction = {
  request: createActionCreator(
    EDeleteAcademyAction.DELETE_ACADEMY_REQUEST,
    (resolve) =>
      (
        materials: TDeleteAcademyMaterials,
        successCallback?: (response: TDeleteAcademyResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteAcademyRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteAcademyAction.DELETE_ACADEMY_SUCCESS,
    (resolve) =>
      (response: TDeleteAcademyResponse): TDeleteAcademySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteAcademyAction.DELETE_ACADEMY_FAILED,
    (resolve) =>
      (error: unknown): TDeleteAcademyFailed =>
        resolve({ error }),
  ),
};
