/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EIconName } from '@/components/Icon';
import { TSideBarData } from '@/containers/SideBar/SideBar.types';
import { Paths } from '@/pages/routers';

export const dataSideBar = (data?: any): TSideBarData[] => [
  {
    id: '1',
    title: 'Thống Kê',
    icon: EIconName.Dashboard,
    link: Paths.Dashboard,
    activePaths: [Paths.Dashboard],
    children: [],
  },
  {
    id: '2',
    title: 'Trung Tâm',
    icon: EIconName.Home,
    link: Paths.Academy,
    activePaths: [Paths.Academy, Paths.AcademyDetail(data?.id)],
    children: [],
  },
  {
    id: '2-1',
    title: 'Chi Tiết Trung Tâm',
    icon: EIconName.Home,
    link: Paths.AcademyDetail(data?.id),
    activePaths: [Paths.AcademyDetail(data?.id)],
    children: [],
    hide: true,
  },
  {
    id: '8',
    title: 'Danh Sách Tư Vấn',
    icon: EIconName.Rocket,
    link: Paths.ContactUsers,
    activePaths: [Paths.ContactUsers],
    children: [],
  },
  {
    id: '3',
    title: 'Giao Dịch',
    icon: EIconName.CreditCard,
    link: '',
    activePaths: [Paths.HistoriesPayment, Paths.Receipts],
    children: [
      {
        id: '3-4',
        title: 'Hoá Đơn',
        icon: EIconName.Receipt,
        link: Paths.Receipts,
        activePaths: [Paths.Receipts],
      },
      {
        id: '3-1',
        title: 'Lịch Sử',
        icon: EIconName.History,
        link: Paths.HistoriesPayment,
        activePaths: [Paths.HistoriesPayment],
      },
    ],
  },
  {
    id: '6',
    title: 'Gói Mua',
    icon: EIconName.BoxSeam,
    link: Paths.Packages,
    activePaths: [Paths.Packages],
  },
  {
    id: '7',
    title: 'Mã Giảm Giá',
    icon: EIconName.Ticket,
    link: Paths.Coupons,
    activePaths: [Paths.Coupons],
  },
  {
    id: '4',
    title: 'CRMs',
    icon: EIconName.Affiliate,
    activePaths: [Paths.CRMs, Paths.Feedback],
    children: [
      {
        id: '4-1',
        title: 'Khách Hàng',
        icon: EIconName.UsersGroup,
        link: Paths.CRMs,
        activePaths: [Paths.CRMs],
      },
      {
        id: '4-2',
        title: 'Testimonials',
        icon: EIconName.Star,
        link: Paths.Feedback,
        activePaths: [Paths.Feedback],
      },
    ],
  },
  {
    id: '5',
    title: 'Cài Đặt',
    icon: EIconName.Settings,
    link: '',
    activePaths: [
      Paths.SettingTrialMode,
      Paths.SettingVAT,
      Paths.SettingMoney,
      Paths.SettingEmail,
      Paths.SettingBank,
      Paths.SettingGeneral,
    ],
    children: [
      {
        id: '5-1',
        title: 'Cài Đặt Chung',
        icon: EIconName.Adjustments,
        link: Paths.SettingGeneral,
        activePaths: [Paths.SettingGeneral],
      },
      {
        id: '5-2',
        title: 'Chế Độ Dùng Thử',
        icon: EIconName.Rocket,
        link: Paths.SettingTrialMode,
        activePaths: [Paths.SettingTrialMode],
      },
      {
        id: '5-3',
        title: 'Thuế VAT',
        icon: EIconName.Receipt,
        link: Paths.SettingVAT,
        activePaths: [Paths.SettingVAT],
      },
      {
        id: '5-4',
        title: 'Cấu Hình Email',
        icon: EIconName.Mail,
        link: Paths.SettingEmail,
        activePaths: [Paths.SettingEmail],
      },
      {
        id: '5-5',
        title: 'Ngân Hàng',
        icon: EIconName.BuildingBank,
        link: Paths.SettingBank,
        activePaths: [Paths.SettingBank],
      },
      {
        id: '5-6',
        title: 'Cổng Thanh Toán',
        icon: EIconName.Coins,
        link: Paths.SettingMoney,
        activePaths: [Paths.SettingMoney],
      },
    ],
  },
];
