import { all, takeLatest } from 'redux-saga/effects';

import {
  createInvoiceByUserAction,
  createInvoiceAction,
  deleteInvoiceAction,
  getInvoiceAction,
  getInvoicesAction,
  updateInvoiceAction,
} from '@/redux/actions';

import { createInvoiceByUserSaga } from './create-invoice-by-user';
import { createInvoiceSaga } from './create-invoice';
import { deleteInvoiceSaga } from './delete-invoice';
import { getInvoiceSaga } from './get-invoice';
import { getInvoicesSaga } from './get-invoices';
import { updateInvoiceSaga } from './update-invoice';

export default function* root(): Generator {
  yield all([
    takeLatest(createInvoiceByUserAction.request.type, createInvoiceByUserSaga),
    takeLatest(createInvoiceAction.request.type, createInvoiceSaga),
    takeLatest(deleteInvoiceAction.request.type, deleteInvoiceSaga),
    takeLatest(getInvoiceAction.request.type, getInvoiceSaga),
    takeLatest(getInvoicesAction.request.type, getInvoicesSaga),
    takeLatest(updateInvoiceAction.request.type, updateInvoiceSaga),
  ]);
}
