import { createActionCreator } from 'deox';

import { TDeletePackageMaterials, TDeletePackageResponse } from '@/services/api/package/delete-package';

// CONSTANTS

export enum EDeletePackageAction {
  DELETE_PACKAGE = 'DELETE_PACKAGE',
  DELETE_PACKAGE_REQUEST = 'DELETE_PACKAGE_REQUEST',
  DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS',
  DELETE_PACKAGE_FAILED = 'DELETE_PACKAGE_FAILED',
}

// TYPES

export type TDeletePackageRequest = {
  type: EDeletePackageAction.DELETE_PACKAGE_REQUEST;
  payload: {
    materials: TDeletePackageMaterials;
    successCallback?: (response: TDeletePackageResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeletePackageSuccess = {
  type: EDeletePackageAction.DELETE_PACKAGE_SUCCESS;
  payload: { response: TDeletePackageResponse };
};

export type TDeletePackageFailed = { type: EDeletePackageAction.DELETE_PACKAGE_FAILED };

// FUNCTION

export const deletePackageAction = {
  request: createActionCreator(
    EDeletePackageAction.DELETE_PACKAGE_REQUEST,
    (resolve) =>
      (
        materials: TDeletePackageMaterials,
        successCallback?: (response: TDeletePackageResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeletePackageRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeletePackageAction.DELETE_PACKAGE_SUCCESS,
    (resolve) =>
      (response: TDeletePackageResponse): TDeletePackageSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeletePackageAction.DELETE_PACKAGE_FAILED,
    (resolve) =>
      (error: unknown): TDeletePackageFailed =>
        resolve({ error }),
  ),
};
