import ApiService from '@/services/api';

// TYPES

export type TDeleteInvoicePaths = {
  id: string | number;
};
export type TDeleteInvoiceParams = unknown;

export type TDeleteInvoiceMaterials = {
  paths?: TDeleteInvoicePaths;
  params?: TDeleteInvoiceParams;
};

export type TDeleteInvoiceResponse = unknown;

// FUNCTION

export const deleteInvoice = async ({ paths, params }: TDeleteInvoiceMaterials): Promise<TDeleteInvoiceResponse> => {
  const response = await ApiService.delete(`/api-admin/invoices/${paths?.id}`, { params });
  return response?.data;
};
