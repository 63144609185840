import ApiService from '@/services/api';

// TYPES

export type TDeleteCouponPaths = {
  id: string | number;
};
export type TDeleteCouponParams = unknown;

export type TDeleteCouponMaterials = {
  paths?: TDeleteCouponPaths;
  params?: TDeleteCouponParams;
};

export type TDeleteCouponResponse = unknown;

// FUNCTION

export const deleteCoupon = async ({ paths, params }: TDeleteCouponMaterials): Promise<TDeleteCouponResponse> => {
  const response = await ApiService.delete(`/api-admin/coupons/${paths?.id}`, { params });
  return response?.data;
};
