import { TAcademy } from '@/common/models';
import { TCommonPaginate, TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetAcademiesParams = {
  page: number;
  size: number;
  sort?: string;
  search?: string;
  auditingStatuses?: string;
};

export type TGetAcademiesMaterials = {
  params?: TGetAcademiesParams;
};

export type TGetAcademiesResponse = TCommonResponse & {
  content: TCommonPaginate & {
    content: TAcademy[];
  };
};

// FUNCTION

export const getAcademies = async ({ params }: TGetAcademiesMaterials): Promise<TGetAcademiesResponse> => {
  const response = await ApiService.get(`/api-admin/academies`, { params });
  return response?.data;
};
