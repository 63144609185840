import { createActionCreator } from 'deox';

import { TCreateCouponMaterials, TCreateCouponResponse } from '@/services/api/coupon/create-coupon';

// CONSTANTS

export enum ECreateCouponAction {
  CREATE_COUPON = 'CREATE_COUPON',
  CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST',
  CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS',
  CREATE_COUPON_FAILED = 'CREATE_COUPON_FAILED',
}

// TYPES

export type TCreateCouponRequest = {
  type: ECreateCouponAction.CREATE_COUPON_REQUEST;
  payload: {
    materials: TCreateCouponMaterials;
    successCallback?: (response: TCreateCouponResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateCouponSuccess = {
  type: ECreateCouponAction.CREATE_COUPON_SUCCESS;
  payload: { response: TCreateCouponResponse };
};

export type TCreateCouponFailed = { type: ECreateCouponAction.CREATE_COUPON_FAILED };

// FUNCTION

export const createCouponAction = {
  request: createActionCreator(
    ECreateCouponAction.CREATE_COUPON_REQUEST,
    (resolve) =>
      (
        materials: TCreateCouponMaterials,
        successCallback?: (response: TCreateCouponResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateCouponRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateCouponAction.CREATE_COUPON_SUCCESS,
    (resolve) =>
      (response: TCreateCouponResponse): TCreateCouponSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateCouponAction.CREATE_COUPON_FAILED,
    (resolve) =>
      (error: unknown): TCreateCouponFailed =>
        resolve({ error }),
  ),
};
