import env from '@/env';
import AuthorizedInstance from '@/services/authorized-api';

const ApiService = AuthorizedInstance(env.api.baseUrl.service);

export default ApiService;
export * from './auth';
export * from './user';
export * from './academy';
export * from './package';
export * from './feature';
export * from './setting';
export * from './coupon';
export * from './payment';
export * from './invoice';
export * from './report';
export * from './advisory';
export * from './testimonial';
