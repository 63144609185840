import { createActionCreator } from 'deox';

import { TDeleteInvoiceMaterials, TDeleteInvoiceResponse } from '@/services/api/invoice/delete-invoice';

// CONSTANTS

export enum EDeleteInvoiceAction {
  DELETE_INVOICE = 'DELETE_INVOICE',
  DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST',
  DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS',
  DELETE_INVOICE_FAILED = 'DELETE_INVOICE_FAILED',
}

// TYPES

export type TDeleteInvoiceRequest = {
  type: EDeleteInvoiceAction.DELETE_INVOICE_REQUEST;
  payload: {
    materials: TDeleteInvoiceMaterials;
    successCallback?: (response: TDeleteInvoiceResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteInvoiceSuccess = {
  type: EDeleteInvoiceAction.DELETE_INVOICE_SUCCESS;
  payload: { response: TDeleteInvoiceResponse };
};

export type TDeleteInvoiceFailed = { type: EDeleteInvoiceAction.DELETE_INVOICE_FAILED };

// FUNCTION

export const deleteInvoiceAction = {
  request: createActionCreator(
    EDeleteInvoiceAction.DELETE_INVOICE_REQUEST,
    (resolve) =>
      (
        materials: TDeleteInvoiceMaterials,
        successCallback?: (response: TDeleteInvoiceResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteInvoiceRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteInvoiceAction.DELETE_INVOICE_SUCCESS,
    (resolve) =>
      (response: TDeleteInvoiceResponse): TDeleteInvoiceSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteInvoiceAction.DELETE_INVOICE_FAILED,
    (resolve) =>
      (error: unknown): TDeleteInvoiceFailed =>
        resolve({ error }),
  ),
};
