import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import { TAdminProps } from '@/layouts/Admin/Admin.types';
import Header from '@/containers/Header';
import SideBar from '@/containers/SideBar';
import { TRootState } from '@/redux/reducers';
import { getMyProfileAction, getSettingsAction } from '@/redux/actions';
import Helpers from '@/services/helpers';
import { LayoutPaths, Paths } from '@/pages/routers';
import { ERole } from '@/common/enums';

import ImageVisa from '@/assets/images/image-visa.png';
import ImageAlepay from '@/assets/images/image-alepay.png';
import ImageMasterCard from '@/assets/images/image-master-card.png';

import './Admin.scss';

const Admin: React.FC<TAdminProps> = ({ children }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);

  const atk = Helpers.getAccessToken();

  const myProfileState = useSelector((state: TRootState) => state.userReducer.getMyProfileResponse)?.content;
  const settingsState = useSelector((state: TRootState) => state.settingReducer.getSettingsResponse);

  const getMyProfile = (): void => {
    if (!myProfileState) {
      dispatch(getMyProfileAction.request({}));
    }
  };

  const handleOpenMenu = (): void => {
    setVisibleMenu(true);
  };

  const handleCloseMenu = (): void => {
    setVisibleMenu(false);
  };

  const getSettings = useCallback((): void => {
    if (myProfileState && myProfileState?.role_name === ERole.ROLE_ADMIN) {
      dispatch(getSettingsAction.request({}));
    }
  }, [dispatch, myProfileState]);

  useEffect(() => {
    if (atk) {
      getMyProfile();
    } else {
      Helpers.clearTokens();
      navigate(LayoutPaths.Auth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myProfileState && myProfileState?.role_name !== ERole.ROLE_ADMIN) {
      navigate(`${LayoutPaths.Auth}${Paths.Login}`);
    }
  }, [myProfileState]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    handleCloseMenu();
  }, [isMobile]);

  return myProfileState && settingsState ? (
    <div className={classNames('Admin', { 'visible-menu': isMobile && visibleMenu })}>
      <div className="Admin-header">
        <Header onOpenMenu={handleOpenMenu} />
      </div>
      <div className="Admin-sidebar">
        <SideBar onCloseMenu={handleCloseMenu} />
      </div>
      {isMobile && <div className="Admin-sidebar-overlay" onClick={handleCloseMenu} />}
      <div className="Admin-body">
        <div className="Admin-body-wrapper">{children}</div>
        <div className="Admin-footer flex items-center justify-between">
          Copyright ® 2015 - 2023 Acazia Software Company. All Rights Reserved.
          <div className="Admin-footer-gateways flex items-center">
            <div className="Admin-footer-gateways-item" style={{ height: '2rem' }}>
              <img src={ImageAlepay} alt="" />
            </div>
            <div className="Admin-footer-gateways-item" style={{ height: '1.6rem' }}>
              <img src={ImageVisa} alt="" />
            </div>
            <div className="Admin-footer-gateways-item" style={{ height: '2rem' }}>
              <img src={ImageMasterCard} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Admin;
