import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createPaymentByUserAction } from '@/redux/actions';
import { createPaymentByUser, TCreatePaymentByUserResponse } from '@/services/api';

// FUNCTION

export function* createPaymentByUserSaga(action: ActionType<typeof createPaymentByUserAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createPaymentByUser, materials);
    const createPaymentByUserResponse: TCreatePaymentByUserResponse = response as TCreatePaymentByUserResponse;
    yield put(createPaymentByUserAction.success(createPaymentByUserResponse));
    successCallback?.(createPaymentByUserResponse);
  } catch (err) {
    yield put(createPaymentByUserAction.failure(err));
    failedCallback?.(err);
  }
}
