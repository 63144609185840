import { TInvoiceState } from '@/redux/reducers/invoice';
import { TCreateInvoiceByUserSuccess } from '@/redux/actions/invoice';

export const createInvoiceByUserUpdateState = (
  state: TInvoiceState,
  action: TCreateInvoiceByUserSuccess,
): TInvoiceState => ({
  ...state,
  createInvoiceByUserResponse: action.payload.response,
});
