import { all, takeLatest } from 'redux-saga/effects';

import { getPublicSettingsAction, getSettingsAction, updateSettingKeyAction } from '@/redux/actions';

import { getPublicSettingsSaga } from './get-public-settings';
import { getSettingsSaga } from './get-settings';
import { updateSettingKeySaga } from './update-setting-key';

export default function* root(): Generator {
  yield all([
    takeLatest(getPublicSettingsAction.request.type, getPublicSettingsSaga),
    takeLatest(getSettingsAction.request.type, getSettingsSaga),
    takeLatest(updateSettingKeyAction.request.type, updateSettingKeySaga),
  ]);
}
