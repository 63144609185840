import { createActionCreator } from 'deox';

import { TGetPublicFeaturesMaterials, TGetPublicFeaturesResponse } from '@/services/api/feature/get-public-features';

// CONSTANTS

export enum EGetPublicFeaturesAction {
  GET_PUBLIC_FEATURES = 'GET_PUBLIC_FEATURES',
  GET_PUBLIC_FEATURES_REQUEST = 'GET_PUBLIC_FEATURES_REQUEST',
  GET_PUBLIC_FEATURES_SUCCESS = 'GET_PUBLIC_FEATURES_SUCCESS',
  GET_PUBLIC_FEATURES_FAILED = 'GET_PUBLIC_FEATURES_FAILED',
}

// TYPES

export type TGetPublicFeaturesRequest = {
  type: EGetPublicFeaturesAction.GET_PUBLIC_FEATURES_REQUEST;
  payload: {
    materials: TGetPublicFeaturesMaterials;
    successCallback?: (response: TGetPublicFeaturesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPublicFeaturesSuccess = {
  type: EGetPublicFeaturesAction.GET_PUBLIC_FEATURES_SUCCESS;
  payload: { response: TGetPublicFeaturesResponse };
};

export type TGetPublicFeaturesFailed = { type: EGetPublicFeaturesAction.GET_PUBLIC_FEATURES_FAILED };

// FUNCTION

export const getPublicFeaturesAction = {
  request: createActionCreator(
    EGetPublicFeaturesAction.GET_PUBLIC_FEATURES_REQUEST,
    (resolve) =>
      (
        materials: TGetPublicFeaturesMaterials,
        successCallback?: (response: TGetPublicFeaturesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPublicFeaturesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPublicFeaturesAction.GET_PUBLIC_FEATURES_SUCCESS,
    (resolve) =>
      (response: TGetPublicFeaturesResponse): TGetPublicFeaturesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPublicFeaturesAction.GET_PUBLIC_FEATURES_FAILED,
    (resolve) =>
      (error: unknown): TGetPublicFeaturesFailed =>
        resolve({ error }),
  ),
};
