import { createActionCreator } from 'deox';

import { TGetAcademyPaymentsMaterials, TGetAcademyPaymentsResponse } from '@/services/api/academy/get-academy-payments';

// CONSTANTS

export enum EGetAcademyPaymentsAction {
  GET_ACADEMY_PAYMENTS = 'GET_ACADEMY_PAYMENTS',
  GET_ACADEMY_PAYMENTS_REQUEST = 'GET_ACADEMY_PAYMENTS_REQUEST',
  GET_ACADEMY_PAYMENTS_SUCCESS = 'GET_ACADEMY_PAYMENTS_SUCCESS',
  GET_ACADEMY_PAYMENTS_FAILED = 'GET_ACADEMY_PAYMENTS_FAILED',
}

// TYPES

export type TGetAcademyPaymentsRequest = {
  type: EGetAcademyPaymentsAction.GET_ACADEMY_PAYMENTS_REQUEST;
  payload: {
    materials: TGetAcademyPaymentsMaterials;
    successCallback?: (response: TGetAcademyPaymentsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAcademyPaymentsSuccess = {
  type: EGetAcademyPaymentsAction.GET_ACADEMY_PAYMENTS_SUCCESS;
  payload: { response: TGetAcademyPaymentsResponse };
};

export type TGetAcademyPaymentsFailed = { type: EGetAcademyPaymentsAction.GET_ACADEMY_PAYMENTS_FAILED };

// FUNCTION

export const getAcademyPaymentsAction = {
  request: createActionCreator(
    EGetAcademyPaymentsAction.GET_ACADEMY_PAYMENTS_REQUEST,
    (resolve) =>
      (
        materials: TGetAcademyPaymentsMaterials,
        successCallback?: (response: TGetAcademyPaymentsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAcademyPaymentsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAcademyPaymentsAction.GET_ACADEMY_PAYMENTS_SUCCESS,
    (resolve) =>
      (response: TGetAcademyPaymentsResponse): TGetAcademyPaymentsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAcademyPaymentsAction.GET_ACADEMY_PAYMENTS_FAILED,
    (resolve) =>
      (error: unknown): TGetAcademyPaymentsFailed =>
        resolve({ error }),
  ),
};
