import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deletePaymentAction } from '@/redux/actions';
import { deletePayment, TDeletePaymentResponse } from '@/services/api';

// FUNCTION

export function* deletePaymentSaga(action: ActionType<typeof deletePaymentAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deletePayment, materials);
    const deletePaymentResponse: TDeletePaymentResponse = response as TDeletePaymentResponse;
    yield put(deletePaymentAction.success(deletePaymentResponse));
    successCallback?.(deletePaymentResponse);
  } catch (err) {
    yield put(deletePaymentAction.failure(err));
    failedCallback?.(err);
  }
}
