import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createPackageAction } from '@/redux/actions';
import { createPackage, TCreatePackageResponse } from '@/services/api';

// FUNCTION

export function* createPackageSaga(action: ActionType<typeof createPackageAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createPackage, materials);
    const createPackageResponse: TCreatePackageResponse = response as TCreatePackageResponse;
    yield put(createPackageAction.success(createPackageResponse));
    successCallback?.(createPackageResponse);
  } catch (err) {
    yield put(createPackageAction.failure(err));
    failedCallback?.(err);
  }
}
