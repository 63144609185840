import { TPayment } from '@/common/models';
import { TCommonPaginate, TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetPaymentsParams = {
  page: number;
  size: number;
  paymentStatuses?: string;
  fromDate?: number;
  toDate?: number;
  sort?: string;
  search?: string;
};

export type TGetPaymentsMaterials = {
  params?: TGetPaymentsParams;
};

export type TGetPaymentsResponse = TCommonResponse & {
  content: TCommonPaginate & {
    content: TPayment[];
  };
};

// FUNCTION

export const getPayments = async ({ params }: TGetPaymentsMaterials): Promise<TGetPaymentsResponse> => {
  const response = await ApiService.get(`/api-admin/payments`, { params });
  return response?.data;
};
