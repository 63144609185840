import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getPublicSettingsAction } from '@/redux/actions';
import { getPublicSettings, TGetPublicSettingsResponse } from '@/services/api';

// FUNCTION

export function* getPublicSettingsSaga(action: ActionType<typeof getPublicSettingsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getPublicSettings, materials);
    const getPublicSettingsResponse: TGetPublicSettingsResponse = response as TGetPublicSettingsResponse;
    yield put(getPublicSettingsAction.success(getPublicSettingsResponse));
    successCallback?.(getPublicSettingsResponse);
  } catch (err) {
    yield put(getPublicSettingsAction.failure(err));
    failedCallback?.(err);
  }
}
