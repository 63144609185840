import { createActionCreator } from 'deox';

import { TGetAcademiesMaterials, TGetAcademiesResponse } from '@/services/api/academy/get-academies';

// CONSTANTS

export enum EGetAcademiesAction {
  GET_ACADEMIES = 'GET_ACADEMIES',
  GET_ACADEMIES_REQUEST = 'GET_ACADEMIES_REQUEST',
  GET_ACADEMIES_SUCCESS = 'GET_ACADEMIES_SUCCESS',
  GET_ACADEMIES_FAILED = 'GET_ACADEMIES_FAILED',
}

// TYPES

export type TGetAcademiesRequest = {
  type: EGetAcademiesAction.GET_ACADEMIES_REQUEST;
  payload: {
    materials: TGetAcademiesMaterials;
    successCallback?: (response: TGetAcademiesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAcademiesSuccess = {
  type: EGetAcademiesAction.GET_ACADEMIES_SUCCESS;
  payload: { response: TGetAcademiesResponse };
};

export type TGetAcademiesFailed = { type: EGetAcademiesAction.GET_ACADEMIES_FAILED };

// FUNCTION

export const getAcademiesAction = {
  request: createActionCreator(
    EGetAcademiesAction.GET_ACADEMIES_REQUEST,
    (resolve) =>
      (
        materials: TGetAcademiesMaterials,
        successCallback?: (response: TGetAcademiesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAcademiesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAcademiesAction.GET_ACADEMIES_SUCCESS,
    (resolve) =>
      (response: TGetAcademiesResponse): TGetAcademiesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAcademiesAction.GET_ACADEMIES_FAILED,
    (resolve) =>
      (error: unknown): TGetAcademiesFailed =>
        resolve({ error }),
  ),
};
