import ApiService from '@/services/api';

// TYPES

export type TUpdateAdvisoryPaths = {
  id: string | number;
};
export type TUpdateAdvisoryBody = unknown;

export type TUpdateAdvisoryMaterials = {
  paths?: TUpdateAdvisoryPaths;
  body?: TUpdateAdvisoryBody;
};

export type TUpdateAdvisoryResponse = unknown;

// FUNCTION

export const updateAdvisory = async ({ paths, body }: TUpdateAdvisoryMaterials): Promise<TUpdateAdvisoryResponse> => {
  const response = await ApiService.put(`/api-admin/advisories/${paths?.id}`, body);
  return response?.data;
};
