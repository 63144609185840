import { createReducer } from 'deox';

import {
  TCreatePackageResponse,
  TDeletePackageResponse,
  TGetPackagesResponse,
  TGetPublicPackagesResponse,
  TUpdatePackageResponse,
} from '@/services/api/package';
import {
  createPackageAction,
  deletePackageAction,
  getPackagesAction,
  getPublicPackagesAction,
  updatePackageAction,
} from '@/redux/actions';
import { createPackageUpdateState } from './create-package';
import { deletePackageUpdateState } from './delete-package';
import { getPackagesUpdateState } from './get-packages';
import { getPublicPackagesUpdateState } from './get-public-packages';
import { updatePackageUpdateState } from './update-package';

export type TPackageState = {
  createPackageResponse?: TCreatePackageResponse;
  deletePackageResponse?: TDeletePackageResponse;
  getPackagesResponse?: TGetPackagesResponse;
  getPublicPackagesResponse?: TGetPublicPackagesResponse;
  updatePackageResponse?: TUpdatePackageResponse;
};

const initialState: TPackageState = {
  createPackageResponse: undefined,
  deletePackageResponse: undefined,
  getPackagesResponse: undefined,
  getPublicPackagesResponse: undefined,
  updatePackageResponse: undefined,
};

const PackageReducer = createReducer(initialState, (handleAction) => [
  handleAction(createPackageAction.success, createPackageUpdateState),
  handleAction(deletePackageAction.success, deletePackageUpdateState),
  handleAction(getPackagesAction.success, getPackagesUpdateState),
  handleAction(getPublicPackagesAction.success, getPublicPackagesUpdateState),
  handleAction(updatePackageAction.success, updatePackageUpdateState),
]);

export default PackageReducer;
