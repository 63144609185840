import { TAcademy } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TCreateAcademyParams = unknown;
export type TCreateAcademyBody = unknown;

export type TCreateAcademyMaterials = {
  params?: TCreateAcademyParams;
  body?: TCreateAcademyBody;
};

export type TCreateAcademyResponse = TCommonResponse & {
  content: TAcademy;
};

// FUNCTION

export const createAcademy = async ({ params, body }: TCreateAcademyMaterials): Promise<TCreateAcademyResponse> => {
  const response = await ApiService.post(`/api-public/academies`, body, { params });
  return response?.data;
};
