import { createActionCreator } from 'deox';

import { TDeleteAdvisoryMaterials, TDeleteAdvisoryResponse } from '@/services/api/advisory/delete-advisory';

// CONSTANTS

export enum EDeleteAdvisoryAction {
  DELETE_ADVISORY = 'DELETE_ADVISORY',
  DELETE_ADVISORY_REQUEST = 'DELETE_ADVISORY_REQUEST',
  DELETE_ADVISORY_SUCCESS = 'DELETE_ADVISORY_SUCCESS',
  DELETE_ADVISORY_FAILED = 'DELETE_ADVISORY_FAILED',
}

// TYPES

export type TDeleteAdvisoryRequest = {
  type: EDeleteAdvisoryAction.DELETE_ADVISORY_REQUEST;
  payload: {
    materials: TDeleteAdvisoryMaterials;
    successCallback?: (response: TDeleteAdvisoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteAdvisorySuccess = {
  type: EDeleteAdvisoryAction.DELETE_ADVISORY_SUCCESS;
  payload: { response: TDeleteAdvisoryResponse };
};

export type TDeleteAdvisoryFailed = { type: EDeleteAdvisoryAction.DELETE_ADVISORY_FAILED };

// FUNCTION

export const deleteAdvisoryAction = {
  request: createActionCreator(
    EDeleteAdvisoryAction.DELETE_ADVISORY_REQUEST,
    (resolve) =>
      (
        materials: TDeleteAdvisoryMaterials,
        successCallback?: (response: TDeleteAdvisoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteAdvisoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteAdvisoryAction.DELETE_ADVISORY_SUCCESS,
    (resolve) =>
      (response: TDeleteAdvisoryResponse): TDeleteAdvisorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteAdvisoryAction.DELETE_ADVISORY_FAILED,
    (resolve) =>
      (error: unknown): TDeleteAdvisoryFailed =>
        resolve({ error }),
  ),
};
