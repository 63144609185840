import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getDashboardCountAction } from '@/redux/actions';
import { getDashboardCount, TGetDashboardCountResponse } from '@/services/api';

// FUNCTION

export function* getDashboardCountSaga(action: ActionType<typeof getDashboardCountAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getDashboardCount, materials);
    const getDashboardCountResponse: TGetDashboardCountResponse = response as TGetDashboardCountResponse;
    yield put(getDashboardCountAction.success(getDashboardCountResponse));
    successCallback?.(getDashboardCountResponse);
  } catch (err) {
    yield put(getDashboardCountAction.failure(err));
    failedCallback?.(err);
  }
}
