import { createReducer } from 'deox';

import { TGoogleLoginResponse, TLoginResponse, TRefreshTokenResponse, TRegisterResponse } from '@/services/api/auth';
import { googleLoginAction, loginAction, refreshTokenAction, registerAction } from '@/redux/actions';
import { googleLoginUpdateState } from './google-login';
import { loginUpdateState } from './login';
import { refreshTokenUpdateState } from './refresh-token';
import { registerUpdateState } from './register';

export type TAuthState = {
  googleLoginResponse?: TGoogleLoginResponse;
  loginResponse?: TLoginResponse;
  refreshTokenResponse?: TRefreshTokenResponse;
  registerResponse?: TRegisterResponse;
};

const initialState: TAuthState = {
  googleLoginResponse: undefined,
  loginResponse: undefined,
  refreshTokenResponse: undefined,
  registerResponse: undefined,
};

const AuthReducer = createReducer(initialState, (handleAction) => [
  handleAction(googleLoginAction.success, googleLoginUpdateState),
  handleAction(loginAction.success, loginUpdateState),
  handleAction(refreshTokenAction.success, refreshTokenUpdateState),
  handleAction(registerAction.success, registerUpdateState),
]);

export default AuthReducer;
