import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createCouponAction } from '@/redux/actions';
import { createCoupon, TCreateCouponResponse } from '@/services/api';

// FUNCTION

export function* createCouponSaga(action: ActionType<typeof createCouponAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createCoupon, materials);
    const createCouponResponse: TCreateCouponResponse = response as TCreateCouponResponse;
    yield put(createCouponAction.success(createCouponResponse));
    successCallback?.(createCouponResponse);
  } catch (err) {
    yield put(createCouponAction.failure(err));
    failedCallback?.(err);
  }
}
