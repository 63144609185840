import ApiService from '@/services/api';

// TYPES

export type TUpdateTestimonialPaths = {
  id: string | number;
};
export type TUpdateTestimonialBody = unknown;

export type TUpdateTestimonialMaterials = {
  paths?: TUpdateTestimonialPaths;
  body?: TUpdateTestimonialBody;
};

export type TUpdateTestimonialResponse = unknown;

// FUNCTION

export const updateTestimonial = async ({
  paths,
  body,
}: TUpdateTestimonialMaterials): Promise<TUpdateTestimonialResponse> => {
  const response = await ApiService.put(`/api-admin/testimonials/${paths?.id}`, body);
  return response?.data;
};
