import { TCoupon } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetPublicCouponPaths = {
  code: string | number;
};
export type TGetPublicCouponParams = unknown;

export type TGetPublicCouponMaterials = {
  paths?: TGetPublicCouponPaths;
  params?: TGetPublicCouponParams;
};

export type TGetPublicCouponResponse = TCommonResponse & { content: TCoupon };

// FUNCTION

export const getPublicCoupon = async ({
  paths,
  params,
}: TGetPublicCouponMaterials): Promise<TGetPublicCouponResponse> => {
  const response = await ApiService.get(`/api-user/coupons/${paths?.code}`, { params });
  return response?.data;
};
