import { createActionCreator } from 'deox';

import { TRefreshTokenMaterials, TRefreshTokenResponse } from '@/services/api/auth/refresh-token';

// CONSTANTS

export enum ERefreshTokenAction {
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED',
}

// TYPES

export type TRefreshTokenRequest = {
  type: ERefreshTokenAction.REFRESH_TOKEN_REQUEST;
  payload: {
    materials: TRefreshTokenMaterials;
    successCallback?: (response: TRefreshTokenResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRefreshTokenSuccess = {
  type: ERefreshTokenAction.REFRESH_TOKEN_SUCCESS;
  payload: { response: TRefreshTokenResponse };
};

export type TRefreshTokenFailed = { type: ERefreshTokenAction.REFRESH_TOKEN_FAILED };

// FUNCTION

export const refreshTokenAction = {
  request: createActionCreator(
    ERefreshTokenAction.REFRESH_TOKEN_REQUEST,
    (resolve) =>
      (
        materials: TRefreshTokenMaterials,
        successCallback?: (response: TRefreshTokenResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRefreshTokenRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ERefreshTokenAction.REFRESH_TOKEN_SUCCESS,
    (resolve) =>
      (response: TRefreshTokenResponse): TRefreshTokenSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ERefreshTokenAction.REFRESH_TOKEN_FAILED,
    (resolve) =>
      (error: unknown): TRefreshTokenFailed =>
        resolve({ error }),
  ),
};
