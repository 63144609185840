import { createActionCreator } from 'deox';

import { TCreatePaymentMaterials, TCreatePaymentResponse } from '@/services/api/payment/create-payment';

// CONSTANTS

export enum ECreatePaymentAction {
  CREATE_PAYMENT = 'CREATE_PAYMENT',
  CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST',
  CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_FAILED = 'CREATE_PAYMENT_FAILED',
}

// TYPES

export type TCreatePaymentRequest = {
  type: ECreatePaymentAction.CREATE_PAYMENT_REQUEST;
  payload: {
    materials: TCreatePaymentMaterials;
    successCallback?: (response: TCreatePaymentResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreatePaymentSuccess = {
  type: ECreatePaymentAction.CREATE_PAYMENT_SUCCESS;
  payload: { response: TCreatePaymentResponse };
};

export type TCreatePaymentFailed = { type: ECreatePaymentAction.CREATE_PAYMENT_FAILED };

// FUNCTION

export const createPaymentAction = {
  request: createActionCreator(
    ECreatePaymentAction.CREATE_PAYMENT_REQUEST,
    (resolve) =>
      (
        materials: TCreatePaymentMaterials,
        successCallback?: (response: TCreatePaymentResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreatePaymentRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreatePaymentAction.CREATE_PAYMENT_SUCCESS,
    (resolve) =>
      (response: TCreatePaymentResponse): TCreatePaymentSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreatePaymentAction.CREATE_PAYMENT_FAILED,
    (resolve) =>
      (error: unknown): TCreatePaymentFailed =>
        resolve({ error }),
  ),
};
