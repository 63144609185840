import ApiService from '@/services/api';

// TYPES

export type TUpdateSettingKeyPaths = {
  settingKey: string | number;
};
export type TUpdateSettingKeyBody = unknown;

export type TUpdateSettingKeyMaterials = {
  paths?: TUpdateSettingKeyPaths;
  body?: TUpdateSettingKeyBody;
};

export type TUpdateSettingKeyResponse = unknown;

// FUNCTION

export const updateSettingKey = async ({
  paths,
  body,
}: TUpdateSettingKeyMaterials): Promise<TUpdateSettingKeyResponse> => {
  const response = await ApiService.put(`/api-admin/settings/${paths?.settingKey}`, body);
  return response?.data;
};
