import { createActionCreator } from 'deox';

import { TDeleteTestimonialMaterials, TDeleteTestimonialResponse } from '@/services/api/testimonial/delete-testimonial';

// CONSTANTS

export enum EDeleteTestimonialAction {
  DELETE_TESTIMONIAL = 'DELETE_TESTIMONIAL',
  DELETE_TESTIMONIAL_REQUEST = 'DELETE_TESTIMONIAL_REQUEST',
  DELETE_TESTIMONIAL_SUCCESS = 'DELETE_TESTIMONIAL_SUCCESS',
  DELETE_TESTIMONIAL_FAILED = 'DELETE_TESTIMONIAL_FAILED',
}

// TYPES

export type TDeleteTestimonialRequest = {
  type: EDeleteTestimonialAction.DELETE_TESTIMONIAL_REQUEST;
  payload: {
    materials: TDeleteTestimonialMaterials;
    successCallback?: (response: TDeleteTestimonialResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteTestimonialSuccess = {
  type: EDeleteTestimonialAction.DELETE_TESTIMONIAL_SUCCESS;
  payload: { response: TDeleteTestimonialResponse };
};

export type TDeleteTestimonialFailed = { type: EDeleteTestimonialAction.DELETE_TESTIMONIAL_FAILED };

// FUNCTION

export const deleteTestimonialAction = {
  request: createActionCreator(
    EDeleteTestimonialAction.DELETE_TESTIMONIAL_REQUEST,
    (resolve) =>
      (
        materials: TDeleteTestimonialMaterials,
        successCallback?: (response: TDeleteTestimonialResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteTestimonialRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteTestimonialAction.DELETE_TESTIMONIAL_SUCCESS,
    (resolve) =>
      (response: TDeleteTestimonialResponse): TDeleteTestimonialSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteTestimonialAction.DELETE_TESTIMONIAL_FAILED,
    (resolve) =>
      (error: unknown): TDeleteTestimonialFailed =>
        resolve({ error }),
  ),
};
