import ApiService from '@/services/api';

// TYPES

export type TUpdateCouponPaths = {
  id: string | number;
};
export type TUpdateCouponBody = unknown;

export type TUpdateCouponMaterials = {
  paths?: TUpdateCouponPaths;
  body?: TUpdateCouponBody;
};

export type TUpdateCouponResponse = unknown;

// FUNCTION

export const updateCoupon = async ({ paths, body }: TUpdateCouponMaterials): Promise<TUpdateCouponResponse> => {
  const response = await ApiService.put(`/api-admin/coupons/${paths?.id}`, body);
  return response?.data;
};
