import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getPublicCouponsAction } from '@/redux/actions';
import { getPublicCoupons, TGetPublicCouponsResponse } from '@/services/api';

// FUNCTION

export function* getPublicCouponsSaga(action: ActionType<typeof getPublicCouponsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getPublicCoupons, materials);
    const getPublicCouponsResponse: TGetPublicCouponsResponse = response as TGetPublicCouponsResponse;
    yield put(getPublicCouponsAction.success(getPublicCouponsResponse));
    successCallback?.(getPublicCouponsResponse);
  } catch (err) {
    yield put(getPublicCouponsAction.failure(err));
    failedCallback?.(err);
  }
}
