import { createActionCreator } from 'deox';

import { TGetPublicSettingsMaterials, TGetPublicSettingsResponse } from '@/services/api/setting/get-public-settings';

// CONSTANTS

export enum EGetPublicSettingsAction {
  GET_PUBLIC_SETTINGS = 'GET_PUBLIC_SETTINGS',
  GET_PUBLIC_SETTINGS_REQUEST = 'GET_PUBLIC_SETTINGS_REQUEST',
  GET_PUBLIC_SETTINGS_SUCCESS = 'GET_PUBLIC_SETTINGS_SUCCESS',
  GET_PUBLIC_SETTINGS_FAILED = 'GET_PUBLIC_SETTINGS_FAILED',
}

// TYPES

export type TGetPublicSettingsRequest = {
  type: EGetPublicSettingsAction.GET_PUBLIC_SETTINGS_REQUEST;
  payload: {
    materials: TGetPublicSettingsMaterials;
    successCallback?: (response: TGetPublicSettingsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPublicSettingsSuccess = {
  type: EGetPublicSettingsAction.GET_PUBLIC_SETTINGS_SUCCESS;
  payload: { response: TGetPublicSettingsResponse };
};

export type TGetPublicSettingsFailed = { type: EGetPublicSettingsAction.GET_PUBLIC_SETTINGS_FAILED };

// FUNCTION

export const getPublicSettingsAction = {
  request: createActionCreator(
    EGetPublicSettingsAction.GET_PUBLIC_SETTINGS_REQUEST,
    (resolve) =>
      (
        materials: TGetPublicSettingsMaterials,
        successCallback?: (response: TGetPublicSettingsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPublicSettingsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPublicSettingsAction.GET_PUBLIC_SETTINGS_SUCCESS,
    (resolve) =>
      (response: TGetPublicSettingsResponse): TGetPublicSettingsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPublicSettingsAction.GET_PUBLIC_SETTINGS_FAILED,
    (resolve) =>
      (error: unknown): TGetPublicSettingsFailed =>
        resolve({ error }),
  ),
};
