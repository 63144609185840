import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { checkExistAcademyAction } from '@/redux/actions';
import { checkExistAcademy, TCheckExistAcademyResponse } from '@/services/api';

// FUNCTION

export function* checkExistAcademySaga(action: ActionType<typeof checkExistAcademyAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(checkExistAcademy, materials);
    const checkExistAcademyResponse: TCheckExistAcademyResponse = response as TCheckExistAcademyResponse;
    yield put(checkExistAcademyAction.success(checkExistAcademyResponse));
    successCallback?.(checkExistAcademyResponse);
  } catch (err) {
    yield put(checkExistAcademyAction.failure(err));
    failedCallback?.(err);
  }
}
