import ApiService from '@/services/api';

// TYPES

export type TUpdatePackagePaths = {
  id: string | number;
};
export type TUpdatePackageBody = unknown;

export type TUpdatePackageMaterials = {
  paths?: TUpdatePackagePaths;
  body?: TUpdatePackageBody;
};

export type TUpdatePackageResponse = unknown;

// FUNCTION

export const updatePackage = async ({ paths, body }: TUpdatePackageMaterials): Promise<TUpdatePackageResponse> => {
  const response = await ApiService.put(`/api-admin/packages/${paths?.id}`, body);
  return response?.data;
};
