import { TPayment } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetPublicPaymentsParams = unknown;

export type TGetPublicPaymentsMaterials = {
  params?: TGetPublicPaymentsParams;
};

export type TGetPublicPaymentsResponse = TCommonResponse & {
  content: TPayment;
};

// FUNCTION

export const getPublicPayments = async ({
  params,
}: TGetPublicPaymentsMaterials): Promise<TGetPublicPaymentsResponse> => {
  const response = await ApiService.get(`/api-public/payments`, { params });
  return response?.data;
};
