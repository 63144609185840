import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateCouponAction } from '@/redux/actions';
import { updateCoupon, TUpdateCouponResponse } from '@/services/api';

// FUNCTION

export function* updateCouponSaga(action: ActionType<typeof updateCouponAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateCoupon, materials);
    const updateCouponResponse: TUpdateCouponResponse = response as TUpdateCouponResponse;
    yield put(updateCouponAction.success(updateCouponResponse));
    successCallback?.(updateCouponResponse);
  } catch (err) {
    yield put(updateCouponAction.failure(err));
    failedCallback?.(err);
  }
}
