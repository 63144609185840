import { combineReducers } from 'redux';

import { loadingReducer, errorReducer, successReducer } from './status';
import academyReducer from './academy';
import advisoryReducer from './advisory';
import authReducer from './auth';
import couponReducer from './coupon';
import featureReducer from './feature';
import invoiceReducer from './invoice';
import packageReducer from './package';
import paymentReducer from './payment';
import reportReducer from './report';
import settingReducer from './setting';
import testimonialReducer from './testimonial';
import uiReducer from './ui';
import userReducer from './user';

const rootReducer = combineReducers({
  loadingReducer,
  errorReducer,
  successReducer,
  academyReducer,
  advisoryReducer,
  authReducer,
  couponReducer,
  featureReducer,
  invoiceReducer,
  packageReducer,
  paymentReducer,
  reportReducer,
  settingReducer,
  testimonialReducer,
  uiReducer,
  userReducer,
});

export default rootReducer;

export type TRootState = ReturnType<typeof rootReducer>;
