import ApiService from '@/services/api';

// TYPES

export type TDeletePaymentPaths = {
  id: string | number;
};
export type TDeletePaymentParams = unknown;

export type TDeletePaymentMaterials = {
  paths?: TDeletePaymentPaths;
  params?: TDeletePaymentParams;
};

export type TDeletePaymentResponse = unknown;

// FUNCTION

export const deletePayment = async ({ paths, params }: TDeletePaymentMaterials): Promise<TDeletePaymentResponse> => {
  const response = await ApiService.delete(`/api-admin/payments/${paths?.id}`, { params });
  return response?.data;
};
