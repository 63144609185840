import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetDashboardCountParams = unknown;

export type TGetDashboardCountMaterials = {
  params?: TGetDashboardCountParams;
};

export type TGetDashboardCountResponse = TCommonResponse & {
  content: {
    academy_count: number;
    payment_count: number;
    pending_user_count: number;
    revenue_value: number;
  };
};

// FUNCTION

export const getDashboardCount = async ({
  params,
}: TGetDashboardCountMaterials): Promise<TGetDashboardCountResponse> => {
  const response = await ApiService.get(`/api-admin/dashboard/count`, { params });
  return response?.data;
};
