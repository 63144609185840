import { createActionCreator } from 'deox';

import { TGetCouponsMaterials, TGetCouponsResponse } from '@/services/api/coupon/get-coupons';

// CONSTANTS

export enum EGetCouponsAction {
  GET_COUPONS = 'GET_COUPONS',
  GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST',
  GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS',
  GET_COUPONS_FAILED = 'GET_COUPONS_FAILED',
}

// TYPES

export type TGetCouponsRequest = {
  type: EGetCouponsAction.GET_COUPONS_REQUEST;
  payload: {
    materials: TGetCouponsMaterials;
    successCallback?: (response: TGetCouponsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetCouponsSuccess = {
  type: EGetCouponsAction.GET_COUPONS_SUCCESS;
  payload: { response: TGetCouponsResponse };
};

export type TGetCouponsFailed = { type: EGetCouponsAction.GET_COUPONS_FAILED };

// FUNCTION

export const getCouponsAction = {
  request: createActionCreator(
    EGetCouponsAction.GET_COUPONS_REQUEST,
    (resolve) =>
      (
        materials: TGetCouponsMaterials,
        successCallback?: (response: TGetCouponsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetCouponsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetCouponsAction.GET_COUPONS_SUCCESS,
    (resolve) =>
      (response: TGetCouponsResponse): TGetCouponsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetCouponsAction.GET_COUPONS_FAILED,
    (resolve) =>
      (error: unknown): TGetCouponsFailed =>
        resolve({ error }),
  ),
};
