import { createActionCreator } from 'deox';

import { TUpdateAdvisoryMaterials, TUpdateAdvisoryResponse } from '@/services/api/advisory/update-advisory';

// CONSTANTS

export enum EUpdateAdvisoryAction {
  UPDATE_ADVISORY = 'UPDATE_ADVISORY',
  UPDATE_ADVISORY_REQUEST = 'UPDATE_ADVISORY_REQUEST',
  UPDATE_ADVISORY_SUCCESS = 'UPDATE_ADVISORY_SUCCESS',
  UPDATE_ADVISORY_FAILED = 'UPDATE_ADVISORY_FAILED',
}

// TYPES

export type TUpdateAdvisoryRequest = {
  type: EUpdateAdvisoryAction.UPDATE_ADVISORY_REQUEST;
  payload: {
    materials: TUpdateAdvisoryMaterials;
    successCallback?: (response: TUpdateAdvisoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateAdvisorySuccess = {
  type: EUpdateAdvisoryAction.UPDATE_ADVISORY_SUCCESS;
  payload: { response: TUpdateAdvisoryResponse };
};

export type TUpdateAdvisoryFailed = { type: EUpdateAdvisoryAction.UPDATE_ADVISORY_FAILED };

// FUNCTION

export const updateAdvisoryAction = {
  request: createActionCreator(
    EUpdateAdvisoryAction.UPDATE_ADVISORY_REQUEST,
    (resolve) =>
      (
        materials: TUpdateAdvisoryMaterials,
        successCallback?: (response: TUpdateAdvisoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateAdvisoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateAdvisoryAction.UPDATE_ADVISORY_SUCCESS,
    (resolve) =>
      (response: TUpdateAdvisoryResponse): TUpdateAdvisorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateAdvisoryAction.UPDATE_ADVISORY_FAILED,
    (resolve) =>
      (error: unknown): TUpdateAdvisoryFailed =>
        resolve({ error }),
  ),
};
